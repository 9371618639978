import React from 'react'

const ResidentCheckInStatus = ({checkedIn}) => {
  return (
    <div className='flex items-center gap-x-[5px]'>
        <div className='w-[8px]'>
            <div className={`rounded-full w-[7px] h-[7px] ${checkedIn ? 'bg-green-500' : 'bg-red-500'}`} />
        </div>
        <p className='text-sm font-[500] text-gray-600'>{checkedIn ? 'Checked-in' : 'Pending'}</p>
    </div>
  )
}

export default ResidentCheckInStatus
import React, { useEffect, useState } from 'react'
import SecondaryButton from '../../elements/SecondaryButton'
import FormButton from '../../elements/form/FormButton'
import { useDispatch, useSelector } from 'react-redux'
import RadioGroup from '../../elements/form/RadioGroup'
import Checkbox from '../../elements/form/Checkbox'
import { fetchResidentActivities } from '../../../store/actions/residentActivitiesActions'
import EmptyState from '../../elements/EmptyState'
import TextareaField from '../../elements/form/TextareaField'
import DateField from '../../elements/form/DateField'
import TimeInputField from '../../elements/form/TimeInputField'
import AutocompleteSelect from '../../elements/form/AutocompleteSelect'
import { fetchStaff } from '../../../store/actions/staffActions'
import { CREATING_RESIDENT_REPORT, ERROR } from '../../../store/types'
import { createResidentReport } from '../../../store/actions/residentReportsActions'
import { authHeader } from '../../../utils'
import FileUpload from '../../elements/form/FileUpload'

const NewResidentReport = ({resident, close}) => {
    const residentReportsSelector = useSelector(state => state.residentReports)
    const activitiesSelector = useSelector(state => state.residentActivities)
    const staffSelector = useSelector(state => state.staff)

    const dispatch = useDispatch()
    const [reportPayload, setReportPayload] = useState({});
    const [reportMedia, setReportMedia] = useState({});
    const [validationErrors, setValidationErrors] = useState({});
    const [selectedActivities, setSelectedActivities] = useState([]); 

    useEffect(() => {
        dispatch(fetchResidentActivities(resident._id, '', 0, 0))
        dispatch(fetchStaff('', 0, 0))
        
        return () => {
            
        };
    }, [dispatch, resident._id]);

    const toggleActivitySelection = (activity) => {
        let temp = [...selectedActivities]

        if(temp.includes(activity._id)) {
            temp = temp.filter(a => a !== activity._id)
        } else {
            temp.push(activity._id)
        }

        setSelectedActivities(temp)
    }

    const validateForm = () => {
        let errors = {}
    
        if(!reportPayload.reportType || reportPayload.reportType === '') {
          errors.reportType = true
        }
    
        if(reportPayload.reportType === 'activity' && selectedActivities.length === 0) {
          errors['activities'] = true
        }
    
        if(reportPayload.reportType === 'incident' && (!reportPayload.incidentDate || reportPayload.incidentDate === '')) {
          errors['incidentDate'] = true
        }
    
        if(reportPayload.reportType === 'incident' && (!reportPayload.incidentTime || reportPayload.incidentTime === '')) {
          errors['incidentTime'] = true
        }
    
        if(reportPayload.reportType === 'handover-report' && (!reportPayload.handoverTo || reportPayload.handoverTo === '')) {
          errors['handoverTo'] = true
        }
    
        if(!reportPayload.note || reportPayload.note === '') {
          errors['note'] = true
        }
      
        // if(!activityPayload?.frequency?.times || activityPayload.frequency.times === '') {
        //     errors['frequency-times'] = true
        // }
       
        setValidationErrors(errors)
    
        return errors
    }

    const [file, setFile] = useState(null);
    
    const handleUpload = async (file) => {
        console.log(file)
    
        var formData = new FormData()
        formData.append('file', file.file )
        const headers = new Headers();
        headers.append("Authorization",  authHeader().Authorization);

    
        try {
            dispatch({
                type: CREATING_RESIDENT_REPORT,
                payload: true
            })
            const doUpload = await fetch(`${process.env.REACT_APP_BASE_URL}/files/new`, {
                method: "POST",
                headers,
                body: formData,
            });
            const response = await doUpload.json();
    
            return response
        } catch (error) {
            dispatch({
                type: ERROR,
                error,
            });
        }
    }
    
    const pushReport = async () => {
        if (Object.values(validateForm()).includes(true)) {
            dispatch({
                type: ERROR,
                error: {response: {data: {
                    message: 'Please check the highlighted fields'
                }}}
            })
            return
        }
    
        const payload = {...reportPayload, ...{resident: resident._id}}
        if(selectedActivities && selectedActivities.length > 0) {
            payload.activities = selectedActivities
        }

        if(reportMedia.type && reportMedia.type !== ''){
            payload.media = reportMedia
        }

        if(file) {
            const uploaded = await handleUpload(file) 
            payload.media.url = uploaded.data.file
        }

        dispatch(createResidentReport(payload))

    }

    const reportTypes = [
        {label: 'Care updates', value: 'care-update'},
        {label: 'Activity Updates', value: 'activity'},
        {label: 'Incident Report', value: 'incident'},
        {label: 'Mood & Behaviour', value: 'mood-and-behaviour'},
        // {label: 'Near miss', value: 'near-miss'},
        {label: 'Handover Report', value: 'handover'},
    ]
// 'video', 'image', 'audio', 'document'
    const mediaTypes = [
        {
            label: 'Video',
            value: 'video',
            allowed: ['mp4']
        },
        {
            label: 'Image',
            value: 'image',
            allowed: ['jpg', 'jpeg', 'png']
        },
        {
            label: 'Audio',
            value: 'audio',
            allowed: ['mp3']
        },
        {
            label: 'Document',
            value: 'document',
            allowed: ['pdf']
        },
    ]

    const [allowedUploadFormats, setAllowedUploadFormats] = useState([]);
    const [showUploader, setShowUploader] = useState(false);

    return (
        <div className='w-full'>
            <div className='w-full mt-2'>
                <RadioGroup
                    items={reportTypes}
                    inputLabel="Report type"
                    titleField="label"
                    displayImage={false}
                    imageField=""
                    preSelectedIndex={reportTypes.findIndex(item => item.value === reportPayload?.reportType)}
                    // preSelectedIndex={null}
                    fieldId={`report-type`}
                    requiredField={true}
                    inline={true}
                    hasError={validationErrors && validationErrors[`activity-type`]}
                    showDescriptions={true}
                    returnSelected={(value) => {setReportPayload({...reportPayload, ...{reportType: value.value}})}}
                />
            </div>
            {reportPayload.reportType === 'activity' && <>
                <div className='w-full mt-2'>
                    <h3 className='text-sm font-[500]'>Activities</h3>
                    <p className='text-sm font-[500] text-gray-500'>Select activities that were performed for this report below</p>
                
                    {activitiesSelector?.activities?.activities?.length > 0 ? 
                        activitiesSelector?.activities?.activities?.map((activity, activityIndex)=>(<div key={activityIndex} className='w-full mt-2'>
                            <Checkbox
                                CheckboxLabel={<span>
                                    <p className='text-sm font-[550]'>{activity.activity}</p>
                                    <p className='text-xs font-[500] text-gray-500'>{activity.description}</p>
                                </span>
                                }
                                checkboxToggleFunction={()=>{
                                    toggleActivitySelection(activity)
                                }} 
                                isChecked={selectedActivities.includes(activity._id)} 
                                hasError={validationErrors.activities} 
                            />
                        </div>))
                        :
                        <div className='w-full p-[20px]'>
                            <EmptyState emptyStateText={`No activities have been created for this resident yet. Navigate to the resident overview tab and create some activities first`} />
                        </div>
                    }
                </div>
            </>}

            {reportPayload.reportType === 'incident' && <>
                <div className='w-full flex items-start justify-between gap-x-[15px] mt-2'>
                    <div className='w-full mt-2'>
                        <DateField
                            inputLabel="Incident Date"
                            fieldId={`incident-date`}
                            placeholderText={`Choose date`}
                            preloadValue={reportPayload.incidentDate || ``}
                            requiredField={true}
                            hasError={validationErrors && validationErrors.incidentDate}
                            returnFieldValue={(value) => {setReportPayload({...reportPayload, ...{incidentDate: new Date(value)}})}}
                        />
                    </div>

                    <div className='w-full mt-2'>
                        <TimeInputField 
                            inputType="number" 
                            fieldId={`incident-time`}
                            inputLabel="What time did the incident occur?" 
                            requiredField={true}
                            preloadValue={reportPayload.incidentTime || ''}
                            hasError={validationErrors.incidentTime} 
                            returnFieldValue={(value)=>{setReportPayload({...reportPayload, ...{incidentTime: value}})}}
                        />
                    </div>
                </div>
            </>}

            {reportPayload.reportType === 'handover-report' && <>
                <div className='w-full mt-2'>
                    <AutocompleteSelect
                        selectOptions = {staffSelector?.staff?.staff}
                        requiredField={true}
                        inputLabel = "Staff"
                        titleField = "fullName"
                        displayImage = {false}
                        inputPlaceholder={`Select the staff you are handing over to`}
                        imageField = "image"
                        fieldId = "citizenship"
                        preSelected={reportPayload.handoverTo && reportPayload.handoverTo !== '' ? reportPayload.handoverTo : ''}
                        preSelectedLabel='name'
                        hasError = {validationErrors && validationErrors.handoverTo}
                        returnFieldValue={(value)=>{setReportPayload({...reportPayload, ...{
                            handoverTo: value._id
                        }})}}
                        disabled={false}
                    />
                </div>
            </>}

            <div className='w-full mt-2'>
                <TextareaField
                    inputLabel="Note" 
                    fieldId={`note`} 
                    inputType="text" 
                    preloadValue={reportPayload.note || ''}
                    inputPlaceholder={'Add a note for this report'}
                    hasError={validationErrors && validationErrors[`note`]} 
                    returnFieldValue={(value)=>{setReportPayload({...reportPayload, ...{note: value}})}}
                />
            </div>

            <div className='w-full mt-4'>
                <h3 className='text-sm font-[500]'>Report Media</h3>
                <p className='text-sm font-[500] text-gray-500'>Upload media for this report below if needed</p>

                <div className='w-full mt-2'>
                    <RadioGroup
                        items={mediaTypes}
                        inputLabel="Media type"
                        titleField="label"
                        displayImage={false}
                        imageField=""
                        preSelectedIndex={mediaTypes.findIndex(item => item.value === reportMedia?.type)}
                        // preSelectedIndex={null}
                        fieldId={`report-media-type`}
                        requiredField={true}
                        inline={true}
                        hasError={validationErrors && validationErrors[`activity-type`]}
                        showDescriptions={true}
                        returnSelected={(value) => {
                            setReportMedia({...reportMedia, ...{type: value.value}})
                            setShowUploader(false)
                            setAllowedUploadFormats(value.allowed)
                            setTimeout(() => {
                                setShowUploader(true)
                            }, 50);
                        }}
                    />
                </div>
                {showUploader && <div className='mt-4 w-full'>
                    <FileUpload
                        hasError={validationErrors.file}
                        fieldLabel={`Report Media`}
                        returnFileDetails={(details)=>{
                            setFile(details)
                        }}
                        acceptedFormats={allowedUploadFormats}
                    />
                    <label className='block mt-2 text-xs text-gray-400'>{`Add a media item for the report if needed`}</label>
                </div>}
            </div>

            <div className='w-1/2 flex ml-auto items-center justify between gap-x-[10px] mt-[40px]'>
                    <div className='w-max'>
                        <SecondaryButton 
                            buttonLabel={`Cancel`} 
                            buttonAction={()=>{close()}} 
                            processing={false} 
                        />
                    </div>
                    <FormButton 
                        buttonLabel={`Submit Report`} 
                        buttonAction={()=>{pushReport()}} 
                        processing={residentReportsSelector.creatingResidentReport} 
                    />
                </div>
        </div>
    )
}

export default NewResidentReport
import React from 'react'
import Layout from '../../components/layouts/Layout'
import SettingsLayout from '../../components/layouts/SettingsLayout'
import { Outlet } from 'react-router-dom'

const Settings = () => {
  return (
    <Layout pageTitle={`Settings`}>
        <SettingsLayout>
            <Outlet />
        </SettingsLayout>
    </Layout>
  )
}

export default Settings
import React, { useEffect } from 'react'
import FormButton from '../../elements/form/FormButton'
import { useState } from 'react'
import ModalDialog from '../../layouts/ModalDialog';
import ResidentCheckIn from './ResidentCheckIn';
import { useDispatch, useSelector } from 'react-redux';
import { SET_SUCCESS } from '../../../store/types';
import { clearCheckedInResident } from '../../../store/actions/residentsActions';
import ClockIcon from '../../elements/icons/ClockIcon';
import { transactionTimeStamp } from '../../../utils';
import PulseLine from '../../../assets/images/icons/pulse-line-svg.svg'
import Droplets from '../../../assets/images/icons/droplets-svg.svg'
// import Thumbtack from '../../../assets/images/icons/thumbtack-svg.svg'
import ArrowIcon from '../../elements/icons/ArrowIcon';
import ChevronIcon from '../../elements/icons/ChevronIcon';
import NewResidentActivity from './NewResidentActivity';
import RecordResidentVitals from './RecordResidentVitals';
import { clearCreatedResidentActivity, fetchResidentActivities } from '../../../store/actions/residentActivitiesActions';
import Preloader from '../../elements/Preloader';
import ActivityCard from '../../elements/residents/ActivityCard';
import VisitorCard from '../VisitorCard';

const ResidentOverview = ({resident, reload}) => {
    const [checkingIn, setCheckingIn] = useState(false);
    const dispatch = useDispatch()
    const residentsSelector = useSelector(state => state.residents)
    const activitiesSelector = useSelector(state => state.residentActivities)

    useEffect(() => {
        dispatch(fetchResidentActivities(resident._id, '', 1, 5))
        if(residentsSelector.checkedInResident && residentsSelector.checkedInResident !== null){
            dispatch({
                type: SET_SUCCESS,
                payload: "Resident checked in successfully"
            })
            setCheckingIn(false)
            dispatch(clearCheckedInResident())
            reload()
        }

        if(activitiesSelector.createdResidentActivity && activitiesSelector.createdResidentActivity !== null) {
            dispatch({
                type: SET_SUCCESS,
                payload: "Resident activity created in successfully"
            })
            setCreatingActivity(false)
            dispatch(clearCreatedResidentActivity())
        }
        return () => {
            
        };
    }, [activitiesSelector.createdResidentActivity, dispatch, reload, resident._id, residentsSelector.checkedInResident]);

    const barChartData = [
        {
          subject: '01/01',
          percentage: 78,
        },
        {
          subject: '12/01',
          percentage: 71
        },
        {
          subject: '03/01',
          percentage: 72
        },
        {
          subject: '04/01',
          percentage: 80
        },
        {
          subject: '05/01',
          percentage: 76
        },
        {
          subject: '06/01',
          percentage: 72
        },
        {
          subject: '07/01',
          percentage: 71
        },
    ]

    const [creatingActivity, setCreatingActivity] = useState(false);
    const [recordingVitals, setRecordingVitals] = useState(false);

    const visitorsToday = [
        {
            name: 'Saronov Stephanovic',
            timeIn: '10:00AM',
            timeOut: '12:23PM',
            // resident: {
            //     name: 'Olga Palyuchenko',
            //     picture: Resident4
            // },
            status: "completed"
        },
        {
            name: 'Eucharia Eket',
            timeIn: '11:00AM',
            timeOut: '12:00PM',
            // resident: {
            //     name: 'Olga Palyuchenko',
            //     // picture: Resident4
            // },
            status: "completed"
        },
        {
            name: 'Philia Morgan-Uchendu',
            timeIn: '11:00AM',
            timeOut: '12:00PM',
            // resident: {
            //     name: 'Josephine Grace Turing',
            //     picture: Resident2
            // },
            status: "in_progress"
        },
    ]

    return (
        <>
            <div className='w-full'>
                {!resident.checkedIn ? 
                    <div className='w-full p-[40px] bg-gray-50 border rounded-[8px]'>
                        <h3 className='font-[550] mb-[2px] text-doiteasy-black'>This resident hasn't been checked in yet</h3>
                        <p className='text-sm font-[500] text-gray-500'>If the resident is ready to check in, you can check them in by clicking the "Check in resident" button below</p>

                        <div className='mt-[20px] w-max'>
                            <FormButton buttonLabel={`Check in Resident`} buttonAction={()=>{setCheckingIn(true)}} />
                        </div>
                    </div>
                :
                    <div className='w-full flex items-start gap-x-[20px]'>
                        <div className='w-1/2'>
                            <div className='w-full p-[20px] rounded-[8px] bg-doiteasy-light-gray'>
                                <h3 className='mb-[10px] font-[550]'>Latest Recorded Vitals</h3>
                                <div className='grid grid-cols-2 gap-x-[15px] mb-[15px]'>
                                    <div className='w-full p-[20px] rounded-[8px] bg-white relative'>
                                        <img src={PulseLine} alt='' className='absolute top-[10px] right-[10px] w-[40px]' />
                                        <p className='font-[500] text-sm text-gray-400'>Heart Rate</p>
                                        <div className='flex items-center gap-x-[15px]'>
                                            <h1 className='text-[32px] font-[550]'>72 <span className='text-[14px] font-urbanist'>b/m</span></h1>
                                        </div>
                                        <span className='absolute bottom-[10px] right-[10px] text-xs rounded px-[8px] py-[4px] bg-doiteasy-blue text-white mt-[12px]'>+5%
                                        </span>
                                        <div className='flex items-center gap-x-[4px]'>
                                            <ClockIcon className={`w-4 h-4 text-gray-400`} />
                                            <p className='text-xs font-[500] text-gray-400'>{transactionTimeStamp(new Date()).date} - {transactionTimeStamp(new Date()).time}</p>
                                        </div>
                                    </div>
                                    <div className='w-full p-[20px] rounded-[8px] bg-white relative'>
                                        <img src={Droplets} alt='' className='absolute top-[10px] right-[10px] w-[40px]' />

                                        <p className='font-[500] text-sm text-gray-400'>Blood Pressure</p>
                                        <div className='flex items-center gap-x-[15px]'>
                                            <h1 className='text-[32px] font-[550]'>120/80<span className='text-[14px] font-urbanist'>mmHg</span></h1>
                                            {/* <span className='text-xs rounded px-[8px] py-[4px] bg-doiteasy-blue text-white mt-[12px]'>+5%
                                            </span> */}
                                        </div>
                                        <span className='absolute bottom-[10px] right-[10px] text-xs rounded px-[8px] py-[4px] bg-doiteasy-blue text-white mt-[12px]'>+5%
                                        </span>
                                        <div className='flex items-center gap-x-[4px]'>
                                            <ClockIcon className={`w-4 h-4 text-gray-400`} />
                                            <p className='text-xs font-[500] text-gray-400'>{transactionTimeStamp(new Date()).date} - {transactionTimeStamp(new Date()).time}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className='grid grid-cols-3 gap-x-[15px]'>
                                    <div className='w-full p-[10px] rounded-[8px] bg-white relative'>
                                        <p className='font-[500] text-sm text-gray-400'>Body Temperature</p>
                                        <div className='flex items-center gap-x-[15px]'>
                                            <h1 className='text-[24px] font-[550]'>36.8 <span className='text-[14px] font-urbanist'>&deg;C</span></h1>
                                            {/* <span className='text-xs rounded px-[8px] py-[4px] bg-doiteasy-blue text-white mt-[12px]'>+5%
                                            </span> */}
                                        </div>
                                        <div className='flex items-center gap-x-[4px]'>
                                            <ClockIcon className={`w-4 h-4 text-gray-400`} />
                                            <p className='text-xs font-[500] text-gray-400'>{transactionTimeStamp(new Date()).date} - {transactionTimeStamp(new Date()).time}</p>
                                        </div>
                                    </div>
                                    <div className='w-full p-[10px] rounded-[8px] bg-white relative'>
                                        <p className='font-[500] text-sm text-gray-400'>Respiratory <br />Rate</p>
                                        <div className='flex items-center gap-x-[15px]'>
                                            <h1 className='text-[24px] font-[550]'>16 <span className='text-[14px] font-urbanist'>br/m</span></h1>
                                            {/* <span className='text-xs rounded my-[5px] block text-gray-700 font-[500]'>+5%</span> */}
                                        </div>
                                        <div className='flex items-center gap-x-[4px]'>
                                            <ClockIcon className={`w-4 h-4 text-gray-400`} />
                                            <p className='text-xs font-[500] text-gray-400'>{transactionTimeStamp(new Date()).date} - {transactionTimeStamp(new Date()).time}</p>
                                        </div>
                                    </div>
                                    <div className='w-full p-[10px] rounded-[8px] bg-white relative'>
                                        <p className='font-[500] text-sm text-gray-400'>Body <br />Weight</p>
                                        <div className='flex items-center gap-x-[15px]'>
                                            <h1 className='text-[24px] font-[550]'>95 <span className='text-[14px] font-urbanist'>kg</span></h1>
                                            {/* <span className='text-xs rounded px-[8px] py-[4px] bg-doiteasy-blue text-white mt-[12px]'>+5%
                                            </span> */}
                                        </div>
                                        <div className='flex items-center gap-x-[4px]'>
                                            <ClockIcon className={`w-4 h-4 text-gray-400`} />
                                            <p className='text-xs font-[500] text-gray-400'>{transactionTimeStamp(new Date()).date} - {transactionTimeStamp(new Date()).time}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className='w-full flex flex-row items-center justify-between mt-[20px]'>
                                    <button className='flex items-center gap-x-[5px] text-[14px] text-doiteasy-black font-[500]'>
                                        See vitals history
                                        <ArrowIcon className={`w-4 h-4`} />
                                    </button>               
                                    <button onClick={()=>{setRecordingVitals(true)}} className='text-white text-xs px-[15px] py-[10px] bg-doiteasy-black rounded'>
                                        Record Vitals
                                    </button>
                                </div>
                            </div>

                            <div className='w-full mt-[20px]'>
                                <div className='w-full rounded-[10px] bg-white p-[16px] pb-[40px]'>
                                    <div className='w-full flex items-center justify-between gap-x-[5px]'>
                                        <p className='text-doiteasy-black text-[18px] font-[500] leading-[24px] font-space-grotesk'>Heart Rate</p>
                                        <span className='bg-doiteasy-light-gray px-[10px] rounded py-[6px] text-sm font-[500]'>
                                            Average: 78bpm over 7 readings
                                        </span>
                                        {/* <button onClick={()=>{}} className={`text-xs font-space-grotesk font-[550] text-gray-400 text-gray-600'}`}>Click to change</button> */}

                                    </div>
                                    <div className='w-full flex items-end justify-between h-[150px] relative px-[20px] pb-[10px]'>
                                        {barChartData.map((subject, subjectIndex)=>(
                                            <div key={subjectIndex} onClick={()=>{}} className={`relative cursor-pointer transition text-center duration-200 w-[20px] rounded bg-doiteasy-blue`} style={{height: subject.percentage + '%'}}>
                                                <p className='absolute -bottom-[30px] text-[12px] text-gray-500 font-medium ml-[5px]'>{subject.subject}</p>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>

                            <div className='w-full bg-white rounded-[20px] p-[16px] pb-[40px] relative'>
                            <h3 className='text-doiteasy-black font-[500] border-doiteasy-black w-max'>Visitors today</h3>
                                
                                {visitorsToday.map((visitor, visitorIndex) => (
                                    <div className='w-full my-[12px]' key={visitorIndex}>
                                        <VisitorCard visitor={visitor} hideResident={true} />
                                    </div>
                                ))}

                                <button className='flex items-center gap-x-[5px] text-[14px] text-doiteasy-black font-[500]'>
                                    See all visitors
                                    <ArrowIcon className={`w-4 h-4`} />
                                </button>   
                            </div>
                        </div>

                        <div className='w-1/2'>
                            <div className='w-full p-[20px] rounded-[8px] bg-red-50 border-2 border-red-100 relative mb-[20px]'>
                                <h3 className='text-doiteasy-black font-[500] pb-[5px] border-b-2 text-sm border-doiteasy-black w-max mb-[10px]'>Emergency contacts</h3>
                                <div>
                                    <p className='text-doiteasy-black font-[550] text-sm'>{resident.emergencyContacts[0].name}</p>
                                    <h3 className='text-doiteasy-black font-[550] text-lg mt-[5px]'>{resident.emergencyContacts[0].phone}, {resident.emergencyContacts[0].email} <span className='text-xs text-gray-400 font-[550] mt-[5px]'>{resident.emergencyContacts.length > 1 && `+${resident.emergencyContacts.length - 1} more`}</span></h3>
                                    </div>
                            </div>

                            <div className='w-full relative'>
                                {/* <img src={Thumbtack} alt='' className='absolute top-[0px] right-[0px] w-[30px] rotate-90' /> */}
                                <div className='flex items-center justify-between pb-[10px] border-b-2 mb-[10px]'>
                                    <h3 className='text-doiteasy-black font-[500] border-doiteasy-black w-max'>Pinned Notes</h3>
                                    <button className='flex items-center gap-x-[5px] text-[14px] text-doiteasy-black font-[500]'>
                                    See all notes
                                    <ArrowIcon className={`w-4 h-4`} />
                                </button> 
                                </div>

                                <div className='p-[15px]'>
                                    <p className='text-sm font-[550] text-gray-600'>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit.</p>

                                    <p className='mt-[10px] text-xs font-[400] text-gray-500 '>Created by <span className='font-[550] text-gray-800'>Sam Smith (Caregiver)</span> on 11/11/2024</p>
                                </div>

                                <div className='w-full flex items-center justify-between mt-[20px]'>
                                    <button className='text-gray-500 hover:text-gray-400 transition duration-200'>
                                        <ChevronIcon className={`w-5 h-5`} />
                                    </button>

                                    <p className='text-sm text-gray-400'>1 of 3</p>
                                    <button className='text-gray-500 hover:text-gray-400 transition duration-200'>
                                        <ChevronIcon className={`w-5 h-5 rotate-180`} />
                                    </button>
                                </div>
                            </div>

                            <div className='w-full mt-[20px]'>
                                <div className='flex items-center justify-between pb-[10px] border-b-2 mb-[20px]'>
                                    <h3 className='text-doiteasy-black font-[500] border-doiteasy-black w-max'>Resident Activities</h3>
                                    <button onClick={()=>{setCreatingActivity(true)}} className='text-white text-xs px-[15px] py-[10px] bg-doiteasy-black rounded'>
                                        Create activity
                                    </button> 
                                </div>

                                {activitiesSelector.loadingResidentActivities ? 
                                    <Preloader />
                                    :
                                    <div className='w-full'>
                                        {activitiesSelector.activities.activities.map((activity, activityIndex)=>(
                                            <div key={activityIndex} className='w-full my-[10px]'>
                                                <ActivityCard activity={activity} />
                                            </div>
                                        ))}
                                    </div>
                                }

                            </div>

                        </div>
                    </div>
                }
            </div>

            <ModalDialog
                shown={checkingIn} 
                closeFunction={()=>{setCheckingIn(false)}} 
                actionFunction={()=>{}} 
                actionFunctionLabel={``}
                dialogTitle={<h3 className='capitalize'>Check-in {resident.title} {resident.firstName} {resident.lastName}</h3>}
                maxWidthClass='max-w-lg'
                hideActions={true}
            >
                <ResidentCheckIn 
                    resident={resident}
                    close={()=>{setCheckingIn(false)}}
                />
            </ModalDialog>

            <ModalDialog
                shown={creatingActivity} 
                closeFunction={()=>{setCreatingActivity(false)}} 
                actionFunction={()=>{}} 
                actionFunctionLabel={``}
                dialogTitle={<span className='capitalize font-space-grotesk'>Create activity for {resident.title} {resident.firstName} {resident.lastName}</span>}
                maxWidthClass='max-w-xl'
                hideActions={true}
            >
                <NewResidentActivity 
                    resident={resident}
                    close={()=>{setCreatingActivity(false)}}
                />
            </ModalDialog>

            <ModalDialog
                shown={recordingVitals} 
                closeFunction={()=>{setRecordingVitals(false)}} 
                actionFunction={()=>{}} 
                actionFunctionLabel={``}
                dialogTitle={<span className='capitalize font-space-grotesk'>Record Vitals for {resident.title} {resident.firstName} {resident.lastName}</span>}
                maxWidthClass='max-w-lg'
                hideActions={true}
            >
                <RecordResidentVitals 
                    resident={resident}
                    close={()=>{setRecordingVitals(false)}}
                />
            </ModalDialog>
        </>
    )
}

export default ResidentOverview
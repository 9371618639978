import axios from "axios"
import { authHeader, baseUrl } from "../../utils"
import { CREATE_RESIDENT_ACTIVITY, CREATING_RESIDENT_ACTIVITY, GET_RESIDENT_ACTIVITIES, GETTING_RESIDENT_ACTIVITIES, RESIDENT_ACTIVITIES_ERROR, UPDATE_RESIDENT_ACTIVITY, UPDATING_RESIDENT_ACTIVITY } from "../types"

export const createResidentActivity = (payload) => async (dispatch) => {    
    try{
        const headers = authHeader()

        dispatch({
            type: CREATING_RESIDENT_ACTIVITY,
            payload: true
        })
        const response = await axios.post(`${baseUrl}/resident-activities`, payload, { headers })
        
        dispatch({
            type: CREATE_RESIDENT_ACTIVITY,
            payload: response.data.data
        })
        
    }
    catch(error){
        console.log(error)
        dispatch({
            type: RESIDENT_ACTIVITIES_ERROR,
            error
        })
    }
}

export const clearCreatedResidentActivity = () => async (dispatch) => {    
    dispatch({
        type: CREATE_RESIDENT_ACTIVITY,
        payload: null
    })
}

export const updateResidentActivity = (activityId, payload) => async (dispatch) => {    
    try{
        const headers = authHeader()

        dispatch({
            type: UPDATING_RESIDENT_ACTIVITY,
            payload: true
        })
        const response = await axios.patch(`${baseUrl}/resident-activities/${activityId}`, payload, { headers })
        
        dispatch({
            type: UPDATE_RESIDENT_ACTIVITY,
            payload: response.data.data
        })
        
    }
    catch(error){
        console.log(error)
        dispatch({
            type: RESIDENT_ACTIVITIES_ERROR,
            error
        })
    }
}

export const clearUpdatedResidentActivity = () => async (dispatch) => {    
    dispatch({
        type: UPDATE_RESIDENT_ACTIVITY,
        payload: null
    })
}

export const fetchResidentActivities = (residentId, filterString, page, perPage) => async (dispatch) => {    
    try{
        const headers = authHeader()

        let url = `${baseUrl}/resident-activities?resident=${residentId}`
        if(filterString && filterString !== '') {
            url += `${url.includes('?') ? '&' : '?'}${filterString}`
        }

        if(page && page!=='') {
            url += `${url.includes('?') ? '&' : '?'}page=${page}`
        }

        if(perPage && perPage!=='') {
            url += `${url.includes('?') ? '&' : '?'}perPage=${perPage}`
        }

        dispatch( {
            type: GETTING_RESIDENT_ACTIVITIES,
            payload: true
        })

        const response = await axios.get(url, { headers })

        dispatch({
            type: GET_RESIDENT_ACTIVITIES,
            payload: response.data.data
        })
        
    }
    catch(error){
        dispatch( {
            type: RESIDENT_ACTIVITIES_ERROR,
            error
        })
    }
}

import React, { useEffect, useState } from 'react'
import PlusWhite from '../../../../assets/images/icons/plus.svg';
import PrimaryButton from '../../../../components/elements/PrimaryButton';
import EmptyState from '../../../../components/elements/EmptyState';
import ModalDialog from '../../../../components/layouts/ModalDialog';
import NewRoom from '../../../../components/elements/rooms/NewRoom';
import { useDispatch, useSelector } from 'react-redux';
import { clearCreatedRoom, fetchRooms } from '../../../../store/actions/roomActions';
import Preloader from '../../../../components/elements/Preloader';
import Pagination from '../../../../components/elements/Pagination';
import { SET_SUCCESS } from '../../../../store/types';
import RoomCard from '../../../../components/elements/rooms/RoomCard';

const Rooms = () => {
    const dispatch = useDispatch()
    const roomsSelector = useSelector(state => state.rooms)

    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(25);
    // eslint-disable-next-line no-unused-vars
    const [filters, setFilters] = useState('');

    useEffect(() => {
        dispatch(fetchRooms(filters, page, perPage))

        if(roomsSelector.createdRoom !== null) {
            dispatch({
                type: SET_SUCCESS,
                payload: "New room created successfully"
            })
            dispatch(clearCreatedRoom())
            setCreatingNewRoom(false)
        }
        return () => {
            
        };
    }, [dispatch, filters, page, perPage, roomsSelector.createdRoom]);

    const [creatingNewRoom, setCreatingNewRoom] = useState(false);

    return (
        <>
            <div className='w-full'>
                <div className='flex items-center justify-between'>
                    <div className='w-1/2'>
                        <h3 className='font-[550] mb-[5px]'>Care Home Rooms</h3>
                        <p className='text-sm font-[500] text-gray-600'>A record of all rooms in your care home. These rooms can be assigned to residents, staff or as common areas. You can create a new room by clicking on "Create new room"</p>
                    </div>
                    <div className='w-1/2 flex flex-row-reverse gap-x-[10px]'>
                        <PrimaryButton label={`Create new room`} icon={PlusWhite} buttonAction={()=>{setCreatingNewRoom(true)}} />
                        {/* <FiltersV2 /> */}
                    </div>
                </div>

                {roomsSelector?.loadingRooms ? 
                    <Preloader preloadingText={`Loading rooms`} />
                    :
                    <>
                    {roomsSelector?.rooms?.rooms?.length > 0 ? <div className=''>
                        
                        <div className='grid lg:grid-cols-2 xl:grid-cols-3 gap-[10px] 2xl:grid-cols-4 mt-[40px]'>
                            {roomsSelector?.rooms?.rooms?.map((room, roomIndex)=>(<div key={roomIndex} className='w-full'>
                                <RoomCard room={room} />
                            </div>))}
                        </div>
                        <Pagination 
                            pagination={{
                                page,
                                perPage,
                                totalItems: roomsSelector?.rooms?.total
                            }} 
                            changePage={(page)=>{setPage(page)}} 
                            updatePerPage={(perPage)=>{setPerPage(perPage)}} 
                        />
                    </div>
                    :
                        <div className='bg-white p-[50px] mt-[20px] rounded-[20px]'>
                            <div className='w-1/2 mx-auto'>
                                <EmptyState 
                                    emptyStateTitle={`No Rooms Found`} 
                                    emptyStateText={`It seems you have not created any rooms for your care home yet. If you need to create a room right now, use the "Create new room" button at the top right`} 
                                />
                            </div>
                        </div>}
                    </>}

                
            </div>

            <ModalDialog
                shown={creatingNewRoom} 
                closeFunction={()=>{setCreatingNewRoom(false)}} 
                actionFunction={()=>{}} 
                actionFunctionLabel={``}
                maxWidthClass='max-w-5xl'
                hideActions={true}
                dialogTitle={`Create a new room`}
            >
                <NewRoom 
                    close={()=>{setCreatingNewRoom(false)}}
                />
            </ModalDialog>
        </>
    )
}

export default Rooms
import React from 'react'
import Layout from '../../../components/layouts/Layout'
import { Outlet } from 'react-router-dom'
import StaffLayout from '../../../components/layouts/StaffLayout'

const CareHomeStaff = () => {
  return (
    <Layout pageTitle={`Care Home Staff`}>
        <StaffLayout>
            <Outlet />
        </StaffLayout>
    </Layout>
  )
}

export default CareHomeStaff
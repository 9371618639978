import { Switch } from '@headlessui/react';
import React, { useEffect, useState } from 'react'
import TextareaField from '../../../../components/elements/form/TextareaField';
import TextField from '../../../../components/elements/form/TextField';
import { useDispatch, useSelector } from 'react-redux';
import { ERROR, GETTING_PERMISSIONS, SET_SUCCESS } from '../../../../store/types';
import FormButton from '../../../../components/elements/form/FormButton';
import axios from 'axios';
import { authHeader, baseUrl } from '../../../../utils';
import Loader from '../../../../components/elements/Loader';
import { clearCreatedRole, createRole } from '../../../../store/actions/rolesPermissionsActions';
import { useNavigate } from 'react-router-dom';

const NewRole = () => {
  const dispatch = useDispatch()
  const rolesSelector = useSelector((state => state.roles))
  const navigate = useNavigate()
  const [loadingPermissions, setLoadingPermissions] = useState(true);
  const [rolePayload, setRolePayload] = useState({});
  const [permissions, setPermissions] = useState(null);

  useEffect(() => {
    const fetchPermissions = async () => {    
      try{
        const headers = authHeader()
        const url = `${baseUrl}/permissions`
        setLoadingPermissions(true)

        const response = await axios.get(url, { headers })

        setPermissions(response.data.data.permissions)
        setLoadingPermissions(false)
        dispatch( {
          type: GETTING_PERMISSIONS,
          payload: false
        })
      }
      catch(error){
          dispatch( {
              type: ERROR,
              error
          })
      }
    }

    fetchPermissions()

    if(rolesSelector.createdRole !== null) {
      dispatch(clearCreatedRole())
      dispatch({
        type: SET_SUCCESS,
        payload: 'New role created successfully'
      })
      navigate('/admin/settings/roles-permissions')
    }

    return () => {
      
    };
  }, [dispatch, rolesSelector.createdRole, navigate]);


  const togglePermissionSelection = (sectionIndex, permissionIndex) => {
    let tempPermissions = {...permissions}

    if(permissionIndex === 0 && tempPermissions.sections[sectionIndex].permissions[permissionIndex].selected === false) {
      tempPermissions.sections[sectionIndex].permissions.forEach((option) => {
        option.selected = true
      })
    } else if (permissionIndex === 0 && tempPermissions.sections[sectionIndex].permissions[permissionIndex].selected === true) {
      tempPermissions.sections[sectionIndex].permissions.forEach((option) => {
        option.selected = false
      })
    } else {
      tempPermissions.sections[sectionIndex].permissions[0].selected = false
      tempPermissions.sections[sectionIndex].permissions[permissionIndex].selected = !tempPermissions.sections[sectionIndex].permissions[permissionIndex].selected
    }

    setPermissions(tempPermissions)
  }

  const [validationErrors, setValidationErrors] = useState({});

  const validateForm = () => {
    let errors = {}
    if(!rolePayload.name || rolePayload.name === '') {
        errors.name = true
    }
    // if(!rolePayload.description || rolePayload.type === '') {
    //     errors.description = true
    // }

    setValidationErrors(errors)

    return errors
  }

  const pushRole = () => {
    try {
      if (Object.values(validateForm()).includes(true)) {
          dispatch({
              type: ERROR,
              error: {response: {data: {
                  message: 'Please check the highlighted fields'
              }}}
          })
          return
      }

      const selectedPermissions = []

      permissions.sections.forEach((section)=>{
        section.permissions.forEach((permission) => {
          if(permission.selected === true){
            selectedPermissions.push(permission.value)
          }
        })
      })

      dispatch(createRole({...rolePayload, ...{permissions: selectedPermissions, type: 'user'}}))

  } catch (error) {
      console.log(error)
      dispatch({
          type: ERROR,
          error
      })
    }
  }

  return (
    <div className='w-full'>
      <div className='w-10/12 xl:w-8/12 2xl:w-5/12 mx-auto mt-6 p-8 bg-white'>
        <h3 className='font-medium'>Role Details</h3>
        <p className='text-sm mb-3'>Please provide employee details below</p>

        <div className='mt-4'>
          <TextField
            inputLabel="Role name" 
            fieldId="role-name" 
            inputType="text" 
            requiredField={true}
            preloadValue={rolePayload.name || ''}
            hasError={validationErrors && validationErrors.name} 
            returnFieldValue={(value)=>{setRolePayload({...rolePayload, ...{name: value}})}}
          />
        </div>

        <div className='my-2 w-full'>
          <TextareaField
            inputLabel="Role description" 
            fieldId="role-description" 
            inputType="text" 
            requiredField={false}
            preloadValue={rolePayload.description || ''}
            hasError={false} 
            maxLength={300}
            returnFieldValue={(value)=>{setRolePayload({...rolePayload, ...{description: value}})}}
          />
          {rolePayload?.description?.length === 300 &&<p className='text-red-600 text-xs mt-2'>Please keep the description less than 300 characters</p>}
        </div>

        {loadingPermissions ? <Loader /> : <div className='my-4 w-full'>
            <h3 className='font-medium mt-6 mb-2'>User Account permissions</h3>
            <p className='text-sm mb-12'>Use the toggles below to grant the user's account permissions to different modules of the system</p>

            {permissions.sections.map((section, sectionIndex)=>(<div className='w-full mb-8' key={sectionIndex}>
                <h3 className="font-medium text-sm">{section.title}</h3>
                {section.permissions.map((permission, permissionIndex)=>(<div key={permissionIndex} className='w-full my-4 flex gap-x-4 items-center justify-between'>
                  <div className='w-full'>
                    <p className="text-sm text-gray-600">
                        {permission.label}
                    </p>
                    {/* <p className='text-xs text-gray-400'>Creating a user profile means the user will have access to this platform.</p> */}
                  </div>
                  <div className='w-24'>
                    <Switch
                        checked={permission.selected}
                        onChange={()=>{togglePermissionSelection(sectionIndex, permissionIndex)}}
                        className={`${
                          permission.selected ? 'bg-doiteasy-blue' : 'bg-gray-200'
                        } relative inline-flex items-center h-5 rounded-full w-10`}
                        >
                        {/* <span className="sr-only">Display stock levels</span> */}
                        <span
                            className={`transform transition ease-in-out duration-200 ${
                              permission.selected ? 'translate-x-6' : 'translate-x-1'
                          } inline-block w-3 h-3 transform bg-white rounded-full`}
                        />
                    </Switch>
                  </div>
                </div>))}
              </div>
            ))}
          </div>}

          <div className='w-full mt-8'>
            <FormButton buttonLabel={`Create Role`} buttonAction={()=>{pushRole()}} processing={rolesSelector.creatingRole} />
          </div>
      </div>
    </div>
  )
}

export default NewRole
import { ADMINISTER_PRESCRIPTION, ADMINISTERING_PRESCRIPTION, CREATE_PRESCRIPTION, CREATING_PRESCRIPTION, GET_PRESCRIPTIONS, GETTING_PRESCRIPTIONS, PRESCRIPTIONS_ERROR, UPDATE_PRESCRIPTION, UPDATING_PRESCRIPTION } from "../types"

const initialState = {
    prescriptions: [],
    loadingPrescriptions: true,
    prescriptionsError: null,
    creatingPRescription: false,
    createdPrescription: null,
    updatingPrescription: false,
    updatedPrescription: null,
    administeringPrescription: false,
    administeredPrescription: null
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function(state = initialState, action){

    switch(action.type){
        case CREATING_PRESCRIPTION:
        return {
            ...state,
            creatingPrescription :action.payload,
        }
        case CREATE_PRESCRIPTION:
        return{
            ...state,
            creatingPrescription:false,
            prescriptionsError:  null,
            createdPrescription: action.payload,
        }
        case GETTING_PRESCRIPTIONS:
        return {
            ...state,
            loadingPrescriptions :action.payload,
        }
        case GET_PRESCRIPTIONS:
        return{
            ...state,
            loadingPrescriptions:false,
            prescriptionsError:  null,
            prescriptions: action.payload,
        }
        case UPDATING_PRESCRIPTION:
        return {
            ...state,
            updatingPrescription :action.payload,
        }
        case UPDATE_PRESCRIPTION:
        return{
            ...state,
            updatingPrescription:false,
            prescriptionsError:  null,
            updatedPrescription: action.payload,
        }
        case ADMINISTERING_PRESCRIPTION:
        return {
            ...state,
            administerPrescription :action.payload,
        }
        case ADMINISTER_PRESCRIPTION:
        return{
            ...state,
            administeringPrescription:false,
            prescriptionsError:  null,
            administeredPrescription: action.payload,
        }
        case PRESCRIPTIONS_ERROR:
        return{
            ...state,
            loadingPrescriptions:false,
            prescriptionsError: action.payload 
        }
        default: return state
    }

}
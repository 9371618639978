import React, { useState } from 'react'
import FormButton from '../../elements/form/FormButton'
import SecondaryButton from '../../elements/SecondaryButton'
import { useDispatch, useSelector } from 'react-redux'
import TextField from '../../elements/form/TextField'
import { ERROR } from '../../../store/types'
import AutocompleteSelect from '../../elements/form/AutocompleteSelect'
import DateField from '../../elements/form/DateField'
import NumberField from '../../elements/form/NumberField'
// import SelectField from '../../elements/form/SelectField'
import RadioGroup from '../../elements/form/RadioGroup'
import { medicationDosageMeasureOptions, medicationFrequencies } from '../../../utils'
import { createResidentPrescription } from '../../../store/actions/ResidentPrescriptionsActions'

const drugClasses = require('../../../assets/static/drug-classes.json')
const administrationRoutes = require('../../../assets/static/administration-routes.json')


const NewResidentPrescription = ({resident, close}) => {
    const prescriptionsSelector = useSelector(state => state.prescriptions);
    const dispatch = useDispatch()
    const [prescriptionPayload, setPrescriptionPayload] = useState({
        dosage: {},
        frequency: {}
    });
    const [validationErrors, setValidationErrors] = useState({});

    const validateForm = () => {
        let errors = {}

        if(!prescriptionPayload.medication || prescriptionPayload.medication === '') {
            errors.medication = true
        }
       
        if(!prescriptionPayload.drugClass || prescriptionPayload.drugClass === '') {
            errors.drugClass = true
        }
       
        if(!prescriptionPayload.administrationRoute || prescriptionPayload.administrationRoute === '') {
            errors.administrationRoute = true
        }
       
        if(!prescriptionPayload.days || prescriptionPayload.days === '') {
            errors.days = true
        }
       
        if(!prescriptionPayload.startDate || prescriptionPayload.startDate === '') {
            errors.startDate = true
        }
       
        if(!prescriptionPayload?.dosage?.measure || prescriptionPayload.dosage?.measure === '') {
            errors['dosage-measure'] = true
        }
       
        if(!prescriptionPayload?.dosage?.measureUnit || prescriptionPayload?.dosage?.measureUnit === '') {
            errors['dosage-measure-unit'] = true
        }
       
        if(!prescriptionPayload?.frequency?.type || prescriptionPayload.frequency.type === '') {
            errors['frequency-type'] = true
        }
       
        if(!prescriptionPayload?.frequency?.times || prescriptionPayload.frequency.times === '') {
            errors['frequency-times'] = true
        }
       
        setValidationErrors(errors)

        return errors
    }

    const pushPrescription = () => {
    
        if (Object.values(validateForm()).includes(true)) {
            dispatch({
                type: ERROR,
                error: {response: {data: {
                    message: 'Please check the highlighted fields'
                }}}
            })
            return
        }

        const payload = {...prescriptionPayload, ...{resident: resident._id}}
        dispatch(createResidentPrescription(payload))
    }

    const updateDosage = (field, value) => {
        let temp = {...prescriptionPayload}
        temp.dosage[field] = value
        setPrescriptionPayload(temp)
    }
    
    const updateFrequency = (field, value) => {
        let temp = {...prescriptionPayload}
        temp.frequency[field] = value
        setPrescriptionPayload(temp)
    }

    return (
        <div className='w-full'>
            <div className='w-full mb-4 border-b border-gray-300 pb-[10px]'>
                <p className='text-sm font-[500] text-gray-500'>Provide information about the medication and prescription it save it for this resident</p>
            </div>

            {/* <div className='w-full flex items-start justify-between gap-x-[15px]'> */}
                <div className='w-full'>
                    <div className='w-full'>
                        <TextField
                            inputLabel="Medication" 
                            fieldId="medication" 
                            inputType="text" 
                            preloadValue={''}
                            inputPlaceholder={'Name of the medication'}
                            hasError={validationErrors && validationErrors.medication} 
                            requiredField={true}
                            returnFieldValue={(value)=>{setPrescriptionPayload({...prescriptionPayload, ...{medication: value}})}}
                        />
                    </div>

                    <div className='w-full flex items-start justify-between gap-x-[15px] mt-4'>
                        <div className='w-full'>
                            <AutocompleteSelect
                                selectOptions = {drugClasses}
                                requiredField={true}
                                inputLabel = "Drug class"
                                titleField = "label"
                                displayImage = {false}
                                inputPlaceholder={`Select drug class`}
                                imageField = "image"
                                fieldId = "citizenship"
                                preSelected={prescriptionPayload.drugClass && prescriptionPayload.drugClass !== '' ? prescriptionPayload.drugClass : ''}
                                preSelectedLabel='name'
                                hasError = {validationErrors && validationErrors.drugClass}
                                returnFieldValue={(value)=>{setPrescriptionPayload({...prescriptionPayload, ...{
                                    drugClass: value.value
                                }})}}
                                disabled={false}
                            />
                        </div>

                        <div className='w-full'>
                            <AutocompleteSelect
                                selectOptions = {administrationRoutes}
                                requiredField={true}
                                inputLabel = "Administration Route"
                                titleField = "label"
                                displayImage = {false}
                                inputPlaceholder={`Select how this medication will be administered`}
                                imageField = "image"
                                fieldId = "administration-route"
                                preSelected={prescriptionPayload.administrationRoute && prescriptionPayload.administrationRoute !== '' ? prescriptionPayload.administrationRoute : ''}
                                preSelectedLabel='name'
                                hasError = {validationErrors && validationErrors.administrationRoute}
                                returnFieldValue={(value)=>{setPrescriptionPayload({...prescriptionPayload, ...{
                                    administrationRoute: value.label
                                }})}}
                                disabled={false}
                            />
                        </div>
                    </div>

                    <div className='w-full flex items-start justify-between gap-x-[15px] mt-4'>
                        <div className='w-full mt-2'>
                            <DateField
                                inputLabel="Start Date"
                                fieldId={`start-date`}
                                placeholderText={`Choose date`}
                                preloadValue={prescriptionPayload.startDate || ``}
                                requiredField={true}
                                hasError={validationErrors && validationErrors.startDate}
                                returnFieldValue={(value) => {setPrescriptionPayload({...prescriptionPayload, ...{startDate: new Date(value)}})}}
                            />
                        </div>

                        <div className='w-full mt-2'>
                            <NumberField 
                                inputType="number" 
                                fieldId={`number-of-days`}
                                inputLabel="How many days should this medication be taken for?" 
                                requiredField={true}
                                preloadValue={prescriptionPayload.days || ''}
                                hasError={validationErrors.days} 
                                returnFieldValue={(value)=>{setPrescriptionPayload({...prescriptionPayload, ...{days: value}})}}
                            />
                        </div>
                    </div>
                </div>

                {/* <div className='w-7/12'> */}
                    <h3 className='test-sm font-[500] mt-4'>Dosage</h3>
                    {/* <div className='w-full mt-2 flex items-start justify-between gap-x-[10px]'> */}
                    <div className='w-full mt-2'>
                        <NumberField 
                            inputType="number" 
                            fieldId={`dosage`}
                            inputLabel="Dosage measure" 
                            requiredField={true}
                            preloadValue={prescriptionPayload?.dosage?.measure || ''}
                            hasError={validationErrors['dosage-measure']} 
                            returnFieldValue={(value)=>{updateDosage('measure', value)}}
                        />
                    </div>
                    <div className='w-full mt-2'>
                        <RadioGroup
                            items={medicationDosageMeasureOptions}
                            inputLabel="Dosage measure unit"
                            titleField="label"
                            displayImage={false}
                            imageField=""
                            preSelectedIndex={medicationFrequencies.findIndex(item => item.value === prescriptionPayload?.dosage?.measureUnit)}
                            // preSelectedIndex={null}
                            fieldId={`title`}
                            inline={true}
                            requiredField={true}
                            hasError={validationErrors && validationErrors[`dosage-measure-unit`]}
                            showDescriptions={true}
                            returnSelected={(value) => {updateDosage('measureUnit', value.value)}}
                        />
                    </div>
                    {/* </div> */}

                    <div className='w-full mt-2'>
                        <TextField
                            inputLabel="Additional instructions" 
                            fieldId="medication" 
                            inputType="text" 
                            preloadValue={''}
                            inputPlaceholder={'Add some more information about the dosage if required'}
                            hasError={false} 
                            requiredField={false}
                            returnFieldValue={(value)=>{updateDosage('additionalInstruction', value)}}
                        />
                    </div>

                    <h3 className='test-sm font-[500] mt-4'>Frequency</h3>

                    <div className='w-full mt-2'>
                        <RadioGroup
                            items={medicationFrequencies}
                            inputLabel="Frequency type"
                            titleField="label"
                            displayImage={false}
                            imageField=""
                            preSelectedIndex={medicationFrequencies.findIndex(item => item.value === prescriptionPayload?.frequency?.type)}
                            // preSelectedIndex={null}
                            fieldId={`title`}
                            requiredField={true}
                            inline={true}
                            hasError={validationErrors && validationErrors[`frequency-type`]}
                            showDescriptions={true}
                            returnSelected={(value) => {updateFrequency('type', value.value)}}
                        />
                    </div>
                    <div className='w-full mt-2'>
                        <NumberField 
                            inputType="Times per frequency" 
                            fieldId={`frequency`}
                            inputLabel="Times in selected frequency" 
                            requiredField={true}
                            preloadValue={prescriptionPayload?.frequency?.times || ''}
                            hasError={validationErrors[`frequency-times`]} 
                            returnFieldValue={(value)=>{updateFrequency('times', value)}}
                        />
                        <label className={`text-sm mt-1 lg:text-md cursor-text bg-transparent z-30 relative block py-1 mb-1 transition duration-200 text-gray-500`}>
                            How many times will the medication be administered per the selected frequency?
                        </label>
                    </div>
                    <div className='w-full mt-2'>
                        <TextField
                            inputLabel="Additional notes for frequency" 
                            fieldId="frequency-notes" 
                            inputType="text" 
                            preloadValue={''}
                            inputPlaceholder={'Add some more information about frequency if required'}
                            hasError={false} 
                            requiredField={false}
                            returnFieldValue={(value)=>{updateFrequency('instructions', value)}}
                        />
                    </div>


                {/* </div> */}
            {/* </div> */}

            <div className='w-1/2 flex ml-auto items-center justify between gap-x-[10px] mt-[40px]'>
                <div className='w-max'>
                    <SecondaryButton 
                        buttonLabel={`Cancel`} 
                        buttonAction={()=>{close()}} 
                        processing={false} 
                    />
                </div>
                <FormButton 
                    buttonLabel={`Record Prescription`} 
                    buttonAction={()=>{pushPrescription()}} 
                    processing={prescriptionsSelector.creatingPrescription} 
                />
            </div>

        </div>
    )
}

export default NewResidentPrescription
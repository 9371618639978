import { GETTING_STAFF, GET_STAFF, ONBOARDING_STAFF, ONBOARD_STAFF, RESENDING_INVITATION, RESEND_INVITATION, REVOKE_INVITATION, REVOKING_INVITATION, STAFF_ERROR, UPDATED_STAFF, UPDATING_STAFF } from "../types"


const initialState = {
    staff: [],
    loadingStaff: true,
    staffError: null,
    onboardingStaff: false,
    onboardedStaff: null,
    resendingInvitation: null,
    resentInvitation: null,
    revokingInvitation: null,
    revokedInvitation: null,
    updatingStaff: false,
    updatedStaff: null
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function(state = initialState, action){

    switch(action.type){
        case GETTING_STAFF:
        return {
            ...state,
            loadingStaff :action.payload,
        }
        case GET_STAFF:
        return{
            ...state,
            loadingStaff:false,
            staffError:  null,
            staff: action.payload,
        }
        case ONBOARDING_STAFF:
        return {
            ...state,
            onboardingStaff: action.payload,
        }
        case ONBOARD_STAFF:
        return{
            ...state,
            loadingStaff: false,
            onboardingStaff:false,
            staffError: null,
            onboardedStaff: action.payload,
        }
        case UPDATING_STAFF:
        return {
            ...state,
            updatingStaff: action.payload,
        }
        case UPDATED_STAFF:
        return{
            ...state,
            loadingStaff: false,
            updatingStaff:false,
            staffError: null,
            updatedStaff: action.payload,
        }
        case RESENDING_INVITATION:
        return {
            ...state,
            resendingInvitation: action.payload,
        }
        case RESEND_INVITATION:
        return{
            ...state,
            resendingInvitation: null,
            staffError: null,
            resentInvitation: action.payload,
        }
        case REVOKING_INVITATION:
        return {
            ...state,
            revokingInvitation: action.payload,
        }
        case REVOKE_INVITATION:
        return{
            ...state,
            revokingInvitation:null,
            staffError: null,
            revokedInvitation: action.payload,
        }
        case STAFF_ERROR:
        return{
            ...state,
            loadingStaff: false,
            updatingStaff:false,
            onboardingStaff: false,
            staffError: action.payload 
        }
        default: return state
    }

}
import React, { useEffect } from 'react'
import PrimaryButton from '../../elements/PrimaryButton'
import PlusWhite from '../../../assets/images/icons/plus.svg';
import { useDispatch, useSelector } from 'react-redux';
import Preloader from '../../elements/Preloader';
import EmptyState from '../../elements/EmptyState';
import { clearCreatedPrescription, fetchPrescriptions } from '../../../store/actions/ResidentPrescriptionsActions';
import { useState } from 'react';
import Pagination from '../../elements/Pagination';
import ResidentPrescriptionCard from '../../elements/residents/ResidentPrescriptionCard';
import ModalDialog from '../../layouts/ModalDialog';
import NewResidentPrescription from './NewResidentPrescription';
import { GETTING_PRESCRIPTIONS, SET_SUCCESS } from '../../../store/types';

const ResidentMedications = ({resident}) => {
    const dispatch = useDispatch()
    const prescriptionsSelector = useSelector(state => state.prescriptions)
    // eslint-disable-next-line no-unused-vars
    const [filters, setFilters] = useState('');
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(25);
    const [reload, setReload] = useState(0);

    useEffect(() => {
        dispatch({
            type: GETTING_PRESCRIPTIONS,
            payload: true
        })
        dispatch(fetchPrescriptions(resident._id, filters, page, perPage))

        if (prescriptionsSelector.createdPrescription && prescriptionsSelector.createdPrescription !== null) {
            dispatch(clearCreatedPrescription())
            dispatch({
                type: SET_SUCCESS,
                payload: 'Prescription created successfully!'
            })
            setCreatingPrescription(false)
        }
        return () => {
            
        };
    }, [dispatch, filters, page, perPage, prescriptionsSelector.createdPrescription, reload, resident._id]);

    const [creatingPrescription, setCreatingPrescription] = useState(false);

    return (
        <>
            <div>
                <div className='w-full flex items-start justify-between mb-[20px]'>
                    <div className='w-1/2'>
                        <h3 className='text-doiteasy-black font-[550]'>Resident Prescriptions & Medications</h3>
                        <p className='text-sm text-gray-500 font-[500]'>
                            View and manage medications for this resident, including dosages, frequency, and any other relevant information. You can also administer each medication here.
                        </p>
                    </div>
                    <div className='flex flex-row-reverse'>
                        <PrimaryButton label={`Add Prescription`} icon={PlusWhite} buttonAction={()=>{setCreatingPrescription(true)}} />
                    </div>
                </div>

                <div className='w-full'>
                    {prescriptionsSelector?.loadingPrescriptions ? 
                        <Preloader preloadingText={`Loading resident prescriptions`} />
                        :
                        <>
                        <div className='w-full'>
                            {prescriptionsSelector?.prescriptions?.prescriptions?.length > 0 ?
                                <div className='w-full'>
                                    <div className='w-full mt-[20px]'>
                                        {prescriptionsSelector?.prescriptions?.prescriptions?.map((prescription, prescriptionIndex) => (
                                            <div key={prescriptionIndex} className='w-full mb-[20px]'>
                                                <ResidentPrescriptionCard prescription={prescription} resident={resident} reload={()=>{setReload(reload+1)}} />
                                            </div>
                                        ))}
                                    </div>
                                    <Pagination 
                                        pagination={{
                                            page,
                                            perPage,
                                            totalItems: prescriptionsSelector?.prescriptions?.total
                                        }} 
                                        changePage={(page)=>{setPage(page)}} 
                                        updatePerPage={(perPage)=>{setPerPage(perPage)}} 
                                    />
                                </div>
                                :
                                <div className='w-full'>
                                    <div className='mt-[50px] w-[500px] mx-auto'>
                                        <EmptyState  
                                        emptyStateTitle={`No prescriptions added for this resident yet.`}
                                        emptyStateText={`You can create new prescriptions for this resident by clicking on "Add prescription" button above.`}
                                        />
                                    </div>
                                </div>
                            }
                            </div>
                        </>
                    }
                </div>
            </div>

            <ModalDialog
                shown={creatingPrescription} 
                closeFunction={()=>{setCreatingPrescription(false)}} 
                actionFunction={()=>{}} 
                actionFunctionLabel={``}
                dialogTitle={<h3 className='capitalize'>Record a new prescription for {resident.title} {resident.firstName} {resident.lastName}</h3>}
                maxWidthClass='max-w-4xl'
                hideActions={true}
            >
                <NewResidentPrescription 
                    resident={resident}
                    close={()=>{setCreatingPrescription(false)}}
                />
            </ModalDialog>
        </>
    )
}

export default ResidentMedications
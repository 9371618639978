import React from 'react'
import { calculateAge } from '../../../utils'
import AvatarFemale from '../../../assets/images/resident-placeholder-female.jpg'
import AvatarMale from '../../../assets/images/resident-placeholder-male.jpg'

const ResidentTableSnippet = ({name, picture, dob, gender}) => {
    const bgImage = () => {
        if(picture && picture !== ''){
            return picture
        } else if (gender === 'female') {
            return AvatarFemale
        } else if (gender === 'male') {
            return AvatarMale
        }
    }
    return (
        <div className='w-full'>
            <div className='flex items-center gap-x-[10px]'>
                <div className='w-[50px]'>
                    <div className='w-[50px] h-[50px] rounded-full bg-gray-50 border-4 border-doiteasy-light-gray' style={{
                        backgroundImage: `url(" ${bgImage()} ")`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center center',
                        backgroundRepeat: 'none'
                    }} />
                </div>
                <div>
                    <h3 className='text-[15px] font-space-grotesk text-doiteasy-black'>{name}</h3>
                    <div className='flex items-center gap-x-[5px] mt-[5px]'>
                        <p className='text-sm text-gray-500'>{calculateAge(dob)} years old</p>
                        <span className='block rounded-full bg-doiteasy-black w-[5px] h-[5px]' />
                        <p className='text-sm capitalize text-gray-500'>{gender}</p>
                        {/* <span className='block rounded-full bg-doiteasy-black w-[5px] h-[5px]' /> */}
                        {/* <p className='text-sm capitalize text-gray-500'>Room: {room && room!=='' ? room : 'pending'}</p>
                        <span className='block rounded-full bg-doiteasy-black w-[5px] h-[5px]' />
                        <p className='text-sm capitalize text-gray-500'>{careType} care</p> */}
                    </div>
                </div>
            </div>
        </div>
  )
}

export default ResidentTableSnippet
import React, { useEffect, useState } from 'react'
import SelectField from '../../../../components/elements/form/SelectField'
import TextField from '../../../../components/elements/form/TextField'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { ERROR, SET_SUCCESS } from '../../../../store/types'
import DateField from '../../../../components/elements/form/DateField'
import RadioGroup from '../../../../components/elements/form/RadioGroup'
import FormButton from '../../../../components/elements/form/FormButton'
import CheckIcon from '../../../../components/elements/icons/CheckIcon'
import { fetchRoles } from '../../../../store/actions/rolesPermissionsActions'
import { fetchDepartments } from '../../../../store/actions/departmentActions'
import TrashIcon from '../../../../components/elements/icons/TrashIcon'
import PlusIcon from '../../../../components/elements/icons/PlusIcon'
import { clearOnboardedStaff, onboardStaff } from '../../../../store/actions/staffActions'
import { Nationalities } from '../../../../assets/static/nationalities';
import AutocompleteSelect from '../../../../components/elements/form/AutocompleteSelect'
import { slugify } from '../../../../utils'
import Checkbox from '../../../../components/elements/form/Checkbox'

const NewStaff = () => {
  const dispatch = useDispatch()
  const residentsSelector = useSelector(state => state.staff)
  const rolesPermissionsSelector = useSelector(state => state.roles)
  const departmentsSelector = useSelector(state => state.departments)
  const staffSelector = useSelector(state => state.staff)
  const navigate = useNavigate()

  useEffect(() => {
    dispatch(fetchRoles('type=user', 0, 0))
    dispatch(fetchDepartments('', 0, 0))

    if(staffSelector.onboardedStaff !== null){
      dispatch({
        type: SET_SUCCESS,
        payload: "New member of staff onboarded successfully!"
      })
      dispatch(clearOnboardedStaff())
      navigate('/admin/care-home/staff/care-home-staff')

    }

    return () => {
      
    };
  }, [dispatch, navigate, staffSelector.onboardedStaff]);

  const titles = [
    {
      label: 'Mr.',
      value: 'mr',
    },
    {
      label: 'Mrs.',
      value: 'mrs',
    },
    {
      label: 'Miss',
      value: 'miss',
    },
    {
      label: 'Dr.',
      value: 'dr',
    },
    {
      label: 'Prof.',
      value: 'prof',
    },
    {
      label: 'Engr.',
      value: 'engr',
    },
    {
      label: 'Other',
      value: 'other',
    },
  ]

  const genders = [
    {
      label: 'Female',
      value: 'female',
    },
    {
      label: 'Male',
      value: 'male',
    },
  ]

  const maritalStatusOptions = [
    {
      label: 'Single',
      value: 'single',
    },
    {
      label: 'Married',
      value: 'married',
    },
    {
      label: 'Divorced',
      value: 'divorced',
    },
    {
      label: 'Widowed',
      value: 'widowed',
    },
    {
      label: 'Civil Union',
      value: 'civil-union',
    },
    {
      label: 'Other',
      value: 'other',
    },
  ]

  const employmentContracts = [
    {
      label: 'Full time',
      value: 'full-time',
    },
    {
      label: 'Part time',
      value: 'part-time',
    },
    {
      label: 'Contract',
      value: 'contract',
    }
  ]

  const [staffPayload, setStaffPayload] = useState({});

  const [validationErrors, setValidationErrors] = useState({});

  const documentTemplate = {
    documentName: '',
    description: '',
    hasExpiry: false,
    hasDocumentNumber: false
  }

  const [requiredDocuments, setRequiredDocuments] = useState([documentTemplate]);

  const addDocument = () => {
    setRequiredDocuments([...requiredDocuments, documentTemplate])
  }

  const updateDocumentField = (index, field, value) => {
    let temp = [...requiredDocuments]
    temp[index][field] = value
    setRequiredDocuments(temp)
  }
  
  const deleteDocument = (index) => {
    let temp = [...requiredDocuments]
    temp.splice(index, 1)
    setRequiredDocuments(temp)
  }

  const validateForm = () => {
    let errors = {}

    if(!staffPayload.title || staffPayload.title === ''){
      errors.title = true
    }

    if(!staffPayload.firstName || staffPayload.firstName === ''){
      errors.firstName = true
    }

    if(!staffPayload.lastName || staffPayload.lastName === ''){
      errors.lastName = true
    }

    if(!staffPayload.dob || staffPayload.dob === ''){
      errors.dob = true
    }

    if(!staffPayload.gender || staffPayload.gender === ''){
      errors.gender = true
    }

    if(!staffPayload.maritalStatus || staffPayload.maritalStatus === ''){
      errors.maritalStatus = true
    }

    if(!staffPayload.email || staffPayload.email === ''){
      errors.email = true
    }

    if(!staffPayload.phone || staffPayload.phone === ''){
      errors.phone = true
    }

    if(!staffPayload.address || staffPayload.phone === ''){
      errors.address = true
    }

    if(!staffPayload.city || staffPayload.city === ''){
      errors.city = true
    }

    if(!staffPayload.zip || staffPayload.zip === ''){
      errors.zip = true
    }

    if(!staffPayload.state || staffPayload.state === ''){
      errors.state = true
    }

    if(!staffPayload.nationality || staffPayload.nationality === ''){
      errors.nationality = true
    }

    if(!staffPayload.role || staffPayload.role === ''){
      errors.role = true
    }

    if(!staffPayload.designation || staffPayload.designation === ''){
      errors.designation = true
    }

    if(!staffPayload.department || staffPayload.department === ''){
      errors.department = true
    }

    if(requiredDocuments.length > 1) {
      requiredDocuments.forEach((doc, docIndex) => {
        if(!doc.documentName || doc.documentName === '') {
          errors[`doc-name-${docIndex}`] = true
        }
        if(!doc.description || doc.description === '') {
          errors[`doc-description-${docIndex}`] = true
        }
      })
    }

    console.log('validation errors -> ', errors)

    setValidationErrors(errors)
    return errors
  }

  const pushStaff = () => {
    if (Object.values(validateForm()).includes(true)) {
      dispatch({
          type: ERROR,
          error: {response: {data: {
              message: 'Please check the highlighted fields'
          }}}
      })
      return
    }

    const payload = {
      ...staffPayload,
      ...{
        userType: 'user',
        address: {
          address: staffPayload.address,
          city: staffPayload.city,
          state: staffPayload.state,
          zip: staffPayload.zip
        }
      }
    }

    if(requiredDocuments[0].documentName !== '' && requiredDocuments[0].description !== ''){
      payload.requiredDocumentUploads = requiredDocuments.map(doc => {
        return {...doc, ...{slug: slugify(doc.documentName)}}
      })
    }

    dispatch(onboardStaff(payload))
  }
  return (
    <div className='w-full'>
      <div className='w-2/3 mx-auto'>
        <h3 className='font-[550] mb-[5px]'>Enrol New Staff</h3>
        <p className='w-3/4 text-sm font-[500] text-gray-600'>Please provide details of the staff below to enrol them as employees of your care home</p>

        <p className='w-3/4 text-sm font-[500] text-gray-600 mt-[10px]'>An email will be sent to the email address provided for the staff and they will receive a link to help them to complete their onboarding.</p>
      </div>

      <div className='w-2/3 rounded-[32px] bg-white px-[40px] py-[40px] mt-[20px] mx-auto'>
        <div className='mt-4 flex items-center justify-between gap-x-[20px]'>
          <div className='w-2/12'>
            <SelectField
              selectOptions={titles}
              inputLabel="Title"
              titleField="label"
              displayImage={false}
              imageField=""
              placeholderText={`Title`}
              preSelectedIndex={titles.findIndex(item => item.value === staffPayload?.title)}
              // preSelectedIndex={null}
              fieldId={`title`}
              hasError={validationErrors && validationErrors[`title`]}
              returnFieldValue={(value) => {setStaffPayload({...staffPayload, ...{title: value.value}})}}
            />
          </div>
          {staffPayload?.title === 'other' && <div className='w-4/12'>
            <TextField
              inputLabel="Other title" 
              fieldId="other-title" 
              inputType="text" 
              preloadValue={''}
              inputPlaceholder={'Title'}
              hasError={validationErrors && validationErrors.title} 
              returnFieldValue={(value)=>{setStaffPayload({...staffPayload, ...{title: value}})}}
            />
          </div>}
          <div className='w-full'>
            <TextField
              inputLabel="First name" 
              fieldId="first-name" 
              inputType="text" 
              preloadValue={''}
              inputPlaceholder={'Given name'}
              hasError={validationErrors && validationErrors.firstName} 
              returnFieldValue={(value)=>{setStaffPayload({...staffPayload, ...{firstName: value}})}}
            />
          </div>
        </div>

        <div className='mt-4 flex items-center justify-between gap-x-[20px]'>
          <div className='w-full'>
            <TextField
              inputLabel="Middle name" 
              fieldId="middle-name" 
              inputType="text" 
              preloadValue={''}
              inputPlaceholder={`Resident's middle name (optional)`}
              hasError={false}
              returnFieldValue={(value)=>{setStaffPayload({...staffPayload, ...{middleName: value}})}}
            />
          </div>
          <div className='w-full'>
            <TextField
              inputLabel="Last name" 
              fieldId="last-name" 
              inputType="text" 
              preloadValue={''}
              inputPlaceholder={`Resident's surname`}
              hasError={validationErrors && validationErrors.lastName} 
              returnFieldValue={(value)=>{setStaffPayload({...staffPayload, ...{lastName: value}})}}
            />
          </div>
        </div>

        <div className='mt-4 flex items-center justify-between gap-x-[20px]'>
          <div className='w-full'>
            <DateField
              inputLabel="Date of birth"
              fieldId={`dob`}
              placeholderText={`Choose date`}
              preloadValue={``}
              hasError={validationErrors && validationErrors.dob}
              returnFieldValue={(value) => {setStaffPayload({...staffPayload, ...{dob: new Date(value)}})}}
            />
          </div>

          <div className='w-full'>
            <RadioGroup
              items={genders}
              inputLabel="Gender"
              titleField="label"
              displayImage={false}
              imageField=""
              preSelectedIndex={genders.findIndex(item => item.value === staffPayload?.gender)}
              // preSelectedIndex={null}
              fieldId={`title`}
              inline={true}
              hasError={validationErrors && validationErrors[`gender`]}
              showDescriptions={true}
              returnSelected={(value) => {setStaffPayload({...staffPayload, ...{gender: value.value}})}}
            />
          </div>
        </div>

        <div className='mt-4 flex items-center justify-between gap-x-[20px]'>
          <div className='w-full'>
            <AutocompleteSelect
              selectOptions = {Nationalities}
              requiredField={true}
              inputLabel = "Nationality"
              titleField = "name"
              displayImage = {true}
              inputPlaceholder={`Select nationality`}
              imageField = "image"
              fieldId = "citizenship"
              preSelected={staffPayload.nationality && staffPayload.nationality !== '' ? staffPayload.nationality : ''}
              preSelectedLabel='name'
              hasError = {validationErrors && validationErrors.nationality}
              returnFieldValue={(value)=>{setStaffPayload({...staffPayload, ...{
                  countryCode: value.code,
                  nationality: value.name
              }})}}
              disabled={false}
            />
          </div>
          <div className='w-full'>
            <SelectField
              selectOptions={maritalStatusOptions}
              inputLabel="Marital Status"
              titleField="label"
              displayImage={false}
              imageField=""
              placeholderText={`Select marital status`}
              preSelectedIndex={maritalStatusOptions.findIndex(item => item.value === staffPayload?.maritalStatus)}
              // preSelectedIndex={null}
              fieldId={`marital-status`}
              hasError={validationErrors && validationErrors[`maritalStatus`]}
              returnFieldValue={(value) => {setStaffPayload({...staffPayload, ...{maritalStatus: value.value}})}}
            />
          </div>
        </div>

        <div className='w-full mt-8'>
          <h3 className="mt-[10px] font-[500] text-gray-700 mb-1">Staff member's contact details</h3>
          <p className="text-sm text-gray-500 font-[500]">You can fill each step of the form and save it to continue at a later time.</p>
        </div>

        <div className='mt-4 flex items-center justify-between gap-x-[20px]'>
          <div className='w-full'>
            <TextField
              inputLabel="Email address" 
              fieldId="email-address" 
              inputType="text" 
              preloadValue={''}
              inputPlaceholder={'Active email address'}
              hasError={validationErrors && validationErrors.firstName} 
              returnFieldValue={(value)=>{setStaffPayload({...staffPayload, ...{email: value}})}}
            />
          </div>

          <div className='w-full'>
            <TextField
              inputLabel="Phone number" 
              fieldId="phone-number" 
              inputType="text" 
              preloadValue={''}
              inputPlaceholder={'Active contact phone'}
              hasError={validationErrors && validationErrors.phone} 
              returnFieldValue={(value)=>{setStaffPayload({...staffPayload, ...{phone: value}})}}
            />
          </div>
        </div>

        <div className='mt-4'>
            <TextField
              inputLabel="Address" 
              fieldId="facility-address" 
              inputType="text" 
              preloadValue={''}
              inputPlaceholder={'Residential address of staff'}
              hasError={validationErrors && validationErrors.address} 
              returnFieldValue={(value)=>{setStaffPayload({...staffPayload, ...{address: value}})}}
            />
        </div>

        <div className='mt-4 flex items-center justify-between gap-x-[20px]'>
            <div className='w-full'>
              <TextField
                inputLabel="City" 
                fieldId="address-city" 
                inputType="text" 
                preloadValue={''}
                inputPlaceholder={'City/Location'}
                hasError={validationErrors && validationErrors.city} 
                returnFieldValue={(value)=>{setStaffPayload({...staffPayload, ...{city: value}})}}
              />
            </div>
            <div className='w-full'>
              <TextField
                inputLabel="Zip/Area Code" 
                fieldId="address-zip-code" 
                inputType="text" 
                preloadValue={''}
                inputPlaceholder={'Zip/Area Code'}
                hasError={validationErrors && validationErrors.zip} 
                returnFieldValue={(value)=>{setStaffPayload({...staffPayload, ...{zip: value}})}}
              />
            </div>
            <div className='w-full'>
              <TextField
                inputLabel="State" 
                fieldId="address-state" 
                inputType="text" 
                preloadValue={''}
                inputPlaceholder={'State'}
                hasError={validationErrors && validationErrors.state} 
                returnFieldValue={(value)=>{setStaffPayload({...staffPayload, ...{state: value}})}}
              />
            </div>
        </div>
        

        <div className='w-full mt-8'>
          <h3 className="mt-[10px] font-[500] text-gray-700 mb-1">Employment details</h3>
          <p className="text-sm text-gray-600 font-[500]">Provide details/conditions of the staff member's employment.</p>
        </div>

        <div className='w-full mt-4'>
          <TextField
            inputLabel="Employee Code" 
            fieldId="employee-code" 
            inputType="text" 
            preloadValue={''}
            inputPlaceholder={`Eg: ABCD1234`}
            hasError={false}
            returnFieldValue={(value)=>{setStaffPayload({...staffPayload, ...{designation: value}})}}
          />
          <label 
                className={`text-sm lg:text-md cursor-text z-10 relative py-1 transition block font-[500] duration-200 text-gray-400 mt-[5px]`}
            >
             Create an employee code for this member of staff if needed.
            </label>
        </div>

        <div className='w-full mt-4'>
          <RadioGroup
            items={employmentContracts}
            inputLabel="Employment contract type"
            titleField="label"
            displayImage={false}
            imageField=""
            preSelectedIndex={genders.findIndex(item => item.value === staffPayload?.employmentType)}
            // preSelectedIndex={null}
            fieldId={`title`}
            inline={true}
            hasError={validationErrors && validationErrors[`gender`]}
            showDescriptions={true}
            returnSelected={(value) => {setStaffPayload({...staffPayload, ...{employmentType: value.value}})}}
          />
        </div>

        {departmentsSelector?.departments?.departments?.length > 0 ? 
          <div className='mt-4'>
            <div className='w-full'>
              <SelectField
                selectOptions={departmentsSelector?.departments?.departments}
                inputLabel="Department"
                titleField="name"
                displayImage={false}
                imageField=""
                placeholderText={`Select department placement`}
                preSelectedIndex={departmentsSelector?.departments?.departments?.findIndex(item => item.value === staffPayload?.department) || null}
                // preSelectedIndex={null}
                fieldId={`marital-status`}
                hasError={validationErrors && validationErrors[`department`]}
                returnFieldValue={(value) => {setStaffPayload({...staffPayload, ...{department: value._id}})}}
              />
            </div>
          </div> 
        :
          <div className='bg-gray-100 rounded-[8px] p-[15px] border border-300 text-center w-full'>
            <p className='text-gray-500 text-sm'>No departments available</p>
          </div>
        }

        <div className='w-full mt-4'>
          <TextField
            inputLabel="Staff Designation" 
            fieldId="middle-name" 
            inputType="text" 
            preloadValue={''}
            inputPlaceholder={`Eg: Janitor`}
            hasError={validationErrors.designation}
            returnFieldValue={(value)=>{setStaffPayload({...staffPayload, ...{designation: value}})}}
          />
        </div>

        <div className='mt-4'>
        {rolesPermissionsSelector?.roles?.roles?.length > 0 ? 
          <div className='w-full'>
            <SelectField
              selectOptions={rolesPermissionsSelector?.roles?.roles}
              inputLabel="Employee Role"
              titleField="name"
              displayImage={false}
              imageField=""
              placeholderText={`Select role`}
              preSelectedIndex={rolesPermissionsSelector?.roles?.roles?.findIndex(item => item.value === staffPayload?.role) || null}
              // preSelectedIndex={null}
              fieldId={`marital-status`}
              hasError={validationErrors && validationErrors[`role`]}
              returnFieldValue={(value) => {setStaffPayload({...staffPayload, ...{role: value._id}})}}
            />
          </div>

          :
          <div className='bg-gray-100 rounded-[8px] p-[15px] border border-300 text-center w-full'>
            <p className='text-gray-500 text-sm'>No departments available</p>
          </div>
        }
        </div>

        <div className='w-full mt-8'>
          <h3 className="mt-[10px] font-[500] text-gray-700 mb-1">Documentation</h3>
          <p className="text-sm text-gray-500 font-[500]">If this employee is required to provide any documents as part of their onboarding process, set set them up below. You can add more documents by clicking "add document" or leave this section blank if no documents are required.</p>
        </div>

        {requiredDocuments.map((doc, docIndex)=>(
          <div key={docIndex} className='w-full'>
            <div className='w-full flex items-center justify-between mt-4'>
              <h3 className='text-[14px] text-gray-500 font-[500] pb-2 border-b border-doiteasy-black'>DOCUMENT {docIndex+1}</h3>
              {docIndex > 0 &&
              <button onClick={()=>{deleteDocument(docIndex)}} className=' z-20 p-[7px] text-gray-400 hover:bg-gray-100 hover:text-red-700 rounded transition duration-200'>
                <TrashIcon className={`w-5 h-5`} />
              </button>}
            </div>
            <div className='w-full mt-2'>
              <TextField
                inputLabel="Document" 
                fieldId={`doc-name-${docIndex}`} 
                inputType="text" 
                preloadValue={doc.name || ''}
                inputPlaceholder={`Requested document name`}
                hasError={validationErrors[`doc-name-${docIndex}`]}
                returnFieldValue={(value)=>{updateDocumentField(docIndex, 'documentName', value)}}
              />
            </div>
            <div className='w-full mt-4'>
              <TextField
                inputLabel="Description" 
                fieldId={`doc-description-${docIndex}`} 
                inputType="text" 
                preloadValue={doc.description || ''}
                inputPlaceholder={`A description of the document`}
                hasError={validationErrors[`doc-description-${docIndex}`]}
                returnFieldValue={(value)=>{updateDocumentField(docIndex, 'description', value)}}
              />
            </div>
            <div className='w-full mt-8'>
              <Checkbox
                  CheckboxLabel={<div>
                    <h3 className='text-[15px] font-[550] -mt-[5px]'>Document Number</h3>
                    <p className='text-sm mt-[2px] font-500 text-gray-400'>Check this box if this document has a document number for the new employee to provide</p>
                  </div>}
                  checkboxToggleFunction={()=>{
                    updateDocumentField(docIndex, 'hasDocumentNumber', !doc.hasDocumentNumber)
                  }} 
                  isChecked={doc.hasDocumentNumber} 
                  hasError={false} 
              />
            </div>
            <div className='w-full mt-8'>
              <Checkbox
                  CheckboxLabel={<div>
                    <h3 className='text-[15px] font-[550] -mt-[5px]'>Document Expiry</h3>
                    <p className='text-sm mt-[2px] font-500 text-gray-400'>Check this box if this document has an expiry date that the employee needs to provide</p>
                  </div>}
                  checkboxToggleFunction={()=>{
                    updateDocumentField(docIndex, 'hasExpiry', !doc.hasExpiry)
                  }} 
                  isChecked={doc.hasExpiry} 
                  hasError={false} 
              />
            </div>
          </div>
        ))}
        <button onClick={()=>{addDocument()}} className='flex items-center justify-center w-max rounded-[8px] px-[16px] py-[10px] mt-6 text-sm bg-doiteasy-black text-doiteasy-light-gray'>
            <PlusIcon className={`w-4 h-4`}/>
            Add Document
          </button>

        <div className='flex flex-row-reverse items-center justify-between pt-8 border-t mt-12'>
          <div className='w-max'>
            <FormButton 
              buttonLabel={
                <span className='flex items-center justify-center gap-x-[10px] text-white font-space-grotesk font-[550]'>
                  Complete Staff Enrolment
                  <CheckIcon className={`w-5 h-5`} />
                </span>
              } 
              buttonAction={()=>{pushStaff()}} 
              processing={residentsSelector.onboardingStaff} />
          </div>
        </div>
      </div>


    </div>
  )
}

export default NewStaff
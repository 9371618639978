import React, { useEffect, useState } from 'react'
import Layout from '../../../../components/layouts/Layout'
import FiltersV2 from '../../../../components/elements/FiltersV2'
import PlusWhite from '../../../../assets/images/icons/plus.svg';
import PrimaryButton from '../../../../components/elements/PrimaryButton';
import EmptyState from '../../../../components/elements/EmptyState';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchResidents } from '../../../../store/actions/residentsActions';
import DataTable from '../../../../components/elements/DataTable';

import { tableHeadersFields, transactionTimeStamp } from '../../../../utils';
import ResidentTableSnippet from '../../../../components/elements/residents/ResidentTableSnippet';
import ResidentCheckInStatus from '../../../../components/elements/residents/ResidentCheckInStatus';
import ResidentEmergencyContactSnippet from '../../../../components/elements/residents/ResidentEmergencyContactSnippet';
import SearchField from '../../../../components/elements/SearchField';
import Preloader from '../../../../components/elements/Preloader';
import CareTypePill from '../../../../components/elements/residents/CareTypePill';

const Residents = () => {
  const dispatch = useDispatch()
  const residentsSelector = useSelector(state => state.residents)
  const navigate = useNavigate()
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(25);
  // eslint-disable-next-line no-unused-vars
  const [filtersString, setFiltersString] = useState('');

  useEffect(() => {
    dispatch(fetchResidents(filtersString, page, perPage))
    return () => {
      
    };
  }, [dispatch, filtersString, page, perPage]);

  const tableOptions = {
    selectable: false,
    multiselect: false,
    clickableRows: true,
    rowAction: (rowIndex)=>{
      navigate(`resident/${residentsSelector.residents?.residents[rowIndex]._id}`)
    }
  }

  const columnWidths = {
    resident: 'w-3/12',
    careType: 'w-1/12',
    checkInStatus: 'w-2/12',
    room: 'w-1/12',
    emergencyContacts: 'w-3/12',
    arrivalDate: 'w-2/12',
  }

  const columnDataStyles = {}

  const cleanUpTableData = (array) => {
    if(!array) return
    let finalResult = [] 
    array.forEach((object, objectIndex) => {
        const applicationObject = {
          resident: <ResidentTableSnippet 
            name={`${object.firstName}${object.otherNames && object.otherNames !== '' ? ` ${object.otherNames} ` : ' '} ${object.lastName}`}
            picture={object.picture}
            dob={object.dob}
            gender={object.gender}
          />,
          careType: <CareTypePill careType={object?.careTypeRequired} />,
          checkInStatus: <ResidentCheckInStatus checkedIn={object.checkedIn} />,
          room: object.room?.name || <div className='w-[75px] h-[14px] bg-gray-100 animate-pulse' />,
          emergencyContacts: <ResidentEmergencyContactSnippet contacts={object.emergencyContacts} />,
          arrivalDate: <div>
            <p className='text-sm'>{transactionTimeStamp(object.arrivalDate)?.date}</p>
            <p className='text-sm mt-[5px] text-gray-500'>{transactionTimeStamp(object.arrivalDate)?.time}</p>
          </div> || <div className='w-[75px] h-[14px] bg-gray-100 animate-pulse' />
        }

        finalResult.push(applicationObject)
    })
    return finalResult
  }
  
  return (
    <Layout pageTitle="Residents">
      <div className='w-full'>
        <div className='flex items-center justify-between'>
          <div className='w-1/2'>
            <div className='w-8/12'>
              <SearchField placeholderText={`Search for resident`} />
            </div>
          </div>
          <div className='w-1/2 flex flex-row-reverse gap-x-[10px]'>
            <Link to={`enrol-resident`}>
              <PrimaryButton label={`Enrol resident`} icon={PlusWhite} />
            </Link>
            <FiltersV2 />
          </div>
        </div>

        <div className='bg-white p-[20px] mt-[20px] rounded-[20px]'>
          {residentsSelector?.loadingResidents ? 
            <Preloader preloadingText={`Loading residents`} />
            :
            <>
              {residentsSelector?.residents?.residents?.length > 0 ? <div className=''>
                <div className='w-full'>
                  <DataTable
                    tableHeaders={tableHeadersFields(cleanUpTableData(residentsSelector?.residents?.residents)[0]).headers} 
                    tableData={cleanUpTableData(residentsSelector?.residents?.residents)} 
                    columnWidths={columnWidths}
                    columnDataStyles={columnDataStyles}
                    allFields={tableHeadersFields(cleanUpTableData(residentsSelector?.residents?.residents)[0]).fields}
                    onSelectItems={()=>{}}
                    tableOptions={tableOptions}
                    pagination={{
                        perPage, 
                        page,
                        totalItems: residentsSelector?.residents?.total,
                    }}
                    onSelectSingle={()=>{}}
                    changePage={setPage}
                    updatePerPage={setPerPage}
                  />
                </div>
              </div>
                : 
                <div className='w-1/2 mx-auto'>
                  <EmptyState emptyStateTitle={`No Residents Found`} emptyStateText={`It seems you have not enrolled any residents yet. If you need to enroll a resident right now, use the "Enrol resident" button at the top right`} />
                </div>

              }
            </>
          }
        </div>
        
      </div>
    </Layout>
  )
}

export default Residents
import React from 'react'
import PhotoIcon from '../../../assets/images/icons/photograph-icon.svg'
import TrashIcon from '../icons/TrashIcon'
import ArrowIcon from '../icons/ArrowIcon'

const RoomCard = ({room}) => {
  return (
    <div className='w-full bg-white'>
        <div className='w-full h-[250px] bg-doiteasy-light-gray flex items-center justify-center' style={{
            backgroundImage: `url(" ${room.picture} ")`,
            backgroundSize: 'cover',
            backgroundPosition: 'center center',
            backgroundRepeat: 'none'
        }}>
            {(!room.picture || room.picture === '') && <img src={PhotoIcon} className='w-[50px]' alt='' />}
        </div>
        <div className='p-[15px]'>
            <h3 className='font-[550] text-doiteasy-black mb-[10px]'>{room.name}</h3>
            <p className='text-sm font-[500] mb-[20px]'>{room.description.substr(0, 150)}{room.description.length > 150 && '...'}</p>
            {room.inUse && <span className='rounded text-xs bg-red-100 text-red-500 px-[10px] py-[5px] font-[550] '>In use</span>}
            {!room.inUse && <span className='rounded text-xs bg-opacity-50 bg-green-100 text-green-500 px-[10px] py-[5px] font-[550] '>Available</span>}
        </div>

        <div className="w-full flex items-center justify-between mt-3 p-3 border-t">
            <button onClick={()=>{}} className='text-sm flex flex-row items-center gap-x-3 text-vcm-purple hover:text-gray-400 transition duration-200'>Room Details <ArrowIcon className={`w-5 h-5`} /></button> 

            <button onClick={()=>{}} className='p-1 rounded bg-transparent hover:bg-red-100 transition duration-200 text-gray-400 hover:text-vcm-purple'>
                <TrashIcon className={`w-5 h-5`} />
            </button>
        </div>
    </div>
  )
}

export default RoomCard
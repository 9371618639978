import React, { Fragment, useEffect, useState } from 'react'
import DrugClassPill from '../medications/DrugClassPill'
import ChevronIcon from '../icons/ChevronIcon'
import { addDays, transactionTimeStamp } from '../../../utils';
import ModalDialog from '../../layouts/ModalDialog';
import CheckIcon from '../icons/CheckIcon';
import PenIcon from '../icons/PenIcon';
import EditResidentPrescription from '../../partials/residents/EditResidentPrescription';
import moment from 'moment';
import AdministerMedication from '../../partials/residents/AdministerMedication';
import { useDispatch, useSelector } from 'react-redux';
import { SET_SUCCESS } from '../../../store/types';
import { clearAdministeredPrescription } from '../../../store/actions/ResidentPrescriptionsActions';
import MedicationAdministration from '../../partials/residents/MedicationAdministration';

const ResidentPrescriptionCard = ({prescription, resident, reload}) => {
    const dispatch = useDispatch()
    const prescriptionsSelector = useSelector(state => state.prescriptions)

    const [administrationsOpen, setAdministrationsOpen] = useState(false);
    const frequencies = {
        hourly: 'hour',
        daily: 'day',
        weekly: 'week',
        monthly: 'month',
        yearly: 'year'
    }
    const [administeringMedication, setAdministeringMedication] = useState(false);
    const [administrationOpen, setAdministrationOpen] = useState(false);
    const [activeAdministration, setActiveAdministration] = useState(null);

    useEffect(() => {
        if(prescriptionsSelector.administeredPrescription && prescriptionsSelector.administeredPrescription !== null ){
            dispatch({
                type: SET_SUCCESS,
                payload: 'Prescription administered successfully!'
            })
            dispatch(clearAdministeredPrescription())
            reload()
            setAdministeringMedication(false)
        }
        return () => {
            
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, prescriptionsSelector.administeredPrescription]);

    const openAdministration = (date, index, option) => {
        console.log(date)
        console.log(index)
        console.log(option)
        const administration = getNthAdministration(date, index, option)
        console.log(administration)
        if(!administration){
            return
        }
        setActiveAdministration(administration)
        setTimeout(() => {
            setAdministrationOpen(true);
        }, 50);
    }

    const closeAdministration = () => {
        setAdministrationOpen(false);
        setTimeout(() => {
            setActiveAdministration(null)
        }, 50);
    }

    const [editing, setEditing] = useState(false);

    const maxDaysVisible = 15;
    // const currentDay = daysBetweenDates(prescription.startDate, new Date())
    const isDateInAdministrations = (administrations, targetDate, option = 'daily') => {
        const target = new Date(targetDate);
    
        // Normalize target date to the start of the hour, day, week, month, or year based on the option
        if (option === 'hourly') target.setMinutes(0, 0, 0);
        if (option === 'daily') target.setHours(0, 0, 0, 0);
        if (option === 'weekly') target.setDate(target.getDate() - target.getDay()); // Start of the week
        if (option === 'monthly') target.setDate(1); // Start of the month
        if (option === 'yearly') {
            target.setMonth(0, 1); // Start of the year
            target.setHours(0, 0, 0, 0);
        }
    
        let occurrences = 0;
    
        administrations.forEach(administration => {
            const adminDate = new Date(administration.date);
    
            if (option === 'hourly') {
                adminDate.setMinutes(0, 0, 0);
            }
            if (option === 'daily') {
                adminDate.setHours(0, 0, 0, 0);
            }
            if (option === 'weekly') {
                adminDate.setDate(adminDate.getDate() - adminDate.getDay());
            }
            if (option === 'monthly') {
                adminDate.setDate(1);
            }
            if (option === 'yearly') {
                adminDate.setMonth(0, 1);
                adminDate.setHours(0, 0, 0, 0);
            }
    
            if (adminDate.getTime() === target.getTime()) {
                occurrences += 1;
            }
        });
    
        return {
            exists: occurrences > 0,
            occurrences,
        };
    }

    function getNthAdministration(targetDate, n, option = 'daily') {
        const administrations = prescription.administrations
        const normalizeDate = (date, granularity) => {
            const normalized = new Date(date);
            if (granularity === 'hourly') normalized.setMinutes(0, 0, 0);
            if (granularity === 'daily') normalized.setHours(0, 0, 0, 0);
            if (granularity === 'weekly') {
                const dayOfWeek = normalized.getDay();
                normalized.setDate(normalized.getDate() - dayOfWeek);
                normalized.setHours(0, 0, 0, 0);
            }
            if (granularity === 'monthly') {
                normalized.setDate(1);
                normalized.setHours(0, 0, 0, 0);
            }
            if (granularity === 'yearly') {
                normalized.setMonth(0, 1);
                normalized.setHours(0, 0, 0, 0);
            }
            return normalized;
        };
    
        const normalizedTarget = normalizeDate(targetDate, option);
    
        // Debugging: Log normalized target date
        console.log(`Normalized Target: ${normalizedTarget}`);
    
        const matchingAdministrations = administrations.filter((administration) => {
            const adminDate = normalizeDate(administration.date, option);
    
            // Debugging: Log each administration date
            console.log(`Admin Date: ${adminDate}`);
    
            return adminDate.getTime() === normalizedTarget.getTime();
        });
    
        // Return the nth administration or null if not found
        return matchingAdministrations[n] || null;
    }

    const endDate = addDays(prescription.days, new Date(prescription.startDate))
    const hasEnded = new Date(endDate).setHours(0, 0, 0, 0) <= new Date().setHours(0, 0, 0, 0);

    const canAdminister = () => {
        const { exists, occurrences } = isDateInAdministrations(
            prescription.administrations,
            new Date(),
            prescription.frequency.type
        );
    
        // Ensure the prescription has started
        const hasStarted = new Date(prescription.startDate).setHours(0, 0, 0, 0) <= new Date().setHours(0, 0, 0, 0);

        // Check if administration is allowed
        return hasStarted && !hasEnded && (!exists || occurrences < prescription.frequency.times);
    };

    return (
        <>
            <div className='p-[15px] rounded-[8px] border border-gray-100 shadow-xl shadow-doiteasy-black/5'>
                <div className='flex items-start justify-between'>
                    <div className='flex items-center gap-x-[5px]'>
                        <h3 className='font-[550] text-doiteasy-black'>{prescription.medication}</h3>
                        <DrugClassPill type={prescription.drugClass} />
                    </div>
                    <div className='text-right'>
                        <button onClick={()=>{setEditing(true)}} className='flex items-center gap-x-[5px] justify-center text-xs font-[500] hover:text-gray-500 hover:bg-gray-200 transition duration-200 p-[7px] rounded bg-gray-100'>
                            <PenIcon className={`w-4 h-4`} />
                        </button>
                    </div>
                </div>
                <div className='flex items-center gap-x-[5px] mt-[5px] pb-[8px]'>
                    <p className='text-sm text-doiteasy-black font-[550] capitalize'>{prescription?.administrationRoute}</p>
                    <span className='block rounded-full bg-doiteasy-black w-[5px] h-[5px]' />
                    <p className='text-sm text-gray-500 font-[550]'>{prescription?.dosage?.measure} {prescription?.dosage?.measureUnit}</p>
                    <span className='block rounded-full bg-doiteasy-black w-[5px] h-[5px]' />
                    <p className='text-sm text-gray-500 font-[550]'>{prescription?.frequency?.times}x {prescription?.frequency?.type} for {prescription.days} {frequencies[prescription.frequency.type]}(s)</p>
                    <span className='block rounded-full bg-doiteasy-black w-[5px] h-[5px]' />
                    <p className='text-sm text-gray-500 font-[550]'>Start {transactionTimeStamp(prescription.startDate).date}</p>
                </div>
                <div className='flex items-start gap-x-[15px] mt-[5px] w-full border-t pt-[8px] pb-[10px]'>
                    {/* <div className='w-full'>
                        <p className='text-sm text-gray-500 font-[550]'>Start {transactionTimeStamp(prescription.startDate).date}</p>
                        <p className='text-xs text-gray-400 font-[500]'>Recorded by {prescription.createdBy.name}</p>
                    </div> */}
                    <div className='w-full'>
                        <label className={`text-sm lg:text-md cursor-text font-[550] z-10 relative transition mb-1 block duration-200 text-gray-400`}>
                            Dosage/Administration Instructions
                        </label>
                        <p className='text-sm text-gray-500 font-[550]'>{prescription?.dosage?.additionalInstruction}</p>
                    </div>
                    <div className='w-full'>
                        <label className={`text-sm lg:text-md cursor-text font-[550] z-10 relative transition mb-1 block duration-200 text-gray-400`}>
                            Frequency Instructions
                        </label>
                        <p className='text-sm text-gray-500 font-[550]'>{prescription?.frequency?.instructions}</p>
                    </div>
                </div>
                <div className='py-[10px] transition duration-200 flex items-start justify-between mt-[5px] w-full border-t cursor-pointer'>
                    <div className='flex items-start gap-x-[10px]'>
                        <div onClick={()=>{setAdministrationsOpen(!administrationsOpen)}} className='flex items-center justify-center w-[30px] mt-[5px]'>
                            <ChevronIcon className={`w-4 h-4 transition duration-200 ${administrationsOpen ? 'rotate-[270deg]' : 'rotate-[180deg]'}`} />
                        </div>
                        <div onClick={()=>{setAdministrationsOpen(!administrationsOpen)}} className='w-full'>
                            <h3 className='text-sm text-gray-500 font-[550]'>Administrations {hasEnded && <span className='text-[10px] text-green-600 bg-green-100 bg-opacity-20 p-[5px] rounded font-[550]'>Completed</span>}</h3>
                            <label className={`text-xs lg:text-md cursor-text font-[550] z-10 relative transition block duration-200 text-gray-400`}>
                                Click to see all administrations of this prescription. You can click on an administration to see more details
                            </label>
                        </div>
                    </div>

                    {canAdminister() ?
                        <button onClick={()=>{setAdministeringMedication(true)}} className='flex items-center justify-center w-max rounded-[8px] px-[16px] py-[10px] text-sm bg-green-600 text-doiteasy-light-gray font-[550] mr-[15px]'>
                            <CheckIcon className={`w-5 h-5`} />
                            Administer
                        </button> : 
                        <button className='flex items-center justify-center w-max rounded-[8px] px-[16px] py-[10px] text-sm bg-gray-100 text-gray-400 disabled cursor-not-allowed font-[550] mr-[15px]'>
                            <CheckIcon className={`w-5 h-5`} />
                            Administer
                        </button>
                    }

                </div>
                
                {administrationsOpen && 
                    <div className='mt-[10px]'>
                        <div className='flex items-start gap-x-[5px] mb-[10px]'>
                            <div className='w-[75px]'>
                                <div className='w-[70px]'>
                                    <p className='text-xs text-gray-400'>Times/{frequencies[prescription.frequency.type]}</p>
                                </div>
                            </div>
                            <div className='w-full flex items-start gap-x-[15px] mb-[10px]'>
                                {Array.from({ length: prescription.days > maxDaysVisible ? maxDaysVisible : prescription.days }).map((_, dayIndex) => {
                                    // Calculate the target date
                                    const targetDate = new Date(prescription.startDate); // Base date
                                    targetDate.setDate(targetDate.getDate() + dayIndex); // Increment days

                                    return (
                                        <div key={dayIndex} className='w-[40px]'>
                                            <p className='text-xs text-gray-400 capitalize'>
                                                {frequencies[prescription.frequency.type]} {dayIndex + 1}
                                            </p>
                                            <p className='text-xs text-gray-500 font-[550] capitalize'>
                                                {moment(targetDate).format('DD/MM/YY')}
                                            </p>
                                        </div>
                                    );
                                })}

                            </div>
                        </div>

                        <div className='w-full overflow-x-scroll mt-[10px]'>
                            {Array.from({ length: prescription.frequency.times }).map((_, index) => (
                                <div key={index} className='flex items-center gap-x-[5px] mb-[10px]'>
                                    <div className='w-[75px]'>
                                        <div className='w-[70px]'>
                                            <p className='text-xs text-gray-400'>x{index + 1}</p>
                                        </div>
                                    </div>
                                <div className='w-full flex items-center gap-x-[15px]'>
                                    {Array.from({ length: prescription.days > maxDaysVisible ? maxDaysVisible : prescription.days }).map((_, dayIndex) => {
                                    const targetDate = new Date(prescription.startDate); // Get the start date
                                    targetDate.setDate(targetDate.getDate() + dayIndex); // Add the day offset

                                    const { exists, occurrences } = isDateInAdministrations(
                                        prescription.administrations,
                                        targetDate,
                                        prescription.frequency.type
                                    );

                                    return (
                                        <div key={dayIndex} className='w-[40px] h-[40px] flex items-center justify-center'>
                                            <div
                                                onClick={() => {
                                                    openAdministration(targetDate, index, prescription.frequency.type);
                                                }}
                                                className={`w-[30px] h-[30px] flex items-center justify-center rounded-full
                                                ${exists && occurrences > index ? 'bg-green-500 cursor-pointer' : 'bg-gray-100 cursor-not-allowed'}
                                                `}
                                            >
                                                {/* {exists && occurrences > index ? ( */}
                                                <CheckIcon className='w-5 h-5 text-white' />
                                                {/* ) : null} */}
                                            </div>
                                        </div>
                                    );
                                    })}
                                </div>
                                </div>
                            ))}
                            </div>

                    </div>
                }
            </div>

            <ModalDialog
                shown={editing} 
                closeFunction={()=>{setEditing(false)}} 
                actionFunction={()=>{}} 
                actionFunctionLabel={``}
                dialogTitle={<span className='capitalize font-space-grotesk'>Administer {prescription.medication} to {resident.title} {resident.firstName} {resident.lastName}</span>}
                maxWidthClass='max-w-4xl'
                hideActions={true}
            >
                <EditResidentPrescription
                    resident={resident}
                    prescription={prescription}
                    close={()=>{setEditing(false)}}
                />
            </ModalDialog>

            <ModalDialog
                shown={administeringMedication} 
                closeFunction={()=>{setAdministeringMedication(false)}} 
                actionFunction={()=>{}} 
                actionFunctionLabel={``}
                dialogTitle={<h3 className='capitalize'>Administer {prescription.medication} to {resident.title} {resident.firstName} {resident.lastName}</h3>}
                maxWidthClass='max-w-lg'
                hideActions={true}
            >
               <AdministerMedication 
                    prescription={prescription} 
                    resident={resident} 
                    close={()=>{setAdministeringMedication(false)}} 
                />
            </ModalDialog>

            {activeAdministration && <ModalDialog
                shown={administrationOpen} 
                closeFunction={()=>{setAdministrationOpen(false)}} 
                actionFunction={()=>{}} 
                actionFunctionLabel={``}
                dialogTitle={<span className='capitalize font-space-grotesk'>{prescription.medication} administration for {resident.title} {resident.firstName} {resident.lastName}</span>}
                maxWidthClass='max-w-lg'
                hideActions={true}
            >
                <MedicationAdministration administration={activeAdministration} close={()=>{closeAdministration()}} />
            </ModalDialog>}
        </>
    )
}

export default ResidentPrescriptionCard
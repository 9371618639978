import React from 'react'

const CareTypePill = ({careType}) => {
  return (
    <span className={`rounded-[6px] py-[6px] px-[8px] capitalize text-xs font-[550] 
        ${careType==='1:1' && 'bg-green-100 text-green-600 border-green-500'} 
        ${careType==='2:1' && 'bg-yellow-100 bg-opacity-30 text-yellow-800 border-yellow-500'} 
        ${careType==='shared' && 'bg-red-100 bg-opacity-30 text-red-600 border-red-500'} 
    `}>{careType} care</span>
  )
}

export default CareTypePill
import React, { Fragment, useEffect, useState } from 'react'
import PrimaryButton from '../../elements/PrimaryButton'
import PlusWhite from '../../../assets/images/icons/plus.svg';
import Pagination from '../../elements/Pagination';
import EmptyState from '../../elements/EmptyState';
import { useDispatch, useSelector } from 'react-redux';
import ResidentReportCard from '../../elements/residents/ResidentReportCard';
import { clearCreatedResidentReport, fetchResidentReports } from '../../../store/actions/residentReportsActions';
import ModalDialog from '../../layouts/ModalDialog';
import NewResidentReport from './NewResidentReport';
import { SET_SUCCESS } from '../../../store/types';
import Caregiver1 from '../../../assets/images/caregivers/caregiver1.webp'


const ResidentReports = ({resident}) => {
  const reportsSelector = useSelector(state => state.residentReports)
  const dispatch = useDispatch()
  const [creatingReport, setCreatingReport] = useState(false);
  // const [reload, setReload] = useState(0);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(25);

  useEffect(() => {
    dispatch(fetchResidentReports(resident._id, '', page, perPage))

    if(reportsSelector.createdResidentReport && reportsSelector.createdResidentReport !== null) {
      dispatch(clearCreatedResidentReport())
      dispatch({
        type: SET_SUCCESS,
        payload: 'Report submitted successfully'
      })
      setCreatingReport(false)
    }

    return () => {
      
    };
  }, [dispatch, page, perPage, reportsSelector.createdResidentReport, resident._id]);

  const [activeReportRange, setActiveReportRange] = useState('today');

  return (
    <>
      <div>
        <div className='w-full flex items-start justify-between mb-[20px]'>
            <div className='w-1/2'>
                <h3 className='text-doiteasy-black font-[550] capitalize'>Reports on {resident.title} {resident.firstName} {resident.lastName}</h3>
                <p className='text-sm text-gray-500 font-[500]'>
                    View, manage and export reports for this resident. You can create a new report by clicking on "Create new Report".
                </p>
            </div>
            <div className='flex flex-row-reverse'>
                <PrimaryButton label={`Create new Report`} icon={PlusWhite} buttonAction={()=>{setCreatingReport(true)}} />
            </div>
        </div>

        <div className='w-full'>
          {reportsSelector?.residentReports?.reports?.length > 0 ?
            <div className='w-full mt-[10px]'>
              <div className='p-[15px] bg-gray-50 rounded-[8px]'>
                <h3 className='text-doiteasy-black font-[550] capitalize text-sm'>Reports Date Range</h3>
                <p className='text-xs text-gray-500 font-[500]'>
                    Select a date range below to filter reports
                </p>
                <div className='w-full flex items-center gap-x-[20px] mt-[10px]'>
                  <button 
                    onClick={()=>{setActiveReportRange('today')}}
                    className={`border text-xs px-[12px] py-[6px] rounded-[5px] ${activeReportRange === 'today' ? 'bg-doiteasy-black text-doiteasy-light-gray border-doiteasy-black' : 'border-gray-200 text-gray-600 bg-doiteasy-light-gray'}`}>
                    Today
                  </button>
                  <button 
                    onClick={()=>{setActiveReportRange('this-week')}}
                    className={`border text-xs px-[12px] py-[6px] rounded-[5px] ${activeReportRange === 'this-week' ? 'bg-doiteasy-black text-doiteasy-light-gray border-doiteasy-black' : 'text-gray-600 bg-doiteasy-light-gray border-gray-200'}`}>
                    This Week
                  </button>
                  <button
                  onClick={()=>{setActiveReportRange('this-month')}} 
                  className={`border text-xs px-[12px] py-[6px] rounded-[5px] ${activeReportRange === 'this-month' ? 'bg-doiteasy-black text-doiteasy-light-gray border-doiteasy-black' : 'text-gray-600 bg-doiteasy-light-gray border-gray-200'}`}>
                    This Month
                  </button>
                  <button 
                  onClick={()=>{setActiveReportRange('this-year')}}
                  className={`border text-xs px-[12px] py-[6px] rounded-[5px] ${activeReportRange === 'this-year' ? 'bg-doiteasy-black text-doiteasy-light-gray border-doiteasy-black' : 'text-gray-600 bg-doiteasy-light-gray border-gray-200'}`}>
                    This Year
                  </button>
                </div>
              </div>
              <div className='w-full mt-[20px]'>
                  {reportsSelector?.residentReports?.reports?.map((report, reportIndex) => (
                      <div key={reportIndex} className='w-[90%] mx-auto mb-[20px] flex items-start gap-x-[10px]'>
                        <div className='w-[50px] flex flex-col items-center'>
                          <div className='w-[50px] h-[50px] rounded-full flex items-center justify-center bg-doiteasy-light-gray'>
                            <div className='w-[40px] h-[40px] rounded-full bg-doiteasy-light-gray'
                              style={{
                                      backgroundImage: `url(${Caregiver1})`, 
                                      backgroundSize: 'cover',
                                      backgroundRepeat: 'no-repeat',
                                      backgroundPosition: 'center center'
                                  }}
                            ></div>
                          </div>
                          {/* <div className='w-[2px] h-[300px] bg-gray-200'></div> */}
                        </div>
                        <div className='w-full'>
                          <ResidentReportCard resident={resident} report={report} />
                        </div>
                      </div>
                  ))}
              </div>
              <Pagination 
                  pagination={{
                    page,
                    perPage,
                    totalItems: reportsSelector?.residentReports?.total
                  }} 
                  changePage={(page)=>{setPage(page)}} 
                  updatePerPage={(perPage)=>{setPerPage(perPage)}} 
              />
            </div>
            :
            <div className='w-full'>
              <div className='mt-[50px] w-[500px] mx-auto'>
                <EmptyState  
                  emptyStateTitle={`No reports created on this resident yet.`}
                  emptyStateText={`You can create new reports for this resident by clicking on the "Create new report" button above.`}
                />
              </div>
            </div>
          }
        </div>
      </div>

      <ModalDialog
        shown={creatingReport} 
        closeFunction={()=>{setCreatingReport(false)}} 
        actionFunction={()=>{}}
        actionFunctionLabel={``}
        dialogTitle={<span className='capitalize font-space-grotesk'>Create report on {resident.title} {resident.firstName} {resident.lastName}</span>}
        maxWidthClass='max-w-3xl'
        hideActions={true}
      >
        <NewResidentReport 
          resident={resident}
          close={()=>{setCreatingReport(false)}}
        />
      </ModalDialog>
    </>
  )
}

export default ResidentReports
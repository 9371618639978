import React, { useState } from 'react'
import TextField from '../form/TextField'
import TextareaField from '../form/TextareaField'
import { useDispatch, useSelector } from 'react-redux'
import { ERROR } from '../../../store/types'
import { createDepartment } from '../../../store/actions/departmentActions'
import SecondaryButton from '../SecondaryButton'
import FormButton from '../form/FormButton'

const NewDepartment = ({close}) => {
    const dispatch = useDispatch()
    const departmentsSelector = useSelector(state => state.departments)
    const [departmentPayload, setDepartmentPayload] = useState({});
    const [validationErrors, setValidationErrors] = useState({});

    const validateForm = () => {
        let errors = {}
        if(!departmentPayload.name || departmentPayload.name === '') {
            errors.name = true
        }
        
        if(!departmentPayload.description || departmentPayload.description === '') {
            errors.description = true
        }

        setValidationErrors(errors)

        return errors
    }

    const pushDepartment = async () => {
        if (Object.values(validateForm()).includes(true)) {
            dispatch({
                type: ERROR,
                error: {response: {data: {
                    message: 'Please check the highlighted fields'
                }}}
            })
            return
        }

        const payload = {...departmentPayload}

        dispatch(createDepartment(payload))
    }

    return (
        <div className='w-full'>
            <p className="text-sm mt-[12px] text-gray-600">Please provide details of the room below to create a new room</p>

            <div className='w-full'>
                <div className='mt-4'>
                    <TextField
                        inputLabel="Department name" 
                        fieldId="room-name" 
                        inputType="text" 
                        preloadValue={''}
                        inputPlaceholder={'Eg: Administration'}
                        hasError={validationErrors && validationErrors.name} 
                        returnFieldValue={(value)=>{setDepartmentPayload({...departmentPayload, ...{name: value}})}}
                    />
                </div>

                <div className='mt-4'>
                    <TextareaField
                        inputLabel="Description" 
                        fieldId="room-description" 
                        inputType="text" 
                        preloadValue={''}
                        inputPlaceholder={'Describe the department or its functions'}
                        hasError={validationErrors && validationErrors.description} 
                        returnFieldValue={(value)=>{setDepartmentPayload({...departmentPayload, ...{description: value}})}}
                    />
                </div>
            </div>
            <div className='w-full flex ml-auto items-center justify between gap-x-[10px] mt-[40px]'>
                <div className='w-max'>
                    <SecondaryButton 
                        buttonLabel={`Cancel`} 
                        buttonAction={()=>{close()}} 
                        processing={false} 
                    />
                </div>
                <FormButton 
                    buttonLabel={`Create Department`} 
                    buttonAction={()=>{pushDepartment()}} 
                    processing={departmentsSelector.creatingDepartment} 
                />
            </div>
        </div>
    )
}

export default NewDepartment
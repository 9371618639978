import React, { useState } from 'react'
import FormButton from '../../elements/form/FormButton'
import SecondaryButton from '../../elements/SecondaryButton'
import TextareaField from '../../elements/form/TextareaField'
import { userDetails } from '../../../utils'
import { useDispatch, useSelector } from 'react-redux'
import { administerResidentPrescription } from '../../../store/actions/ResidentPrescriptionsActions'

const AdministerMedication = ({resident, prescription, close}) => {
    const dispatch = useDispatch()
    const prescriptionsSelector = useSelector(state => state.prescriptions)
    const [administrationNote, setAdministrationNote] = useState(null);

    const administer = () => {
        const payload = {
            resident: resident._id,
        }
        if(administrationNote){
            payload.note = administrationNote
        }

        dispatch(administerResidentPrescription(prescription._id, payload))
    }

    return (
        <div className='w-full'>
            <div className='w-full'>
                <p className='text-sm font-[500] text-gray-500'>Please confirm that you, {userDetails().name} have administered <span className='font-[550] text-doiteasy-black'>{prescription.medication}</span> to <span className='font-[550] text-doiteasy-black capitalize'>{resident.title} {resident.firstName} {resident.lastName}</span></p>
            </div>

            <div className='mt-2 w-full'>
                <TextareaField
                    inputLabel="Add a note if required" 
                    fieldId="administration-note" 
                    inputType="text" 
                    preloadValue={''}
                    inputPlaceholder={'Add any occurrence of note during this administration'}
                    hasError={false} 
                    returnFieldValue={(value)=>{setAdministrationNote(value)}}
                />
            </div>

            <div className='w-full flex ml-auto items-center justify between gap-x-[10px] mt-[40px]'>
                <div className='w-max'>
                    <SecondaryButton 
                        buttonLabel={`Cancel`} 
                        buttonAction={()=>{close()}} 
                        processing={false} 
                    />
                </div>
                <FormButton 
                    buttonLabel={`Confirm`} 
                    buttonAction={()=>{administer()}} 
                    processing={prescriptionsSelector.administeringPrescription} 
                />
            </div>
        </div>
    )
}

export default AdministerMedication
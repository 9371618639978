import React from 'react'
import TrashIcon from '../icons/TrashIcon'
import ArrowIcon from '../icons/ArrowIcon'

const DepartmentCard = ({department}) => {
  return (
    <div className='w-full bg-white'>
        <div className='p-[15px]'>
            <h3 className='font-[550] text-doiteasy-black mb-[10px]'>{department.name}</h3>
            <p className='text-sm font-[500] text-gray-400 mb-[20px]'>{department.description.substr(0, 150)}{department.description.length > 150 && '...'}</p>
            <p className='text-xs font-[550] mb-[20px] text-gray-600'>No HOD assigned yet</p>
            <span className='rounded text-xs bg-gray-100 text-gray-500 px-[10px] py-[5px] font-[550] '>0 Staff</span>
        </div>

        <div className="w-full flex items-center justify-between mt-3 p-3 border-t">
            <button onClick={()=>{}} className='text-sm flex flex-row items-center gap-x-3 text-vcm-purple hover:text-gray-400 transition duration-200'>Department Details <ArrowIcon className={`w-5 h-5`} /></button> 

            <button onClick={()=>{}} className='p-1 rounded bg-transparent hover:bg-red-100 transition duration-200 text-gray-400 hover:text-vcm-purple'>
                <TrashIcon className={`w-5 h-5`} />
            </button>
        </div>
    </div>
  )
}

export default DepartmentCard
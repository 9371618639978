import React, {useState} from 'react'
import ModalDialog from '../../../components/layouts/ModalDialog'
import { Switch } from '@headlessui/react'
import ArrowRightLeft from '../../../components/elements/icons/ArrowRightLeft'

const Security = () => {
    const [passwordResetModal, setPasswordResetModal] = useState(false)
    const [twoFaModal, setTwoFaModal] = useState(false)
    const [twoFaActive, setTwoFaActive] = useState(false)

    const changePassword = () => {
        console.log('password changed :)')
    }

    const activate2Fa = () => {
        console.log('2FA activated :)')
    }

    // const deactivate2Fa = () => {
    //     console.log('2FA activated :)')
    // }

    return (
        <>
            <div className='w-full flex justify-between'>
                <div>
                    <h3 className='text-lg font-medium'>Security Settings</h3>
                    <p className='text-sm'>Use the settings below to secure your account</p>
                </div>
                <div>
                {/* <Link to={`new-role`} className='rounded px-4 py-3 mt-4 text-sm text-purple-100 bg-vcm-purple transition duration-200 border border-vcm-purple hover:bg-vcm-light-purple hover:text-vcm-purple flex items-center justify-center gap-x-2'>
                    <PlusIcon className='w-5 h-5' /> Create new role
                </Link> */}

                </div>
            </div>
            
            <div className='grid grid-cols-3 gap-8 my-8'>
                <div className="bg-white p-4">
                    <div>
                        <p className="text-primary text-sm uppercase tracking-[0.2em] font-medium">Account Password</p>
                        <p className="text-sm mt-2 text-opacity-40">Manage your account password</p>

                        <div className="w-full flex items-center justify-between mt-3 py-3 border-t">
                            <button onClick={()=>{setPasswordResetModal(true)}} className='text-sm flex flex-row items-center gap-x-3 text-vcm-purple hover:text-gray-400 transition duration-200'>Change Password <ArrowRightLeft className={`w-4 h-4`} /></button> 

                            {/* <button onClick={()=>{deleteDesignation()}} className='p-1 rounded bg-transparent hover:bg-vcm-light-purple hover:bg-opacity-20 transition duration-200 text-gray-400 hover:text-vcm-purple'>
                                <TrashIcon className={`w-5 h-5`} />
                            </button> */}
                        </div>
                    </div>

                </div>

                <div className="bg-white p-4">
                    <div>
                        <p className="text-primary text-sm uppercase tracking-[0.2em] font-medium">Two-Factor Authentication</p>
                        <p className="text-sm mt-2 text-opacity-40">Manage 2FA settings for your account</p>

                        <div className="w-full flex items-center justify-between mt-3 py-3 border-t">
                            <button onClick={()=>{setTwoFaModal(true)}} className='text-sm flex flex-row items-center gap-x-3 text-vcm-purple hover:text-gray-400 transition duration-200'>Activate 2FA <ArrowRightLeft className={`w-4 h-4`} /></button> 

                            {/* <button onClick={()=>{deleteDesignation()}} className='p-1 rounded bg-transparent hover:bg-vcm-light-purple hover:bg-opacity-20 transition duration-200 text-gray-400 hover:text-vcm-purple'>
                                <TrashIcon className={`w-5 h-5`} />
                            </button> */}
                        </div>
                    </div>

                </div>

                {/* <div className="bg-white p-4 rounded-lg flex flex-row gap-x-3 shadow-md items-center w-1/3 transition duration-200 hover:bg-opacity-20 cursor-pointer hover:shadow-lg" onClick={()=>{setTwoFaModal(true)}}>
                    <div className="p-3 rounded-lg text-secondary bg-accent shadow-md">
                        <LockIcon classes="h-6 w-6" />
                    </div>
                    <div>
                        <p className="text-primary text-sm font-medium">Two-Factor Authentication</p>
                        <p className="text-xs mt-2 text-opacity-40">Manage two-factor authentication settings for your account</p>
                    </div>
                </div> */}
            </div>

            <ModalDialog 
                shown={passwordResetModal} 
                closeFunction={()=>{setPasswordResetModal(false)}} 
                actionFunction={()=>{changePassword()}} 
                actionFunctionLabel='Change Password' 
                dialogTitle='Change Your Account Password'
                maxWidthClass='max-w-md'
            >
                <div>
                    <label className="mt-6 mb-3 text-xs text-primary block">Current Password</label>
                    <input type="password" placeholder="Provide your current password" className="w-full px-4 py-3 rounded border border-primary border-opacity-30 focus:border-opacity-60 text-xs focus:outline-none transition duration-200" />
                </div>
                <div className='mt-10'>
                    <label className="mt-6 mb-3 text-xs text-primary block">New Password</label>
                    <input type="password" placeholder="Choose a new password" className="w-full px-4 py-3 rounded border border-primary border-opacity-30 focus:border-opacity-60 text-xs focus:outline-none transition duration-200" />
                </div>
                <div>
                    <label className="mt-6 mb-3 text-xs text-primary block">Confirm New Password</label>
                    <input type="password" placeholder="Re-type new password" className="w-full px-4 py-3 rounded border border-primary border-opacity-30 focus:border-opacity-60 text-xs focus:outline-none transition duration-200" />
                </div>
            </ModalDialog>

            <ModalDialog 
                shown={twoFaModal} 
                closeFunction={()=>{setTwoFaModal(false)}} 
                actionFunction={()=>{activate2Fa()}} 
                actionFunctionLabel='Activate two-factor authentication' 
                dialogTitle='Activate/Deactivate Two-Factor Authentication'
                maxWidthClass='max-w-md'
            >
                <div className="w-full flex justify-between gap-x-4 py-4 border-b border-secondary border-opacity-50">
                    <div className='w-4/5'>
                        <p className="text-sm font-sofia-pro">Activate Two-factor Authentication</p>
                        <p className='text-gray-500 text-xs mt-2'>
                            Two-factor authentication further protects access your account by sending an OTP to your registered phone number and email address everytime you make an attempt to log in to your account
                        </p>
                    </div>
                    <Switch
                        checked={twoFaActive}
                        onChange={setTwoFaActive}
                        className={`${
                            twoFaActive ? 'bg-doiteasy-blue' : 'bg-gray-400'
                        } relative inline-flex items-center h-5 rounded-full w-10`}
                        >
                        <span className="sr-only">Activate 2FA</span>
                        <span
                            className={`transform transition ease-in-out duration-200 ${
                                twoFaActive ? 'translate-x-6 bg-white' : 'translate-x-1 bg-gray-600'
                            } inline-block w-3 h-3 transform rounded-full`}
                        />
                    </Switch>
                </div>
            </ModalDialog>
        </>
    )
}

export default Security

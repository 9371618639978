import React, { useEffect, useState } from 'react'
import PlusWhite from '../../../../assets/images/icons/plus.svg';
import PrimaryButton from '../../../../components/elements/PrimaryButton';
import EmptyState from '../../../../components/elements/EmptyState';
import ModalDialog from '../../../../components/layouts/ModalDialog';
import { useDispatch, useSelector } from 'react-redux';

import Preloader from '../../../../components/elements/Preloader';
import Pagination from '../../../../components/elements/Pagination';
import { SET_SUCCESS } from '../../../../store/types';
import { clearCreatedDepartment, fetchDepartments } from '../../../../store/actions/departmentActions';
import NewDepartment from '../../../../components/elements/departments/NewDepartment';
import DepartmentCard from '../../../../components/elements/departments/DepartmentCard';

const Departments = () => {
    const dispatch = useDispatch()
    const departmentsSelector = useSelector(state => state.departments)

    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(25);
    // eslint-disable-next-line no-unused-vars
    const [filters, setFilters] = useState('');

    useEffect(() => {
        dispatch(fetchDepartments(filters, page, perPage))

        if(departmentsSelector.createdDepartment !== null) {
            dispatch({
                type: SET_SUCCESS,
                payload: "New department created successfully"
            })
            dispatch(clearCreatedDepartment())
            setCreatingNewDepartment(false)
        }
        return () => {
            
        };
    }, [dispatch, filters, page, perPage, departmentsSelector.createdDepartment]);

    const [creatingNewDepartment, setCreatingNewDepartment] = useState(false);


    return (
        <>
            <div className='w-full'>
                <div className='flex items-center justify-between'>
                    <div className='w-1/2'>
                        <h3 className='font-[550] mb-[5px]'>Care Home Departments</h3>
                        <p className='text-sm font-[500] text-gray-600'>A record of all departments in your care home. Care home staff can be assigned to these departments to define their functions</p>
                    </div>
                    <div className='w-1/2 flex flex-row-reverse gap-x-[10px]'>
                        <PrimaryButton label={`Create new department`} icon={PlusWhite} buttonAction={()=>{setCreatingNewDepartment(true)}} />
                        {/* <FiltersV2 /> */}
                    </div>
                </div>

                {departmentsSelector?.loadingDepartments ? 
                    <Preloader preloadingText={`Loading rooms`} />
                    :
                    <>
                    {departmentsSelector?.departments?.departments?.length > 0 ? <div className=''>
                        
                        <div className='grid lg:grid-cols-2 xl:grid-cols-3 gap-[10px] 2xl:grid-cols-4 mt-[40px]'>
                            {departmentsSelector?.departments?.departments?.map((department, departmentIndex)=>(<div key={departmentIndex} className='w-full'>
                                <DepartmentCard department={department} />
                            </div>))}
                        </div>
                        <Pagination 
                            pagination={{
                                page,
                                perPage,
                                totalItems: departmentsSelector?.rooms?.total
                            }} 
                            changePage={(page)=>{setPage(page)}} 
                            updatePerPage={(perPage)=>{setPerPage(perPage)}} 
                        />
                    </div>
                    :
                        <div className='bg-white p-[50px] mt-[20px] rounded-[20px]'>
                            <div className='w-1/2 mx-auto'>
                                <EmptyState 
                                    emptyStateTitle={`No Departments Found`} 
                                    emptyStateText={`It seems you have not created any departments for your care home yet. If you need to create a department right now, use the "Create new department" button at the top right`} 
                                />
                            </div>
                        </div>}
                    </>}

                
            </div>

            <ModalDialog
                shown={creatingNewDepartment} 
                closeFunction={()=>{setCreatingNewDepartment(false)}} 
                actionFunction={()=>{}} 
                actionFunctionLabel={``}
                maxWidthClass='max-w-lg'
                hideActions={true}
                dialogTitle={`Create a new department`}
            >
                <NewDepartment 
                    close={()=>{setCreatingNewDepartment(false)}}
                />
            </ModalDialog>
        </>
    )
}

export default Departments
import React, { useState } from 'react'
import RadioGroup from '../../elements/form/RadioGroup'
import { careTypes } from '../../../utils'
import SecondaryButton from '../../elements/SecondaryButton';
import FormButton from '../../elements/form/FormButton';
import { useSelector } from 'react-redux';

const ChangeCareType = ({resident, close, updateCareType}) => {

    const [careType, setCareType] = useState('');
    const residentsSelector = useSelector(state => state.residents)

    const doChange = () => {
        updateCareType(careType)
    }

    return (
        <div>
            <p className='text-sm font-[500] text-gray-500'>Select a room for the resident and the required care type to check them in</p>

            <div className='w-full'>
                <RadioGroup
                    items={careTypes}
                    inputLabel="Gender"
                    titleField="label"
                    displayImage={false}
                    imageField=""
                    preSelectedIndex={careTypes.findIndex(item => item.value === resident?.careTypeRequired)}
                    // preSelectedIndex={null}
                    fieldId={`title`}
                    inline={true}
                    hasError={false}
                    showDescriptions={true}
                    returnSelected={(value) => {setCareType(value.value)}}
                />
            </div>

            <div className='w-full flex mx-auto items-center justify between gap-x-[10px] mt-[40px]'>
                <div className='w-max'>
                    <SecondaryButton 
                        buttonLabel={`Cancel`} 
                        buttonAction={()=>{close()}} 
                        processing={false} 
                    />
                </div>
                <FormButton 
                    buttonLabel={`Change Care Type`} 
                    buttonAction={()=>{doChange()}} 
                    processing={residentsSelector.updatingResident} 
                />
            </div>
        </div>
  )
}

export default ChangeCareType
import React, { useState } from 'react'
import SecondaryButton from '../../elements/SecondaryButton'
import FormButton from '../../elements/form/FormButton'
import { useDispatch, useSelector } from 'react-redux'
import NumberField from '../../elements/form/NumberField'

const RecordResidentVitals = ({resident, latestVitals, close}) => {
    const vitalsSelector = useSelector(state => state.residentVitals)
    const dispatch = useDispatch()
    const [vitalsPayload, setVitalsPayload] = useState(latestVitals || {});
    const [validationErrors, setValidationErrors] = useState({});
    const pushVitals = () => {

    }

    return (
        <div className='w-full'>
            <div className='w-full mt-2'>
                <NumberField 
                    inputType="number" 
                    fieldId={`temperature`}
                    inputLabel="Body Temperature (&deg;C)" 
                    requiredField={true}
                    preloadValue={vitalsPayload?.bodyTemperature || ''}
                    hasError={validationErrors[`frequency-times`]} 
                    returnFieldValue={(value)=>{setVitalsPayload({...vitalsPayload, ...{bodyTemperature: value}})}}
                />
            </div>
            <div className='w-full mt-2'>
                <NumberField 
                    inputType="number" 
                    fieldId={`weight`}
                    inputLabel="Weight (kg)" 
                    requiredField={true}
                    preloadValue={vitalsPayload?.weight || ''}
                    hasError={validationErrors[`frequency-times`]} 
                    returnFieldValue={(value)=>{setVitalsPayload({...vitalsPayload, ...{weight: value}})}}
                />
            </div>
            <div className='w-full mt-2'>
                <NumberField 
                    inputType="number" 
                    fieldId={`heart-rate`}
                    inputLabel="Heart Rate (bpm)" 
                    requiredField={true}
                    preloadValue={vitalsPayload?.heartRate || ''}
                    hasError={validationErrors[`heartRate`]} 
                    returnFieldValue={(value)=>{setVitalsPayload({...vitalsPayload, ...{heartRate: value}})}}
                />
            </div>
            <div className='w-full mt-2'>
                <NumberField 
                    inputType="number" 
                    fieldId={`respiratory-rate`}
                    inputLabel="Respiratory Rate (breaths/minute)" 
                    requiredField={true}
                    preloadValue={vitalsPayload?.respiratoryRate || ''}
                    hasError={validationErrors[`respiratoryRate`]} 
                    returnFieldValue={(value)=>{setVitalsPayload({...vitalsPayload, ...{respiratoryRate: value}})}}
                />
            </div>
            <p className='font-[500] text-sm mt-2'>Blood Pressure</p>
            <div className='w-full flex items-start justify-between mt-2 gap-x-[20px]'>
                <div className='w-full'>
                    <NumberField 
                        inputType="number" 
                        fieldId={`bg-dias`}
                        inputLabel="Diastolic (mm/Hg)" 
                        requiredField={true}
                        preloadValue={vitalsPayload?.bloodPressure?.diastolic || ''}
                        hasError={validationErrors[`respiratoryRate`]} 
                        returnFieldValue={(value)=>{setVitalsPayload({...vitalsPayload, ...{diastolic: value}})}}
                    />
                </div>
                <div className='w-full'>
                    <NumberField 
                        inputType="number" 
                        fieldId={`bp-sys`}
                        inputLabel="Systolic (mm/Hg)" 
                        requiredField={true}
                        preloadValue={vitalsPayload?.bloodPressure?.systolic || ''}
                        hasError={validationErrors[`respiratoryRate`]} 
                        returnFieldValue={(value)=>{setVitalsPayload({...vitalsPayload, ...{systolic: value}})}}
                    />
                </div>
            </div>

            <div className='w-full mt-2'>
                <NumberField 
                    inputType="number" 
                    fieldId={`fluid-intake`}
                    inputLabel="Fluid intake (mm)" 
                    requiredField={true}
                    preloadValue={vitalsPayload?.fluidIntake || ''}
                    hasError={validationErrors[`fluidIntake`]} 
                    returnFieldValue={(value)=>{setVitalsPayload({...vitalsPayload, ...{fluidIntake: value}})}}
                />
            </div>

            <div className='w-full mt-2'>
                <NumberField 
                    inputType="number" 
                    fieldId={`fluid-output`}
                    inputLabel="Fluid Output (mm)" 
                    requiredField={true}
                    preloadValue={vitalsPayload?.fluidOutput || ''}
                    hasError={validationErrors[`fluidIntake`]} 
                    returnFieldValue={(value)=>{setVitalsPayload({...vitalsPayload, ...{fluidOutput: value}})}}
                />
            </div>
            <div className='w-full flex ml-auto items-center justify between gap-x-[10px] mt-[40px]'>
                <div className='w-max'>
                    <SecondaryButton
                        buttonLabel={`Cancel`} 
                        buttonAction={()=>{close()}} 
                        processing={false} 
                    />
                </div>
                <FormButton 
                    buttonLabel={`Record Vitals`} 
                    buttonAction={()=>{pushVitals()}} 
                    processing={false} 
                    // processing={vitalsSelector.creatingResidentVitals} 
                />
            </div>
        </div>
    )
}

export default RecordResidentVitals
import React from 'react'

const PrimaryButton = ({label, icon, buttonAction}) => {
  const doButtonAction = () => {
    if(!buttonAction) return
    buttonAction()
  }
  return (
    <div>
        <button onClick={doButtonAction} className='flex items-center justify-center hover:bg-blue-700 transition duration-200 hover:shadow-lg gap-x-[16px] rounded-[20px] font-space-grotesk text-white bg-doiteasy-blue px-[24px] py-[16px] '>
            {label}
            <div className='w-[30px] h-[30px] rounded-full bg-white bg-opacity-20 flex items-center justify-center'>
                <img src={icon} className='w-[16px] h-[16px]' alt='' />
            </div>
        </button>
    </div>
  )
}

export default PrimaryButton
import React from 'react'

const ActivityTypePill = ({activityType}) => {
  return (
    <span className={`inline-block rounded-full px-[8px] text-[12px] font-[550] border capitalize 
      ${activityType === 'physical' && 'bg-green-500 bg-opacity-10 border-green-500 border-opacity-50 text-green-500'}
      ${activityType === 'medical' && 'bg-red-500 bg-opacity-10 border-red-500 border-opacity-50 text-red-500'}
      ${activityType === 'sanitary' && 'bg-blue-500 bg-opacity-10 border-blue-500 border-opacity-50 text-blue-500'}
    `}>
      {activityType}
    </span>
  )
}

export default ActivityTypePill
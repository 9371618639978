import React, {useState} from 'react'
import Layout from '../../components/layouts/Layout'
import { userDetails } from '../../utils'
import ArrowIcon from '../../components/elements/icons/ArrowIcon'
// import BarChart from '../../components/partials/BarChart'
// import ArrowIcon from '../../components/elements/icons/ArrowIcon'
// import MicrophoneIcon from '../../components/elements/icons/MicrophoneIcon'
// import ClipboardIcon from '../../components/elements/icons/ClipboardIcon'
// import SlidersIcon from '../../assets/images/icons/sliders.svg'
import UserIcon from '../../assets/images/icons/user-line.svg'
import UsersIcon from '../../assets/images/icons/users-line.svg'
import ClipboardIcon from '../../assets/images/icons/clipboard-line.svg'
// import BuildingsIcon from '../../assets/images/icons/buildings-line.svg'
import Resident1 from '../../assets/images/residents/resident1.webp'
import Resident2 from '../../assets/images/residents/resident2.jpeg'
import Resident3 from '../../assets/images/residents/resident3.webp'
import Resident4 from '../../assets/images/residents/resident4.jpeg'
import Caregiver1 from '../../assets/images/caregivers/caregiver1.webp'
import Caregiver2 from '../../assets/images/caregivers/caregiver2.webp'
import Caregiver3 from '../../assets/images/caregivers/caregiver3.webp'
import Caregiver4 from '../../assets/images/caregivers/caregiver4.webp'
import Caregiver5 from '../../assets/images/caregivers/caregiver5.jpeg'
import ResidentActivityCard from '../../components/partials/ResidentActivityCard'
import CaregiverOnCallCard from '../../components/partials/CaregiverOnCallCard'
import VisitorCard from '../../components/partials/VisitorCard'
import Calendar from '../../components/elements/Calendar'
import CloseIcon from '../../components/elements/icons/CloseIcon'
import CheckIcon from '../../components/elements/icons/CheckIcon'
import InventoryInGrayscale from '../../assets/images/icons/inventory-in-icon.svg'
import TaskCard from '../../components/partials/TaskCard'
import FiltersV2 from '../../components/elements/FiltersV2'

const Overview = () => {

    const regions = [
        {
            name: "North Africa",
            color: "#CC6502",
            count: 220
        },
        {
            name: "East Africa",
            color: "#680C01",
            count: 91
        },
        {
            name: "South Africa",
            color: "#993399",
            count: 120
        },
        {
            name: "West Africa",
            color: "#000009",
            count: 210
        },
        {
            name: "Cantral Africa",
            color: "#095E59",
            count: 40
        },
    ]
    
    const mapChartData = () => {
    const series = []
    const colors = []
    const labels = []

    regions.forEach((element, index) => {
        series.push(element.count)
        colors.push(element.color)
        labels.push(element.name)
    });

    return {
        series, colors, labels
    }
    }

    // eslint-disable-next-line no-unused-vars
    const [chartData, setChartData] = useState(mapChartData())

    const residentActivities = [
        {
            residentName: 'Clark Hannaman',
            residentPicture: Resident1,
            caregiverName: 'Ahmed Ibrahimovic',
            activityType: 'physical',
            description: 'Prescribed workout',
            time: '6:30PM'
        },
        {
            residentName: 'Amelia Ava William',
            residentPicture: Resident2,
            caregiverName: 'Josh Stewart',
            activityType: 'sanitary',
            description: 'Clean teeth and dentures',
            time: '7:00AM'
        },
        {
            residentName: 'Josephine Grace Turing',
            residentPicture: Resident3,
            caregiverName: 'Willow-Jean Baker',
            activityType: 'medical',
            description: 'Read vitals and switch IV',
            time: '3:00PM'
        },
        {
            residentName: 'Olga Palyuchenko',
            residentPicture: Resident4,
            caregiverName: 'Enahoro Joseph Ibe',
            activityType: 'physical',
            description: 'Practice using new walker',
            time: '6:00PM'
        },
        {
            residentName: 'Olga Palyuchenko',
            residentPicture: Resident4,
            caregiverName: 'Enahoro Joseph Ibe',
            activityType: 'physical',
            description: 'Practice using new walker',
            time: '6:00PM'
        },
    ]

    const caregiversOnCall = [
        {
            name: 'Grace Majors',
            avatar: Caregiver1,
            department: 'Administration',
            shiftTime: '11:00PM - 7:00AM'
        },
        {
            name: 'Mabel Oluchi Okonkwo',
            avatar: Caregiver2,
            department: 'Nursing/Med. Care',
            shiftTime: '11:00PM - 7:00AM'
        },
        {
            name: 'Samson Oko-plus',
            avatar: Caregiver3,
            department: 'Dietary/Nutrition',
            shiftTime: '11:00PM - 7:00AM'
        },
        {
            name: 'Bode Akinwumi George',
            avatar: Caregiver4,
            department: 'Housekeeping/Maintenance',
            shiftTime: '11:00PM - 7:00AM'
        },
        {
            name: 'Cynthia Viniski',
            avatar: Caregiver5,
            department: 'Activities/Recreation',
            shiftTime: '11:00PM - 7:00AM'
        }
    ]

    const visitorsToday = [
        {
            name: 'Saronov Stephanovic',
            timeIn: '10:00AM',
            timeOut: '12:23PM',
            resident: {
                name: 'Olga Palyuchenko',
                picture: Resident4
            },
            status: "completed"
        },
        {
            name: 'Eucharia Eket',
            timeIn: '11:00AM',
            timeOut: '12:00PM',
            resident: {
                name: 'Olga Palyuchenko',
                picture: Resident4
            },
            status: "completed"
        },
        {
            name: 'Philia Morgan-Uchendu',
            timeIn: '11:00AM',
            timeOut: '12:00PM',
            resident: {
                name: 'Josephine Grace Turing',
                picture: Resident2
            },
            status: "in_progress"
        },
        // {
        //     name: 'Philia Morgan-Uchendu',
        //     timeIn: '11:00AM',
        //     timeOut: '12:00PM',
        //     resident: {
        //         name: 'Josephine Grace Turing',
        //         picture: Resident2
        //     },
        //     status: "in_progress"
        // },
        // {
        //     name: 'Philia Morgan-Uchendu',
        //     timeIn: '11:00AM',
        //     timeOut: '12:00PM',
        //     resident: {
        //         name: 'Josephine Grace Turing',
        //         picture: Resident2
        //     },
        //     status: "in_progress"
        // },
    ]

    const inventory = {
        lowStock: 12,
        requests: [
            {
                item: "Packs of toilet paper",
                quantity: 25,
                orderedBy: "Jane Austen",
                orderDate: "21/09/2024",
            },
            {
                item: "Packs of toilet paper",
                quantity: 25,
                orderedBy: "Jane Austen",
                orderDate: "21/09/2024",
            },
            {
                item: "Packs of toilet paper",
                quantity: 25,
                orderedBy: "Jane Austen",
                orderDate: "21/09/2024",
            },
        ]
    }

    const tasks = [
        {
            task: "Lorem ipsum dolor sit amet",
            dueDate: "12/10/2024",
            assignee: {
               name: 'Grace Majors',
                avatar: Caregiver1,
            }
        },
        {
            task: "Sed ut perspiciatis unde omnis",
            dueDate: "09/10/2024",
            assignee: {
                name: 'Mabel Oluchi Okonkwo',
                avatar: Caregiver2,
            }
        },
        {
            task: "Lorem ipsum dolor sit amet",
            dueDate: "29/09/2024",
            overdue: true,
            assignee: {
                name: 'Cynthia Viniski',
                avatar: Caregiver5,
            }
        },
    ]

    const barChartData = [
        {
          subject: 'Jan',
          percentage: 45,
        },
        {
          subject: 'Feb',
          percentage: 71
        },
        {
          subject: 'Mar',
          percentage: 58
        },
        {
          subject: 'Apr',
          percentage: 22
        },
        {
          subject: 'May',
          percentage: 85
        },
        {
          subject: 'Jun',
          percentage: 79
        },
        {
          subject: 'Jul',
          percentage: 27
        },
        {
          subject: 'Aug',
          percentage: 85
        },
        {
          subject: 'Sep',
          percentage: 40
        },
        {
          subject: 'Oct',
          percentage: 20
        },
    ]

    const user = userDetails()

    return (
        <div>
            <Layout pageTitle="Overview">
                <div className='w-full flex items-center justify-between mb-[50px]'>
                    <div>
                        <h3 className='font-[400] text-[32px] leading-[40px] font-space-grotesk text-doiteasy-black'>Welcome, {user.name.split(' ')[0]}!</h3>
                    </div>
                    {/* <button className='flex items-center justify-center gap-x-[16px] rounded-[20px] font-space-grotesk bg-white px-[24px] py-[16px] '>
                        Filters
                        <div className='w-[30px] h-[30px] rounded-full bg-[#F0F0F080] flex items-center justify-center'>
                            <img src={SlidersIcon} className='w-[16px] h-[16px]' alt='' />
                        </div>
                    </button> */}
                    <FiltersV2 />
                </div>
                {/* <img src='../../assets/images/icons/inventory-in-icon.svg' className='w-[500px] h-[500px]' alt='' /> */}
                <div className='w-full flex items-stretch justify-between gap-x-[24px] mb-[24px]'>
                    <div className='w-full grid grid-cols-2 gap-[24px]'>
                        <div className='w-full bg-white rounded-[10px] p-[16px]'>
                            <div className='flex items-center gap-x-[5px]'>
                                <img src={UserIcon} className='w-[22px] h-[22px]' alt='' />
                                <p className='text-doiteasy-black text-[20px] leading-[24px] font-urbanist font-[500]'>Total Residents</p>
                            </div>
                            <h1 className='font-[700] leading-[40px] text-[32px] font-space-grotesk mt-[25px] mb-[30px]'>32</h1>
                            <div className='flex items-center gap-x-[5px]'>
                                <ArrowIcon className={`text-doiteasy-black w-4 h-4 -rotate-90`} />
                                <p className='text-doiteasy-black text-[14px] font-[400] leading-[24px] font-urbanist'><span className='font-[550] font-space-grotesk'>0 </span>Resident(s) this month</p>
                            </div>
                        </div>
                        <div className='w-full bg-white rounded-[10px] p-[16px]'>
                            <div className='flex items-center gap-x-[5px]'>
                                <img src={UsersIcon} className='w-[22px] h-[22px]' alt='' />
                                <p className='text-doiteasy-black text-[20px] leading-[24px] font-urbanist font-[500]'>Visitors</p>
                            </div>
                            <h1 className='font-[700] leading-[40px] text-[32px] font-space-grotesk mt-[25px] mb-[30px]'>31</h1>
                            <div className='flex items-center gap-x-[5px]'>
                                <ArrowIcon className={`text-doiteasy-black w-4 h-4 -rotate-90`} />
                                <p className='text-doiteasy-black text-[14px] font-[400] leading-[24px] font-urbanist'><span className='font-[550] font-space-grotesk'>12 </span>Visitors this month</p>
                            </div>
                        </div>
                        <div className='w-full bg-white rounded-[10px] p-[16px]'>
                            <div className='flex items-center gap-x-[5px]'>
                                <img src={ClipboardIcon} className='w-[22px] h-[22px]' alt='' />
                                <p className='text-doiteasy-black text-[20px] leading-[24px] font-urbanist font-[500]'>Upcoming Appointments</p>
                            </div>
                            <h1 className='font-[700] leading-[40px] text-[32px] font-space-grotesk mt-[25px] mb-[30px]'>22</h1>
                            <div className='flex items-center gap-x-[5px]'>
                                <ArrowIcon className={`text-doiteasy-black w-4 h-4 -rotate-90`} />
                                <p className='text-doiteasy-black text-[14px] font-[400] leading-[24px] font-urbanist'><span className='font-[550] font-space-grotesk'>7 </span>Completed this month</p>
                            </div>
                        </div>
                        <div className='w-full bg-white rounded-[10px] p-[16px]'>
                            {/* <div className='flex items-center gap-x-[5px]'>
                                <img src={BuildingsIcon} className='w-[22px] h-[22px]' alt='' />
                                <p className='text-doiteasy-black text-[20px] leading-[24px] font-urbanist font-[500]'>Facilities</p>
                            </div>
                            <h1 className='font-[700] leading-[40px] text-[32px] font-space-grotesk mt-[25px] mb-[30px]'>76</h1>
                            <div className='flex items-center gap-x-[5px]'>
                                <ArrowIcon className={`text-doiteasy-black w-4 h-4 -rotate-90`} />
                                <p className='text-doiteasy-black text-[14px] font-[400] leading-[24px] font-urbanist'><span className='font-[550] font-space-grotesk'>8 </span>New facilities</p>
                            </div> */}
                        </div>
                    </div>
                    <div className='w-full'>
                        <div className='w-full rounded-[10px] bg-white p-[16px] pb-[40px]'>
                            <p className='text-doiteasy-black text-[18px] font-[500] leading-[24px] font-space-grotesk mb-[24px]'>Overview</p>
                            <div className='w-full flex items-end justify-between h-[300px] relative px-[20px] pb-[10px]'>
                            {barChartData.map((subject, subjectIndex)=>(
                                <div key={subjectIndex} onClick={()=>{}} className={`relative cursor-pointer transition text-center duration-200 w-[30px] rounded bg-doiteasy-blue`} style={{height: subject.percentage + '%'}}>
                                    <p className='absolute -bottom-[30px] text-[12px] text-gray-500 font-medium ml-[5px]'>{subject.subject}</p>
                                </div>
                            ))}
                            </div>
                        </div>
                    </div>
                </div>

                <div className='w-full flex items-stretch justify-between gap-x-[24px] mb-[24px]'>
                    <div className='w-full bg-white rounded-[20px] p-[16px] pb-[40px] relative'>
                        <p className='text-doiteasy-black text-[18px] font-[500] leading-[24px] font-space-grotesk mb-[24px]'>Upcoming Resident Activities</p>
                        
                        {residentActivities.map((activity, activityIndex) => (
                            <div className='w-full my-[12px]' key={activityIndex}>
                                <ResidentActivityCard activity={activity} />
                            </div>
                        ))}

                        <button className='absolute bottom-[20px] left-[16px] flex items-center gap-x-[5px] text-[14px] text-doiteasy-blue'>
                            See all activities
                            <ArrowIcon className={`w-5 h-5`} />
                        </button>
                    </div>
                    <div className='w-full bg-white rounded-[20px] p-[16px] pb-[40px] relative'>
                        <p className='text-doiteasy-black text-[18px] font-[500] leading-[24px] font-space-grotesk mb-[24px]'>Staff on call</p>
                        
                        {caregiversOnCall.map((caregiver, caregiverIndex) => (
                            <div className='w-full my-[12px]' key={caregiverIndex}>
                                <CaregiverOnCallCard caregiver={caregiver} />
                            </div>
                        ))}
                        <button className='absolute bottom-[20px] left-[16px] flex items-center gap-x-[5px] text-[14px] text-doiteasy-blue'>
                            See all staff
                            <ArrowIcon className={`w-5 h-5`} />
                        </button>
                    </div>
                    <div className='w-full bg-white rounded-[20px] p-[16px] pb-[40px] relative'>
                        <p className='text-doiteasy-black text-[18px] font-[500] leading-[24px] font-space-grotesk mb-[24px]'>Visitors today</p>
                        
                        {visitorsToday.map((visitor, visitorIndex) => (
                            <div className='w-full my-[12px]' key={visitorIndex}>
                                <VisitorCard visitor={visitor} />
                            </div>
                        ))}

                        <button className='absolute bottom-[20px] left-[16px] flex items-center gap-x-[5px] text-[14px] text-doiteasy-blue'>
                            See all visits
                            <ArrowIcon className={`w-5 h-5`} />
                        </button>
                    </div>
                </div>

                <div className='w-full flex items-stretch justify-between gap-x-[24px]'>
                    <div className='w-1/3 bg-white rounded-[20px] p-[16px] pb-[40px] relative'>
                        <p className='text-doiteasy-black text-[18px] font-[500] leading-[24px] font-space-grotesk mb-[24px]'>Inventory</p>

                        <h1 className='font-[700] leading-[40px] text-[32px] font-space-grotesk mt-[25px] mb-[5px]'>{inventory.lowStock}</h1>
                        <p className='font-urbanist text-[14px] text-doiteasy-black font-medium leading-[18px]'>Low stock items</p>
                        <button className='mt-[5px] flex items-center gap-x-[5px] text-[14px] text-doiteasy-black'>
                            See all low stock items
                            <ArrowIcon className={`w-5 h-5`} />
                        </button>
                        <div className='w-full border-t border-gray-300 my-[15px]' />
                        
                        {inventory.requests.map((item, itemIndex) => (
                            <div className='w-full my-[12px] flex items-start justify-between gap-x-[12px]' key={itemIndex}>
                                <div className='w-[40px]'>
                                    <img alt='' src={InventoryInGrayscale} className='w-[30px]' />
                                </div>
                                <div>
                                    <h3 className='font-[500] font-space-grotesk text-doiteasy-black text-[15px] leading-[18px] mb-[5px]'>{item?.item}</h3>
                                    <p className='font-urbanist text-[14px] text-doiteasy-black font-medium leading-[18px]'>x{item?.quantity} requested by {item.orderedBy} on {item.orderDate}</p>
                                </div>
                                <div className='w-[100px] flex items-center flex-row-reverse gap-x-[10px]'>
                                    <button className='rounded-full border border-doiteasy-blue bg-white text-doiteasy-blue'>
                                        <CloseIcon className={`w-[20px] h-[20px]`} />
                                    </button>
                                    <button className='rounded-full border border-doiteasy-blue bg-doiteasy-blue text-white'>
                                        <CheckIcon className={`w-[20px] h-[20px]`} />
                                    </button>
                                </div>
                            </div>
                        ))}

                        <button className='absolute bottom-[20px] left-[16px] flex items-center gap-x-[5px] text-[14px] text-doiteasy-blue'>
                            See all inventory requests
                            <ArrowIcon className={`w-5 h-5`} />
                        </button>
                    </div>
                    
                    <div className='w-2/3 bg-doiteasy-blue rounded-[20px] p-[16px] pb-[40px] relative'>
                        <p className='text-doiteasy-light-gray text-[18px] font-[500] leading-[24px] font-space-grotesk mb-[24px]'>Appointments & tasks overview</p>
                        
                        <div className='flex items-start justify-between gap-x-[24px] relative'>
                            <div className='w-full'>
                                <Calendar textColor={`#fff`} />
                            </div>
                            <div className='w-full relative'>

                                {tasks.map((task, taskIndex) => (
                                    <div className='w-full my-[12px]' key={taskIndex}>
                                        <TaskCard task={task} />
                                    </div>
                                ))}

                            </div>

                            <button className='absolute -bottom-[20px] left-[16px] flex items-center gap-x-[5px] text-[14px] text-doiteasy-light-gray'>
                                See all tasks
                                <ArrowIcon className={`w-5 h-5`} />
                            </button>

                        </div>

                    </div>
                </div>
            </Layout>
        </div>
    )
}

export default Overview

import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import SecondaryButton from '../../elements/SecondaryButton';
import FormButton from '../../elements/form/FormButton';
import { fetchRooms } from '../../../store/actions/roomActions';
import RadioGroup from '../../elements/form/RadioGroup';
import AutocompleteSelect from '../../elements/form/AutocompleteSelect';
import { ERROR } from '../../../store/types';
import { checkInResident } from '../../../store/actions/residentsActions';
import { careTypes } from '../../../utils';

const ResidentCheckIn = ({resident, close}) => {
    const dispatch = useDispatch()
    const roomsSelector = useSelector(state => state.rooms)
    const residentsSelector = useSelector(state => state.residents)

    useEffect(() => {
        dispatch(fetchRooms('inUse=false&type=living-quarters', 0, 0))
        return () => {
            
        };
    }, [dispatch]);

    const [checkInPayload, setCheckInPayload] = useState({});
    const [validationErrors, setValidationErrors] = useState({});

    const validateForm = () => {
        let errors = {}

        if(!checkInPayload.room || checkInPayload.room === '') {
            errors.name = true
        }
       
        setValidationErrors(errors)

        return errors
    }

    const checkIn = () => {

        if (Object.values(validateForm()).includes(true)) {
            dispatch({
                type: ERROR,
                error: {response: {data: {
                    message: 'Please check the highlighted fields'
                }}}
            })
            return
        }

        const payload = {...checkInPayload}
        dispatch(checkInResident(resident._id, payload))
    }

    return (
        <div>
            <p className='text-sm font-[500] text-gray-500'>Select a room for the resident and the required care type to check them in</p>

            <div className='w-full'>
                <RadioGroup
                    items={careTypes}
                    inputLabel="Gender"
                    titleField="label"
                    displayImage={false}
                    imageField=""
                    preSelectedIndex={careTypes.findIndex(item => item.value === resident?.careTypeRequired)}
                    // preSelectedIndex={null}
                    fieldId={`title`}
                    inline={true}
                    hasError={false}
                    showDescriptions={true}
                    returnSelected={(value) => {setCheckInPayload({...checkInPayload, ...{careTypeRequired: value.value}})}}
                />
            </div>

            {!roomsSelector.loadingRooms && 
            <>
                {roomsSelector?.rooms?.rooms?.length > 0 ? <div className='w-full mt-4'>
                    <AutocompleteSelect
                        selectOptions = {roomsSelector?.rooms?.rooms}
                        requiredField={true}
                        inputLabel = "Select a room for this resident"
                        titleField = "name"
                        displayImage = {true}
                        inputPlaceholder={`Select room`}
                        imageField = "image"
                        fieldId = "citizenship"
                        preSelected={checkInPayload.room && checkInPayload.room !== '' ? checkInPayload.room : ''}
                        preSelectedLabel='name'
                        hasError = {validationErrors && validationErrors.nationality}
                        returnFieldValue={(value)=>{setCheckInPayload({...checkInPayload, ...{
                            room: value._id
                        }})}}
                        disabled={false}
                    />
                </div> :
                    <div className='w-full p-[10px] rounded border border-red-300 bg-red-50 mt-4'>
                        <p className='text-xs text-red-600 font-[]'>No rooms found. Please create some rooms in your care home settings first.</p>
                    </div>
                }
            </>
            }

            <div className='w-full flex mx-auto items-center justify between gap-x-[10px] mt-[40px]'>
                <div className='w-max'>
                    <SecondaryButton 
                        buttonLabel={`Cancel`} 
                        buttonAction={()=>{close()}} 
                        processing={false} 
                    />
                </div>
                <FormButton 
                    buttonLabel={`Check-in Resident`} 
                    buttonAction={()=>{checkIn()}} 
                    processing={residentsSelector.checkingInResident} 
                />
            </div>
        </div>
    )
}

export default ResidentCheckIn
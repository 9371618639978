import React from 'react'
import { NavLink, useLocation } from 'react-router-dom'

const StaffLayout = ({children}) => {
    const location = useLocation();
    const currentRoute = location.pathname;

    return (
        <div>
            <div className='h-[50px] w-full flex items-center'>
                <NavLink to="/admin/care-home/staff/care-home-staff"  className={`text-sm nav-button px-3 py-3 font-space-grotesk font-[550] ${currentRoute.includes('admin/care-home/staff/care-home-staff') ? 'border-b-2 border-doiteasy-black text-doiteasy-black' : 'border-b border-gray-300 text-gray-600'}`}>Staff</NavLink>
                
                <NavLink to="/admin/care-home/staff/attendance"  className={`text-sm nav-button px-3 py-3 font-space-grotesk font-[550] ${currentRoute.includes('admin/care-home/staff/attendance') ? 'border-b-2 border-doiteasy-black text-doiteasy-black' : 'border-b border-gray-300 text-gray-600'}`}>Staff Attendance</NavLink>
                
                <NavLink to="/admin/care-home/staff/schedules"  className={`text-sm nav-button px-3 py-3 font-space-grotesk font-[550] ${currentRoute.includes('admin/care-home/staff/schedules') ? 'border-b-2 border-doiteasy-black text-doiteasy-black' : 'border-b border-gray-300 text-gray-600'}`}>Schedules</NavLink>
            </div>
            <div className={`mt-[20px] min-h-screen`}>
                <main>{children}</main>
            </div>
        </div>
    )
}

export default StaffLayout
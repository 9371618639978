import React from 'react'
import { unSlugify } from '../../../utils'

const DrugClassPill = ({type}) => {
    // const drugClassColors = {
    //     narcoti
    // }
    return (
      <span className={`rounded-[6px] py-[6px] px-[8px] capitalize text-xs font-[550] 
          ${type==='narcotic' && 'bg-red-100 bg-opacity-30 text-red-600'} 
          ${type!=='narcotic' && 'bg-gray-100 text-gray-600'} 
      `}>{unSlugify(type)}</span>
    )
  }

export default DrugClassPill
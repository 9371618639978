import React, { useEffect, useState } from 'react'
// import Layout from '../../../../components/layouts/Layout'
import FiltersV2 from '../../../../components/elements/FiltersV2'
import PlusWhite from '../../../../assets/images/icons/plus.svg';
import PrimaryButton from '../../../../components/elements/PrimaryButton';
import EmptyState from '../../../../components/elements/EmptyState';
import { Link } from 'react-router-dom';
import SearchField from '../../../../components/elements/SearchField';
import { useDispatch, useSelector } from 'react-redux';
import DataTable from '../../../../components/elements/DataTable';
import { tableHeadersFields, transactionTimeStamp, userDetails } from '../../../../utils';
import StaffTableSnippet from '../../../../components/elements/staff/StaffTableSnippet';
import { clearResentInvitation, clearRevokedInvitation, fetchStaff, resendStaffInvitation, revokeStaffInvitation } from '../../../../store/actions/staffActions';
import Preloader from '../../../../components/elements/Preloader';
import TrashIcon from '../../../../components/elements/icons/TrashIcon';
import ArrowPathIcon from '../../../../components/elements/icons/ArrowPathIcon';
import { SET_SUCCESS } from '../../../../store/types';

const Staff = () => {
  const dispatch = useDispatch()
  const staffSelector = useSelector(state => state.staff)
  const [pagination, setPagination] = useState({
    page: 1,
    perPage: 25
  });
  // eslint-disable-next-line no-unused-vars
  const [filter, setFilter] = useState('');

  useEffect(() => {
    dispatch(fetchStaff(filter, pagination.page, pagination.perPage))
    if(staffSelector.resentInvitation !== null) {
      dispatch(clearResentInvitation())
      dispatch({
        type: SET_SUCCESS,
        payload: 'Staff invitation resent successfully'
      })
    }
    if(staffSelector.revokedInvitation !== null) {
      dispatch(clearRevokedInvitation())
      dispatch({
        type: SET_SUCCESS,
        payload: 'Staff invitation revoked successfully'
      })
    }
    return () => {
      
    };
  }, [dispatch, filter, pagination.page, pagination.perPage, staffSelector.resentInvitation, staffSelector.revokedInvitation]);

  const tableOptions = {
    selectable: false,
    multiselect: false,
    clickableRows: true,
    rowAction: (rowIndex)=>{
      // navigate(applicationsSelector.applications.applications[rowIndex]._id)
    }
  }

  const columnDataStyles = {}
  const teamColumnWidths = {
    teamMember: 'w-4/12',
    // invitedBy: 'w-4/12',
    "designation & department": 'w-2/12',
    invitedBy: 'w-2/12',
    dateJoined: 'w-2/12',
    dateInvited: 'w-2/12',
  }

  const user = userDetails()

  const cleanUpTeamTableData = (array) => {
    if(!array) return
    let finalResult = [] 
    array.forEach((object, objectIndex) => {
        const applicationObject = {
          teamMember: <StaffTableSnippet email={object.email} phone={object.phone} gender={object.gender} name={`${object.firstName}${object.otherNames ? object.otherNames : ' '}${object.lastName} ${user.email === object.email ? '(You)' : ''}`} />,
          "designation & department": <p className='text-sm font-[500] text-gray-500 mt-[5px] capitalize'>{object.designation}<br/><span className='text-sm font-[550] text-gray-700 mt-[5px] capitalize'>{object?.department?.name}</span></p>,
          invitedBy: object.createdBy?.name,
          dateInvited: <div className=''>
            <p className='font-[500] text-gray-600'>{transactionTimeStamp(object.createdAt).date}</p>
            <p className='text-sm font-[400] text-gray-500 mt-[5px]'>{transactionTimeStamp(object.createdAt).time}</p>
          </div>,
          dateJoined: object.userProfile ? <div className=''>
            <p className='font-[500] text-gray-600'>{transactionTimeStamp(object.userProfile.createdAt).date}</p>
            <p className='text-sm font-[400] text-gray-500 mt-[5px]'>{transactionTimeStamp(object.userProfile.createdAt).time}</p>
          </div> : 
          <div className='flex flex-row-reverse gap-x-[10px] items-center w-full'>
            <button onClick={()=>{dispatch(revokeStaffInvitation(object.invitation?._id))}} className='h-[30px] px-[7px] flex items-center justify-center gap-x-[5px] rounded text-gray-600 hover:bg-gray-200 hover:text-red-500 transition duration-200 text-sm'>
              <TrashIcon className={`w-4 h-4`} />
              {staffSelector.revokingInvitation === object?.invitation?._id ? 'Revoking...' : 'Revoke'}
            </button>
            <button onClick={()=>{dispatch(resendStaffInvitation(object?.invitation?._id))}} className='h-[30px] px-[7px] flex items-center justify-center gap-x-[5px] rounded text-gray-600 hover:bg-gray-200 hover:text-gray-700 transition duration-200 text-sm'>
              <ArrowPathIcon className={`w-4 h-4 ${staffSelector.resendingInvitation === object?.invitation?._id && 'animate-spin'}`} />
              {staffSelector.resendingInvitation === object?.invitation?._id ? 'Resending...' : 'Resend'}
            </button>
          </div>
        }

        finalResult.push(applicationObject)
    })
    return finalResult
  }



  return (
    <div className='w-full'>
      <div className='w-1/2'>
        <h3 className='font-[550] mb-[5px]'>Care Home Staff</h3>
        <p className='text-sm font-[500] text-gray-600'>A record of all staff working in your care home. You can add new staff at any time by clicking on "Enrol Staff"</p>
      </div>
      <div className='flex items-center justify-between'>
        <div className='w-1/3'>
          <SearchField placeholderText={`Search for staff`} />
        </div>
        <div className='w-1/2 flex flex-row-reverse gap-x-[10px]'>
          <Link to={`enrol-staff`}>
            <PrimaryButton label={`Enrol Staff`} icon={PlusWhite} />
          </Link>
          <FiltersV2 />
        </div>
      </div>

      <div className='bg-white p-[20px] mt-[20px] rounded-[20px]'>
      {staffSelector?.loadingStaff ? 
        <Preloader preloadingText={`Loading residents`} />
        :
        <>
          <div className='w-full'>
          {staffSelector?.staff?.staff?.length > 0 ?
            <div className='w-full'>
              <div className='w-full mt-[20px]'>
                <DataTable
                  tableHeaders={tableHeadersFields(cleanUpTeamTableData(staffSelector?.staff?.staff)[0]).headers} 
                  tableData={cleanUpTeamTableData(staffSelector?.staff?.staff)} 
                  columnWidths={teamColumnWidths}
                  columnDataStyles={columnDataStyles}
                  allFields={tableHeadersFields(cleanUpTeamTableData(staffSelector?.staff?.staff)[0]).fields}
                  onSelectItems={()=>{}}
                  tableOptions={tableOptions}
                  pagination={{
                      perPage: pagination.perPage, 
                      page: pagination.page,
                      totalItems: staffSelector.staff.total,
                  }}
                  onSelectSingle={()=>{}}
                  changePage={(page)=> {setPagination({...pagination, ...{page: page}})}}
                  updatePerPage={(perPage)=> {setPagination({...pagination, ...{perPage: perPage}})}}
                />
              </div>
            </div>
            :
            <div className='w-full'>
              <div className='mt-[50px] w-[500px] mx-auto'>
                <EmptyState  
                  emptyStateTitle={`You do not have any staff in your Care Home yet.`}
                  emptyStateText={`You can invite new staff to your Doiteasy account by clicking on the "Enrol Staff" button above.`}
                />
              </div>
            </div>
          }
          </div>
        </>
      }
        </div>
      </div>
      
    // </div>

  )
}

export default Staff
import React, { useState } from 'react'
import FormButton from '../form/FormButton'
import SecondaryButton from '../SecondaryButton'
import TextField from '../form/TextField'
import { useDispatch, useSelector } from 'react-redux'
import { CREATING_ROOM, ERROR } from '../../../store/types'
import TextareaField from '../form/TextareaField'
import RadioGroup from '../form/RadioGroup'
import FileUpload from '../form/FileUpload'
import { authHeader } from '../../../utils'
// import PlusIcon from '../icons/PlusIcon'
// import TrashIcon from '../icons/TrashIcon'
import { createRoom } from '../../../store/actions/roomActions'

const NewRoom = ({close}) => {
    const dispatch = useDispatch()
    const roomsSelector = useSelector(state => state.rooms)
    const [roomPayload, setRoomPayload] = useState({});
    const [validationErrors, setValidationErrors] = useState({});

    const validateForm = () => {
        let errors = {}
        if(!roomPayload.name || roomPayload.name === '') {
            errors.name = true
        }
        
        if(!roomPayload.type || roomPayload.type === '') {
            errors.type = true
        }
        
        if(!roomPayload.description || roomPayload.description === '') {
            errors.description = true
        }
        // if(facilities.length > 1) {
        //     facilities.forEach((facility, facilityIndex) => {
        //         if(!facility || facility === '') {
        //             errors[`facility-${facilityIndex}`] = true
        //         }
        //     })
        // }
       
        setValidationErrors(errors)

        return errors
    }

    // const [facilities, setFacilities] = useState(['']);
    // const addFacility = () => {
    //     let temp = [...facilities]
    //     temp.push('')
    //     setFacilities(temp)
    //   }
    
    //   const updateFacility = (index, value) => {
    //     let temp = [...facilities]
    //     temp[index] = value
    //     setFacilities(temp)
    //   }
    
    //   const removeFacility = (index) => {
    //     let temp = [...facilities]
    //     temp.splice(index, 1)
    //     setFacilities(temp)
    //   }
    

    const pushRoom = async () => {
        if (Object.values(validateForm()).includes(true)) {
            dispatch({
                type: ERROR,
                error: {response: {data: {
                    message: 'Please check the highlighted fields'
                }}}
            })
            return
        }

        const payload = {...roomPayload}
        // if(facilities.length > 0 && facilities[0] !== ''){
        //     payload.facilities = facilities
        // }

        if(file) {
            const uploaded = await handleUpload(file) 
            payload.picture = uploaded.data.file
        }

        dispatch(createRoom(payload))
    }

    const roomTypes = [
        {
          label: 'Office',
          value: 'office',
        },
        {
          label: 'Living Quarters',
          value: 'living-quarters',
        },
        {
          label: 'Storage',
          value: 'storage',
        },
        {
          label: 'Common Area',
          value: 'common-area',
        },
    ]

    const [file, setFile] = useState(null);

    const handleUpload = async (file) => {
        console.log(file)
  
        var formData = new FormData()
        formData.append('file', file.file )
        const headers = new Headers();
        headers.append("Authorization",  authHeader().Authorization);

  
        try {
            dispatch({
                type: CREATING_ROOM,
                payload: true
            })
            const doUpload = await fetch(`${process.env.REACT_APP_BASE_URL}/files/new`, {
                method: "POST",
                headers,
                body: formData,
            });
            const response = await doUpload.json();
    
           return response
        } catch (error) {
            dispatch({
                type: ERROR,
                error,
            });
        }
    }

    return (
        <div className='w-full'>
            <p className="text-sm mt-[12px] text-gray-600">Please provide details of the room below to create a new room</p>

            <div className='w-full flex items-start gap-x-[20px]'>
                <div className='w-full'>
                    <div className='mt-4'>
                        <TextField
                            inputLabel="Room name" 
                            fieldId="room-name" 
                            inputType="text" 
                            preloadValue={''}
                            inputPlaceholder={'What will you call the room'}
                            hasError={validationErrors && validationErrors.name} 
                            returnFieldValue={(value)=>{setRoomPayload({...roomPayload, ...{name: value}})}}
                        />
                    </div>
                    <div className='mt-4 w-full'>
                        <RadioGroup
                            items={roomTypes}
                            inputLabel="Room type"
                            titleField="label"
                            displayImage={false}
                            imageField=""
                            preSelectedIndex={roomTypes.findIndex(item => item.value === roomPayload.type)}
                            // preSelectedIndex={null}
                            fieldId={`title`}
                            inline={false}
                            hasError={validationErrors && validationErrors[`gender`]}
                            showDescriptions={true}
                            returnSelected={(value) => {setRoomPayload({...roomPayload, ...{type: value.value}})}}
                        />
                    </div>
                    <div className='mt-4'>
                        <TextareaField
                            inputLabel="Description" 
                            fieldId="room-description" 
                            inputType="text" 
                            preloadValue={''}
                            inputPlaceholder={'Describe the room or its intended use'}
                            hasError={validationErrors && validationErrors.description} 
                            returnFieldValue={(value)=>{setRoomPayload({...roomPayload, ...{description: value}})}}
                        />
                    </div>
                </div>
                <div className='w-full'>
                    <div className='mt-4 w-full'>
                        <FileUpload
                            hasError={validationErrors.file}
                            fieldLabel={`Room Picture`}
                            returnFileDetails={(details)=>{
                                setFile(details)
                            }}
                            acceptedFormats={['jpeg', 'jpg', 'png']}
                        />
                        <label className='block mt-2 text-xs text-gray-400'>{`Add a picture of the room if needed`}</label>
                    </div>

                    {/* <h3 className="text-sm text-doiteasy-black mt-4 font-[550]">Facilities/Features</h3>
                    <p className="text-xs font-[500] text-gray-500 mt-[5px]">You can add as many facilities or features available in the room as needed by clicking on the "add more" button</p>
                      {facilities.map((facility, facilityIndex) => (<div key={facilityIndex} className='w-full mt-2 relative'>
                        {facilityIndex > 0 &&
                            <button onClick={()=>{removeFacility(facilityIndex)}} className='absolute -top-[5px] right-0 z-20 p-[7px] text-gray-400 hover:bg-gray-100 hover:text-red-700 rounded transition duration-200'>
                                <TrashIcon className={`w-5 h-5`} />
                            </button>}
                        <TextField
                          inputLabel="Facility/Feature" 
                          fieldId="facility-name" 
                          inputType="text" 
                          preloadValue={facility || ''}
                          inputPlaceholder={`Facility or feature name`}
                          hasError={validationErrors[`facility-${facility}`]}
                          returnFieldValue={(value)=>{updateFacility(facilityIndex, value)}}
                        />
                      </div>))}
                    
                      <button onClick={()=>{addFacility()}} className='flex items-center justify-center w-max rounded-[8px] px-[16px] py-[10px] mt-4 text-sm bg-doiteasy-black text-doiteasy-light-gray'>
                        <PlusIcon className={`w-4 h-4`}/>
                        Add more
                      </button> */}
                </div>
            </div>
            <div className='w-4/12 flex ml-auto items-center justify between gap-x-[10px] mt-[40px]'>
                <div className='w-max'>
                    <SecondaryButton 
                        buttonLabel={`Cancel`} 
                        buttonAction={()=>{close()}} 
                        processing={false} 
                    />
                </div>
                <FormButton 
                    buttonLabel={`Create Room`} 
                    buttonAction={()=>{pushRoom()}} 
                    processing={roomsSelector.creatingRoom} 
                />
            </div>
        </div>
    )
}

export default NewRoom
import React from 'react'
import { transactionTimeStamp } from '../../../utils'
import Caregiver1 from '../../../assets/images/caregivers/caregiver1.webp'
import ThumbtackIcon from '../icons/ThumbtackIcon'
import { useDispatch } from 'react-redux'
import { updateNote } from '../../../store/actions/notesActions'
import TrashIcon from '../icons/TrashIcon'

const ResidentNote = ({note, openNote, resident, reload, close, state="excerpt"}) => {
    // note state can be "excerpt" or "full"
    const dispatch = useDispatch()
    const togglePin = () => {
        dispatch(updateNote(note._id, {pinned: !note.pinned}))
    }

    return (
        <>
            <div className={`w-full bg-opacity-20  rounded-[8px] min-h-[300px] relative ${state==='excerpt' ? 'bg-doiteasy-light-gray px-[15px] py-[10px] border border-gray-100' : 'bg-transparent'}`}>
                {<div className='flex flex-row-reverse mb-[10px] gap-x-[5px]'>
                    <button onClick={()=>{}} className='text-xs text-gray-600 rounded hover:bg-gray-200 transition duration-200 mt-[10px] bg-doiteasy-light-gray px-[6px] py-[6px] font-[550] flex items-center justify-center gap-x-0'>
                        <TrashIcon className={`w-4 h-4`} />
                    </button>
                    <button onClick={()=>{togglePin()}} className='text-xs text-gray-600 rounded hover:bg-gray-200 transition duration-200 mt-[10px] bg-doiteasy-light-gray px-[6px] py-[6px] font-[550] flex items-center justify-center gap-x-0'>
                        <ThumbtackIcon className={`w-6 h-6 ${note.pinned && 'rotate-45'}`} /> {note.pinned ? 'Unpin' : 'Pin'}
                    </button>
                </div>}

                {state === 'excerpt' && <p className='text-sm text-gray-600 font-[500]'>{note.note.substr(0, 200)}{note.note.length > 200 && '...'}</p>}
                {state === 'full' && <p className='text-sm text-gray-600'>{note.note}</p>}

                {state === 'excerpt' && <button onClick={()=>{openNote()}} className='mt-[15px] text-xs pb-[5px] border-b border-gray-500 font-[500]'>Read note</button>}
            
                <div className={`${state === 'excerpt' ? 'absolute bottom-[10px] left-[15px]' : 'mt-[20px]'} w-full flex items-center gap-x-[5px]`}>
                    <div className='w-[50px] h-[50px] rounded-full flex items-center justify-center bg-doiteasy-light-gray'>
                        <div className='w-[40px] h-[40px] rounded-full bg-doiteasy-light-gray'
                            style={{
                                backgroundImage: `url(${Caregiver1})`, 
                                backgroundSize: 'cover',
                                backgroundRepeat: 'no-repeat',
                                backgroundPosition: 'center center'
                            }}
                        ></div>
                    </div>
                    <div className='w-full'>
                        <p className='text-sm text-doiteasy-black font-[500]'>
                        {note.createdBy.name}
                        </p>
                        <p className='font-[550] text-xs text-gray-500'>{transactionTimeStamp(note.createdAt).date} - {transactionTimeStamp(note.createdAt).time}</p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ResidentNote
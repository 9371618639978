import { CREATE_RESIDENT_REPORT, CREATING_RESIDENT_REPORT, GET_RESIDENT_REPORTS, GETTING_RESIDENT_REPORTS, RESIDENT_REPORTS_ERROR, UPDATE_RESIDENT_REPORT, UPDATING_RESIDENT_REPORT } from "../types"

const initialState = {
    residentReports: [],
    loadingResidentReports: true,
    residentReportsError: null,
    creatingResidentReport: false,
    createdResidentReport: null,
    updatingResidentReport: false,
    updatedResidentReport: null,
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function(state = initialState, action){

    switch(action.type){
        case GETTING_RESIDENT_REPORTS:
        return {
            ...state,
            loadingResidentReports :action.payload,
        }
        case GET_RESIDENT_REPORTS:
        return{
            ...state,
            loadingResidentReports:false,
            residentReportsError:  null,
            residentReports: action.payload,
        }
        case CREATING_RESIDENT_REPORT:
        return {
            ...state,
            creatingResidentReport :action.payload,
        }
        case CREATE_RESIDENT_REPORT:
        return{
            ...state,
            creatingResidentReport:false,
            residentReportsError:  null,
            createdResidentReport: action.payload,
        }
        case UPDATING_RESIDENT_REPORT:
        return {
            ...state,
            updatingResidentReport :action.payload,
        }
        case UPDATE_RESIDENT_REPORT:
        return{
            ...state,
            updatingResidentReports:false,
            residentReportsError:  null,
            updatedResidentReport: action.payload,
        }
        case RESIDENT_REPORTS_ERROR:
        return{
            ...state,
            loadingResidentReports:false,
            creatingResidentReport:false,
            updatingResidentReport:false,
            residentReportsError: action.payload 
        }
        default: return state
    }

}
import React from 'react'
import { transactionTimeStamp } from '../../../utils'
import SecondaryButton from '../../elements/SecondaryButton'

const MedicationAdministration = ({administration, close}) => {

    return (
        <div className='w-full'>
            <div className='w-full mb-4'>
                <label 
                    className={`text-sm lg:text-md cursor-text z-10 relative py-1 transition mb-1 block font-[500] duration-200 text-gray-500`}
                >
                    Administered by
                </label>
                <p className='text-sm font-[550] text-doiteasy-black'>{administration.caregiver.name}</p>
            </div>
            <div className='w-full mb-4'>
                <label 
                    className={`text-sm lg:text-md cursor-text z-10 relative py-1 transition mb-1 block font-[500] duration-200 text-gray-500`}
                >
                    Date & Time
                </label>
                <p className='text-sm font-[550] text-doiteasy-black'>{transactionTimeStamp(administration.date).date} - {transactionTimeStamp(administration.date).time}</p>
            </div>
            {administration.note && <div className='w-full mb-4'>
                <label 
                    className={`text-sm lg:text-md cursor-text z-10 relative py-1 transition mb-1 block font-[500] duration-200 text-gray-500`}
                >
                    Note
                </label>
                <p className='text-sm font-[550] text-doiteasy-black'>{administration.note.note}</p>
            </div>}

            <div className='w-full flex flex-row-reverse ml-auto items-center justify between gap-x-[10px] mt-[40px]'>
                <div className='w-max'>
                    <SecondaryButton 
                        buttonLabel={`Close`} 
                        buttonAction={()=>{close()}} 
                        processing={false} 
                    />
                </div>
            </div>
        </div>
    )
}

export default MedicationAdministration
import React from 'react'
import { NavLink, useLocation } from 'react-router-dom'

const CareHomeSettingsLayout = ({children, pageTitle}) => {
    const location = useLocation();
    const currentRoute = location.pathname;
    return (
        <div>
            <div className='h-[50px] w-full flex items-center'>
                <NavLink to="/admin/care-home-settings/departments"  className={`text-sm nav-button px-3 py-3 font-space-grotesk font-[550] ${currentRoute.includes('admin/care-home-settings/departments') ? 'border-b-2 border-doiteasy-black text-doiteasy-black' : 'border-b border-gray-300 text-gray-600'}`}>Departments</NavLink>
                
                <NavLink to="/admin/care-home-settings/rooms"  className={`text-sm nav-button px-3 py-3 font-space-grotesk font-[550] ${currentRoute.includes('admin/care-home-settings/rooms') ? 'border-b-2 border-doiteasy-black text-doiteasy-black' : 'border-b border-gray-300 text-gray-600'}`}>Rooms</NavLink>

                

                {/* <NavLink to="/admin/settings/roles-permissions"  className={`font-space-grotesk font-[550] text-sm nav-button px-3 py-3 ${currentRoute.includes('admin/settings/roles-permissions') ? 'border-b-2 border-doiteasy-black text-doiteasy-black' : 'border-b border-gray-300 text-gray-600'}`}>Roles & Permissions</NavLink> */}

                {/* <NavLink to="/admin/settings/system-users"  className={`font-medium text-sm nav-button px-3 py-3 ${currentRoute.includes('admin/settings/system-users') ? 'border-b-2 border-doiteasy-black text-doiteasy-black' : 'border-b border-gray-300 text-gray-600'}`}>System Users</NavLink> */}

                {/* <NavLink to="/admin/settings/audit-logs"  className={`font-space-grotesk font-[550] text-sm nav-button px-3 py-3 ${currentRoute.includes('admin/settings/audit-logs') ? 'border-b-2 border-doiteasy-black text-doiteasy-black' : 'border-b border-gray-300 text-gray-600'}`}>Audit Logs</NavLink> */}
                
            </div>
            <div className={`mt-[20px] min-h-screen`}>
                <main>{children}</main>
            </div>
        </div>
    )
}

export default CareHomeSettingsLayout

import './App.css';
import React from "react";
import "./index.css"

import {
  Routes,
  Route,
  Navigate
} from "react-router-dom";

import LoginPage from "./pages/LoginPage";
import Admin from "./pages/Admin";
import ErrorPage from "./pages/ErrorPage";
import AdminErrorPage from './pages/admin/AdminErrorPage';
import ActivateAccount from './pages/ActivateAccount';
import PasswordReset from './pages/PasswordReset';
import ScrollToTop from './components/layouts/ScrollToTop';
import { Provider } from 'react-redux';
import store from './store/store';
import ErrorNotifier from './components/elements/ErrorNotifier';
import SuccessNotifier from './components/elements/SuccessNotifier';
import Signup from './pages/Signup';
import Overview from './pages/admin/Overview';
import Residents from './pages/admin/care-home/residents/Residents';
import NewResident from './pages/admin/care-home/residents/NewResident';
import Staff from './pages/admin/care-home/staff/Staff';
import NewStaff from './pages/admin/care-home/staff/NewStaff';
import Settings from './pages/admin/Settings';
import UserProfile from './pages/admin/settings/UserProfile';
import Security from './pages/admin/settings/Security';
import NewRole from './pages/admin/settings/roles-permissions/NewRole';
import Roles from './pages/admin/settings/roles-permissions/Roles';
import RoleDetails from './pages/admin/settings/roles-permissions/RoleDetails';
import ResidentProfile from './pages/admin/care-home/residents/ResidentProfile';
import Rooms from './pages/admin/care-home/rooms/Rooms';
import RoomDetails from './pages/admin/care-home/rooms/RoomDetails';
import CareHomeSettings from './pages/admin/care-home/CareHomeSettings';
import CareHomeStaff from './pages/admin/care-home/CareHomeStaff';
import Departments from './pages/admin/care-home/departments/Departments';
import AcceptInvitation from './pages/AcceptInvitation';


export default function App() {
  return (
    <main>
    <Provider store={store}>
      <ErrorNotifier />
      <SuccessNotifier />
      <ScrollToTop>
        <Routes>
          <Route path="/" exact element={<LoginPage />} />
          <Route path="/signup" exact element={<Signup />} />
          <Route path="/signup/:invitationCode" exact element={<AcceptInvitation />} />
          <Route path="/password-reset" exact element={<PasswordReset />} />
          <Route path="/activate-account/:activationCode" exact element={<ActivateAccount />} />
          <Route path="/staff-onboarding/:staffId/" exact element={<ActivateAccount />} />
          <Route path="/admin" element={<Admin />}>
            <Route path="/admin/" element={<Navigate replace to="/admin/dashboard/overview" />} />
            <Route path="/admin/dashboard/overview" element={<Overview />} />

            <Route path="/admin/care-home/residents" element={<Residents />} />
            <Route path="/admin/care-home/residents/enrol-resident" element={<NewResident />} />
            <Route path="/admin/care-home/residents/resident/:residentId" element={<ResidentProfile />} />

            {/* <Route path="/admin/care-home/staff" element={<Staff />} />
            <Route path="/admin/care-home/staff/enrol-staff" element={<NewStaff />} /> */}

            {/* <Route path="/admin/care-home/rooms" element={<Rooms />} />
            <Route path="/admin/care-home/rooms/:roomId" element={<RoomDetails />} /> */}

            <Route path="/admin/care-home/staff" element={<CareHomeStaff />}>
              <Route path="/admin/care-home/staff/" element={<Navigate replace to="/admin/care-home/staff/care-home-staff" />} />
              <Route path="/admin/care-home/staff/care-home-staff" element={<Staff />} />
              <Route path="/admin/care-home/staff/care-home-staff/enrol-staff" element={<NewStaff />} />
              {/* TODO: bring these back */}
              {/* <Route path="/admin/care-home/staff/attendance" element={<Rooms />} />
              <Route path="/admin/care-home/staff/schedule" element={<RoomDetails />} /> */}
              
            </Route> 

            <Route path="/admin/care-home-settings" element={<CareHomeSettings />}>
              <Route path="/admin/care-home-settings/" element={<Navigate replace to="/admin/care-home-settings/departments" />} />
              
              <Route path="/admin/care-home-settings/departments" element={<Departments />} />
              {/* <Route path="/admin/settings/notifications" element={<Notifications />} /> */}
              
              <Route path="/admin/care-home-settings/rooms" element={<Rooms />} />
              <Route path="/admin/care-home-settings/rooms/room/:roomId" element={<RoomDetails />} />
              
              {/* <Route path="/admin/settings/users" element={<Users />} /> */}
            </Route> 

            <Route path="/admin/settings" element={<Settings />}>
              <Route path="/admin/settings/" element={<Navigate replace to="/admin/settings/user-profile" />} />
              <Route path="/admin/settings/user-profile" element={<UserProfile />} />
              {/* <Route path="/admin/settings/notifications" element={<Notifications />} /> */}
              <Route path="/admin/settings/security" element={<Security />} />

              {/* <Route path="/admin/settings/audit-logs" element={<AuditLogs />} /> */}

              {/* <Route path="/admin/settings/system-users" element={<SystemUsers />} /> */}
              
              <Route path="/admin/settings/roles-permissions" element={<Roles />} />
              <Route path="/admin/settings/roles-permissions/role/:roleId" element={<RoleDetails />} />
              <Route path="/admin/settings/roles-permissions/new-role" element={<NewRole />} />
              
              {/* <Route path="/admin/settings/users" element={<Users />} /> */}
            </Route> 

            <Route path="/admin/*" element={<AdminErrorPage />} />
          </Route>
          <Route path="*" element={<ErrorPage />} />
        </Routes>
      </ScrollToTop>
    </Provider>
    </main>
  );
}

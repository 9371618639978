import React from 'react'

const UserProfile = () => {
    
    return (

                <>
                    <div className='w-full flex justify-between'>
                        <div>
                            <h3 className='text-lg font-medium'>Your user profile</h3>
                            <p className='text-sm'>You can see and edit your profile information below</p>
                        </div>
                        <div>
                        {/* <Link to={`new-role`} className='rounded px-4 py-3 mt-4 text-sm text-purple-100 bg-vcm-purple transition duration-200 border border-vcm-purple hover:bg-vcm-light-purple hover:text-vcm-purple flex items-center justify-center gap-x-2'>
                            <PlusIcon className='w-5 h-5' /> Create new role
                        </Link> */}

                        </div>
                    </div>
                    <div className='w-full flex flex-row gap-x-4 py-4'>
                        <div className="w-full">
                            <label className="text-xs block mb-2">Name</label>
                            {/* <input type='text' value={userDetails.name} className='w-full px-4 py-2 rounded border border-primary border-opacity-30 bg-transparent text-xs focus:border-opacity-60 transition duration-200 focus:outline-none' /> */}
                        </div>
                    </div>
                    <div className='w-full flex flex-row gap-x-4 py-4'>
                        <div className="w-1/2">
                            <label className="text-xs block mb-2">email</label>
                            {/* <input type='text' value={userDetails.email} className='w-full px-4 py-2 rounded border border-primary border-opacity-30 bg-transparent text-xs focus:border-opacity-60 transition duration-200 focus:outline-none' /> */}
                        </div>
                        <div className="w-1/2">
                            <label className="text-xs block mb-2">phone</label>
                            {/* <input type='text' value={userDetails.phone} className='w-full px-4 py-2 rounded border border-primary border-opacity-30 bg-transparent text-xs focus:border-opacity-60 transition duration-200 focus:outline-none' /> */}
                        </div>
                    </div>
                    <div className='my-5 text-right'>
                        <button className="text-right rounded bg-primary px-5 py-4 text-xs text-white transition duration-200 hover:bg-opacity-50">Update Profile</button>
                    </div>
                </>
    )
}

export default UserProfile

import React, { useState } from 'react'
import { transactionTimeStamp, unSlugify } from '../../../utils'
import ChevronIcon from '../icons/ChevronIcon';
import PaperClipIcon from '../icons/PaperClipIcon';
import VideoPlayer from '../VideoPlayer';
import AudioPlayer from '../AudoPlayer';
import DownloadIcon from '../icons/DownloadIcon';

const ResidentReportCard = ({resident, report}) => {
  const [showMore, setShowMore] = useState(false);
  return (
    <div className='w-full p-[15px] border border-gray-100 shadow-xl shadow-doiteasy-black/5 rounded-[8px]'>
      <h3 className='font-[550] text-doiteasy-black mb-[5px] capitalize'>{unSlugify(report.reportType)} Report</h3>
      <p className='text-xs text-gray-500 font-[500]'>Submitted by <span className='font-[550] text-doiteasy-black'>{report.createdBy.name}</span> on {transactionTimeStamp(report.createdAt).date} - {transactionTimeStamp(report.createdAt).time}</p>

      {report.activities && report.activities.length > 0 && <div className='mt-[10px]'>
        <h4 className='text-sm font-[500] text-doiteasy-black mb-[5px] w-max pb-[5px] border-b border-gray-400'>Activities ({report.activities.length})</h4>
        {report.activities.map((activity, activityIndex)=>(
          <div key={activityIndex}>
            <p className='text-sm font-[500] text-doiteasy-black mb-[5px]'>{activity.activity} - <span className='text-xs font-[500] text-gray-500 mb-[5px]'>{activity.description}</span></p>
          </div>
        ))}
      </div>}

      {report.handoverTo && report.handoverTo !== '' && <div className='mt-[10px]'>
        <h4 className='text-sm font-[500] text-doiteasy-black mb-[5px] w-max pb-[5px] border-b border-gray-400'>handed over to</h4>
        <div>
          <p className='text-sm font-[500] text-doiteasy-black mb-[5px]'>{report.handoverTo.fullName} </p>
        </div>
      </div>}

      {report.incidentDate && report.incidentDate !== '' && <div className='mt-[10px]'>
        <h4 className='text-sm font-[500] text-doiteasy-black mb-[5px] w-max pb-[5px] border-b border-gray-400'>Incident date & time</h4>
        <div>
          <p className='text-sm font-[500] text-doiteasy-black mb-[5px]'>{transactionTimeStamp(report.incidentDate).date} - <span className='text-s, font-[500] text-gray-500 mb-[5px]'>{report.incidentTime}</span></p>
        </div>
      </div>}

      <div className='flex items-start gap-x-[5px] cursor-pointer py-[10px]'>
        <div onClick={()=>{setShowMore(!showMore)}} className='flex items-center justify-center w-[20px] mt-[3px]'>
          <ChevronIcon className={`w-4 h-4 text-gray-400 transition duration-200 ${showMore ? 'rotate-[270deg]' : 'rotate-[180deg]'}`} />
        </div>
        <div onClick={()=>{setShowMore(!showMore)}} className='w-full'>
          <p className='text-sm text-gray-400 font-[550]'>{showMore ? 'Less' : 'More'} Details</p>
        </div>
      </div>

      {showMore && <div className='mt-[10px]'>
        <h4 className='text-sm font-[500] text-doiteasy-black mb-[5px] w-max pb-[5px] border-b border-gray-400'>Notes ({report.notes.length})</h4>
        {<div className='w-full'>
          <p className='text-sm font-[500] text-gray-500'>{report.notes[0].note}</p>
          <button className='text-xs text-gray-600 rounded hover:bg-gray-200 transition duration-200 mt-[10px] bg-doiteasy-light-gray px-[15px] py-[6px] font-[550] flex items-center justify-center gap-x-[5px]'><PaperClipIcon className="w-4 h-4" /> Pin this note</button>
        </div>}

        {report.media && report.media.length > 0 && <div className='mt-[20px]'>
          <h4 className='text-sm font-[500] text-doiteasy-black mb-[5px] w-max pb-[5px] border-b border-gray-400'>Report media ({report.media.length})</h4>
          {report.media.map((item, itemIndex)=>(<div key={itemIndex} className='w-full'>
            {item.type === 'video' && <VideoPlayer videoSrc={item.url} /> }
            {item.type === 'audio' && <AudioPlayer audioSrc={item.url} /> }
            {/* {item.type === 'docy' && <div className='p-[40px]' dangerouslySetInnerHTML={{__html: item.content.content}} /> } */}
            {item?.type === 'image' && <img src={item?.url} alt='' className='w-full' /> }
            {item?.type === 'document' && 
              <>
                  <p className='text-sm text-gray-400 mt-5 mb-3'>This lecture is a downloadable content. Please use the button below to download it.</p>
                  <a href={item?.url} target="_blank" className='mt-[20px] rounded p-2 border-[1.5px] flex w-max items-center justify-center gap-x-[5px] border-verovian-purple text-verovian-purple hover:border-gray-400 hover:text-gray-400 transition duration-200 font-[550] text-xs' rel="noreferrer"><DownloadIcon className={`w-5 h-5`} />Download Document</a>
            </>
            }
          </div>))}

        </div>}
      </div>}


    </div>
  )
}

export default ResidentReportCard
import { CHECK_IN_RESIDENT, CHECKING_IN_RESIDENT, CREATE_RESIDENT, CREATING_RESIDENT, GET_RESIDENTS, GETTING_RESIDENTS, RESIDENTS_ERROR, UPDATE_RESIDENT, UPDATING_RESIDENT } from "../types"


const initialState = {
    residents: [],
    loadingResidents: true,
    residentsError: null,
    creatingResident: false,
    createdResident: null,
    updatingResident: false,
    updatedResident: null,
    checkingInResident: false,
    checkedInResident: null
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function(state = initialState, action){

    switch(action.type){
        case GETTING_RESIDENTS:
        return {
            ...state,
            loadingResidents :action.payload,
        }
        case GET_RESIDENTS:
        return{
            ...state,
            loadingResidents:false,
            residentsError:  null,
            residents: action.payload,
        }
        case CREATING_RESIDENT:
        return {
            ...state,
            creatingResident :action.payload,
        }
        case CREATE_RESIDENT:
        return{
            ...state,
            creatingResident:false,
            residentsError:  null,
            createdResident: action.payload,
        }
        case CHECKING_IN_RESIDENT:
        return {
            ...state,
            checkingInResident :action.payload,
        }
        case CHECK_IN_RESIDENT:
        return{
            ...state,
            checkingInResident:false,
            residentsError:  null,
            checkedInResident: action.payload,
        }
        case UPDATING_RESIDENT:
        return {
            ...state,
            updatingResident :action.payload,
        }
        case UPDATE_RESIDENT:
        return{
            ...state,
            updatingResident:false,
            residentsError:  null,
            updatedResident: action.payload,
        }
        case RESIDENTS_ERROR:
        return{
            ...state,
            loadingResidents:false,
            creatingResident:false,
            updatingResident:false,
            checkingInResident: false,
            residentsError: action.payload 
        }
        default: return state
    }

}
import React, { useEffect, useState } from 'react'
import { Link, useSearchParams } from 'react-router-dom'
import DoiteasyLogo from '../components/elements/DoiteasyLogo'
import TextField from '../components/elements/form/TextField'
import FormButton from '../components/elements/form/FormButton'
import ArrowIcon from '../components/elements/icons/ArrowIcon'
import PasswordField from '../components/elements/form/PasswordField'
import Checkbox from '../components/elements/form/Checkbox'
import ModalDialog from '../components/layouts/ModalDialog'

import axios from 'axios'
import { ERROR } from '../store/types'
import { useDispatch } from 'react-redux'
import CheckIcon from '../components/elements/icons/CheckIcon'
import SelectField from '../components/elements/form/SelectField'
import TrashIcon from '../components/elements/icons/TrashIcon'
import Confetti from '../components/elements/Confetti'
import { authHeader } from '../utils'
import EmailConfirmation from '../components/partials/EmailConfirmation'

const Signup = () => {
    const [validationErrors, setValidationErrors] = useState({});
    const [userDetails, setUserDetails] = useState({});
    const [processing, setProcessing] = useState(false);
    const dispatch = useDispatch()

    const steps = [
        {
            title: "Your Details",
            description: "Please provide details for your user account."
        },
        {
            title: "Facility Details",
            description: "Tell us a bit about your care home"
        },
        {
            title: "Key Personnel",
            description: "Add some key personnel for your care home for our records. We may need to reach out to the people you add as well as yourself with important updates."
        }
    ]

    const [activeStep, setActiveStep] = useState(0);
    const [completedSteps, setCompletedSteps] = useState([]);

    const [acceptedUserTerms, setAcceptedUserTerms] = useState(false);

    const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);

    const [searchParams] = useSearchParams();

    useEffect(() => {
        if(searchParams.get("continueFrom")){
            setCompletedSteps([0])
            setActiveStep(parseInt(searchParams.get("continueFrom")))
        }
        
        return () => {
            
        };
    }, [searchParams]);

    // const [otp, setOtp] = useState('');
    // const [otpSent, setOtpSent] = useState(false)
    // const [counted, setCounted] = useState(false);

    const validateUserForm = () => {
        let errors = {}
        if(!userDetails.firstName || userDetails.firstName === '') {
            errors.firstName = true
        }
        if(!userDetails.lastName || userDetails.lastName === '') {
            errors.lastName = true
        }
        if(!userDetails.email || userDetails.email === '') {
            errors.email = true
        }
        if(!userDetails.phone || userDetails.phone === '') {
            errors.phone = true
        }
        if(!userDetails.password || userDetails.password === '') {
            errors.password = true
        }
        if(!acceptedUserTerms) {
            errors.terms = true
        }

        setValidationErrors(errors)
        return errors
    }

    const createUser = async () => {
        if (Object.values(validateUserForm()).includes(true)) {

            dispatch({
                type: ERROR,
                error: {response: {data: {
                    message: 'Please check the highlighted fields'
                }}}
            })
            return
        }
        try {
            const headers = {
                'Content-Type': 'application/json'
            }
            const requestPayload = {
                email: userDetails.email,
                firstName: userDetails.firstName,
                middleName: userDetails.middleName,
                lastName: userDetails.lastName,
                password: userDetails.password,
                userType: "USER",
                phone: userDetails.phone
            }
            setProcessing(true)
            
            await axios.post(`${process.env.REACT_APP_BASE_URL}/onboarding/users`, requestPayload, { headers }) 
            setProcessing(false)
            // setOtpSent(true)
            // setCounted(false)
            setCompletedSteps([...completedSteps, 0])
            setConfirmationModalOpen(true)
        } catch (error) {
            console.log(error)
            dispatch({
                type: ERROR,
                error
            })
            setProcessing(false)
        }
    }


    // const [confirmingEmail, setConfirmingEmail] = useState(false);
    // const confirmEmail = async () => {
    //     try {
    //         if(!otp || otp === '') {
    //             return
    //         }
    //         const headers = {
    //             'Content-Type': 'application/json'
    //         }
    //         const requestPayload = { 
    //             confirmationCode: otp
    //         }
    //         setConfirmingEmail(true)
            
    //         const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/onboarding/email-confirmation`, requestPayload, { headers }) 
    //         localStorage.setItem("authToken", JSON.stringify(response.data.data.data.accessToken));

    //         setConfirmingEmail(false)
    //         setConfirmationModalOpen(false)
    //         setActiveStep(1)
    //     } catch (error) {
    //         console.log(error)
    //         dispatch({
    //             type: ERROR,
    //             error
    //         })
    //         setProcessing(false)
    //     }
    // }

    const [careHomeDetails, setCareHomeDetails] = useState({});

    const validateStepTwo = () => {
        let errors = {}

        if(!careHomeDetails.name || careHomeDetails.name === ''){
            errors.name = true
        }
        if(!careHomeDetails.startOfOperationsYear || careHomeDetails.startOfOperationsYear === ''){
            errors.startOfOperationsYear = true
        }
        if(!careHomeDetails.address || careHomeDetails.address === ''){
            errors.address = true
        }
        if(!careHomeDetails.email || careHomeDetails.email === ''){
            errors.careHomeEmail = true
        }
        if(!careHomeDetails.phone || careHomeDetails.phone === ''){
            errors.careHomePhone = true
        }
        if(!careHomeDetails.city || careHomeDetails.city === ''){
            errors.city = true
        }
        if(!careHomeDetails.state || careHomeDetails.state === ''){
            errors.state = true
        }
        if(!careHomeDetails.zip || careHomeDetails.zip === ''){
            errors.zip = true
        }
        if(!careHomeDetails.numberOfStaff || careHomeDetails.numberOfStaff === ''){
            errors.numberOfStaff = true
        }
        setValidationErrors(errors)
        return errors
    }

    const completeStepTwo = () => {
        if (Object.values(validateStepTwo()).includes(true)) {

            dispatch({
                type: ERROR,
                error: {response: {data: {
                    message: 'Please check the highlighted fields'
                }}}
            })
            return
        }

        setCompletedSteps([...completedSteps, 1])
        setActiveStep(2)
    }

    const employeeCount = [
        {
            label: "1-10",
            value: "1-10",
        },
        {
            label: "11-25",
            value: "11-25",
        },
        {
            label: "26-50",
            value: "26-50",
        },
        {
            label: "51-100",
            value: "51-100",
        },
        {
            label: "Above 100",
            value: "100-0"
        },
    ]

    const personnel = {
        name: "",
        email: "",
        phone: "",
        position: ""
    }

    const personnelPositions = [
        {label: "Owner"},
        {label: "Board Member"},
        {label: "Medical Director"},
        {label: "Chief Executive Officer"},
        {label: "Chief Financial Officer"},
        {label: "Human Resources Director"},
        {label: "Other"},
    ]

    const [keyPersonnel, setKeyPersonnel] = useState([personnel]);

    const addKeyPersonnel = () => {
        setKeyPersonnel([...keyPersonnel, personnel])
    }
    
    const removeKeyPersonnel = (index) => {
        setKeyPersonnel(keyPersonnel.filter((item, i) => i !== index))
    }

    const updateKeyPersonnel = (index, key, value) => {
        let tempKeyPersonnel = [...keyPersonnel]
        tempKeyPersonnel[index][key] = value
    }

    const validateStepThree = () => {
        let errors = {}

        keyPersonnel.forEach((person, personIndex) => {
            if(!person.name || person.name === ''){
                errors[`person-${personIndex}-name`] = true
            }
            if(!person.email || person.email === ''){
                errors[`person-${personIndex}-email`] = true
            }
            // if(!person.phone || person.phone === ''){
            //     errors[`person-${personIndex}-phone`] = true
            // }
            if(!person.position || person.position === ''){
                errors[`person-${personIndex}-position`] = true
            }
        })
        
        if(!facilityTermsAccepted){
            errors.facilityTermsAccepted = true
        }
        if(!acknowledged){
            errors.acknowledged = true
        }
        setValidationErrors(errors)
        return errors
    }

    const [facilityTermsAccepted, setFacilityTermsAccepted] = useState(false);
    const [acknowledged, setAcknowledged] = useState(false);

    const [showConfetti, setShowConfetti] = useState(false);

    const handleCelebrate = () => {
        setShowConfetti(true);
        setTimeout(() => setShowConfetti(false), 2000); // Hide confetti after 2 seconds
    };
    

    const createFacility = async () => {
        if (Object.values(validateStepThree()).includes(true)) {

            dispatch({
                type: ERROR,
                error: {response: {data: {
                    message: 'Please check the highlighted fields'
                }}}
            })
            return
        }

        try {
            const headers = authHeader()

            const requestPayload =  {
                name: careHomeDetails.name,
                website: careHomeDetails.website,
                contact: {
                    email: careHomeDetails.email,
                    address: {
                        address:careHomeDetails.address,
                        city: careHomeDetails.city,
                        state: careHomeDetails.state,
                        zip: careHomeDetails.zip,
                    },
                    phone: careHomeDetails.phone,
                },
                keyPersonnel: keyPersonnel,
                numberOfStaff: careHomeDetails.numberOfStaff,
                startOfOperationsYear: careHomeDetails.startOfOperationsYear,
                invalidateSession: true
            }

            setProcessing(true)
            
            await axios.post(`${process.env.REACT_APP_BASE_URL}/onboarding/care-homes`, requestPayload, { headers }) 
            setProcessing(false)
            setCompletedSteps([...completedSteps, 2])
            setActiveStep(3)
            handleCelebrate()
            localStorage.removeItem("authToken")
            setProcessing(false)
        } catch (error) {
            console.log(error)
            dispatch({
                type: ERROR,
                error
            })
            setProcessing(false)
        }
    }


    return (
        <>
            <div className="w-full min-h-screen flex pt-[20px] justify-center bg-doiteasy-light-gray">
                <div className="w-5/12">
                    <DoiteasyLogo />

                    <h3 className="mt-[10px] font-medium text-gray-700 text-xl mb-1 text-center">Begin your path to compassionate care.</h3>
                    <p className="text-sm text-opacity-80 text-center">Please provide your details and those of your care facility to get started right away.</p>

                    <div className='flex items-center justify-between mt-[20px] px-[40px] relative'>
                        <div className='w-full h-[2px] bg-blue-200 absolute top-[19px] left-0 z-10'></div>
                        {steps.map((step, stepIndex)=>(
                            <div key={stepIndex} className='flex flex-col items-center justify-center z-20'>
                                <div className={`w-[40px] h-[40px] rounded-full flex items-center justify-center text-white border-[5px] border-doiteasy-light-gray ${activeStep === stepIndex ? 'bg-doiteasy-blue' : completedSteps.includes(stepIndex) ? 'bg-green-500' : 'bg-blue-200'}`}>
                                    {!completedSteps.includes(stepIndex) ? <p className='font-space-grotesk font-[500]'>{stepIndex + 1}</p> : <CheckIcon className="w-5 h-5 text-white" />}
                                </div>
                                <p className={`mt-[10px] text-sm ${activeStep === stepIndex ? 'font-[600] text-doiteasy-black' : 'font-[500] text-gray-400'}`}>{step.title}</p>
                            </div>
                        ))}
                    </div>

                    <div className='rounded-[32px] bg-white px-[40px] py-[40px] mt-[20px]'>
                        <p className="text-sm text-opacity-80 mb-[10px] font-[550] tracking-[0.01em] text-gray-600">{steps[activeStep]?.description || ''}</p>
                        {activeStep < 3 && <hr/>}

                        {activeStep === 0 && <>
                            <div className='mt-4'>
                                <TextField
                                    inputLabel="First name" 
                                    fieldId="first-name" 
                                    inputType="text" 
                                    preloadValue={''}
                                    inputPlaceholder={'Your given name'}
                                    hasError={validationErrors && validationErrors.firstName} 
                                    returnFieldValue={(value)=>{setUserDetails({...userDetails, ...{firstName: value}})}}
                                />
                            </div>

                            <div className='mt-4 flex items-center justify-between gap-x-[20px]'>
                                <div className='w-full'>
                                    <TextField
                                        inputLabel="Middle name" 
                                        fieldId="middle-name" 
                                        inputType="text" 
                                        preloadValue={''}
                                        inputPlaceholder={'Your middle name (optional)'}
                                        hasError={false} 
                                        returnFieldValue={(value)=>{setUserDetails({...userDetails, ...{middleName: value}})}}
                                    />
                                </div>
                                <div className='w-full'>
                                    <TextField
                                        inputLabel="Last name" 
                                        fieldId="last-name" 
                                        inputType="text" 
                                        preloadValue={''}
                                        inputPlaceholder={'Your surname'}
                                        hasError={validationErrors && validationErrors.lastName} 
                                        returnFieldValue={(value)=>{setUserDetails({...userDetails, ...{lastName: value}})}}
                                    />
                                </div>
                            </div>

                            <div className='mt-4'>
                                <TextField
                                    inputLabel="Your email address" 
                                    fieldId="email-address" 
                                    inputType="text" 
                                    preloadValue={''}
                                    inputPlaceholder={'Active email address'}
                                    hasError={validationErrors && validationErrors.firstName} 
                                    returnFieldValue={(value)=>{setUserDetails({...userDetails, ...{email: value}})}}
                                />
                                <label className='block mt-2 text-sm text-gray-400'>Before you can proceed, we will send an otp to your email to confirm it is active</label>
                            </div>

                            <div className='mt-4'>
                                <TextField
                                    inputLabel="Your phone number" 
                                    fieldId="phone-number" 
                                    inputType="text" 
                                    preloadValue={''}
                                    inputPlaceholder={'Active contact phone'}
                                    hasError={validationErrors && validationErrors.phone} 
                                    returnFieldValue={(value)=>{setUserDetails({...userDetails, ...{phone: value}})}}
                                />
                                {/* <label className='block mt-2 text-sm text-gray-400'>Before you can proceed, we will send an otp to your email to confirm it is active</label> */}
                            </div>

                            <div className='mt-4'>
                                <PasswordField
                                    inputLabel="Password" 
                                    fieldId="password" 
                                    inputType="password" 
                                    inputPlaceholder={'Choose a secure password'}
                                    preloadValue={''}
                                    hasError={validationErrors && validationErrors.password} 
                                    returnFieldValue={(value)=>{setUserDetails({...userDetails, ...{password: value}})}}
                                />
                            </div>

                            <div className='w-full mt-8'>
                                <Checkbox
                                    CheckboxLabel="I have read and agree with the Terms & Conditions & Privacy Policy of Doiteasy"
                                    checkboxToggleFunction={()=>{
                                        setAcceptedUserTerms(!acceptedUserTerms)
                                    }} 
                                    isChecked={acceptedUserTerms} 
                                    hasError={validationErrors.terms} 
                                />
                            </div>
                            
                            <div className='mt-8 w-[60%] mx-auto'>
                                <FormButton 
                                    buttonLabel={
                                        <span className='flex items-center justify-center gap-x-[20px] text-white font-space-grotesk font-[550]'>
                                            Continue
                                            <ArrowIcon className={`w-5 h-5`} />
                                        </span>
                                    } 
                                    buttonAction={()=>{createUser()}} 
                                    processing={processing} />
                            </div>
                        </>}

                        {activeStep === 1 && <>
                            <div className='mt-4'>
                                <TextField
                                    inputLabel="Facility name" 
                                    fieldId="facility-name" 
                                    inputType="text" 
                                    preloadValue={''}
                                    inputPlaceholder={'The name of your care home'}
                                    hasError={validationErrors && validationErrors.name} 
                                    returnFieldValue={(value)=>{setCareHomeDetails({...careHomeDetails, ...{name: value}})}}
                                />
                            </div>

                            <div className='mt-4'>
                                <TextField
                                    inputLabel="Address" 
                                    fieldId="facility-address" 
                                    inputType="text" 
                                    preloadValue={''}
                                    inputPlaceholder={'Where is your facility located?'}
                                    hasError={validationErrors && validationErrors.address} 
                                    returnFieldValue={(value)=>{setCareHomeDetails({...careHomeDetails, ...{address: value}})}}
                                />
                            </div>

                            <div className='mt-4 flex items-center justify-between gap-x-[20px]'>
                                <div className='w-full'>
                                    <TextField
                                        inputLabel="City" 
                                        fieldId="address-city" 
                                        inputType="text" 
                                        preloadValue={''}
                                        inputPlaceholder={'City/Location'}
                                        hasError={validationErrors && validationErrors.city} 
                                        returnFieldValue={(value)=>{setCareHomeDetails({...careHomeDetails, ...{city: value}})}}
                                    />
                                </div>
                                <div className='w-full'>
                                    <TextField
                                        inputLabel="Zip/Area Code" 
                                        fieldId="address-zip-code" 
                                        inputType="text" 
                                        preloadValue={''}
                                        inputPlaceholder={'Zip/Area Code'}
                                        hasError={validationErrors && validationErrors.zip} 
                                        returnFieldValue={(value)=>{setCareHomeDetails({...careHomeDetails, ...{zip: value}})}}
                                    />
                                </div>
                                <div className='w-full'>
                                    <TextField
                                        inputLabel="State" 
                                        fieldId="address-state" 
                                        inputType="text" 
                                        preloadValue={''}
                                        inputPlaceholder={'State'}
                                        hasError={validationErrors && validationErrors.state} 
                                        returnFieldValue={(value)=>{setCareHomeDetails({...careHomeDetails, ...{state: value}})}}
                                    />
                                </div>
                            </div>

                            <div className='mt-4 flex items-center justify-between gap-x-[20px]'>
                                <div className='w-full'>
                                    <TextField
                                        inputLabel="Contact email address" 
                                        fieldId="facility-email" 
                                        inputType="text" 
                                        preloadValue={''}
                                        inputPlaceholder={`Your facility's email`}
                                        hasError={validationErrors && validationErrors.careHomeEmail} 
                                        returnFieldValue={(value)=>{setCareHomeDetails({...careHomeDetails, ...{email: value}})}}
                                    />
                                </div>
                                <div className='w-full'>
                                    <TextField
                                        inputLabel="Phone number" 
                                        fieldId="facility-phone" 
                                        inputType="text" 
                                        preloadValue={''}
                                        inputPlaceholder={'Contact phone number'}
                                        hasError={validationErrors && validationErrors.careHomePhone} 
                                        returnFieldValue={(value)=>{setCareHomeDetails({...careHomeDetails, ...{phone: value}})}}
                                    />
                                </div>
                            </div>

                            <div className='w-full mt-4'>
                                <TextField
                                    inputLabel="Website" 
                                    fieldId="facility-website" 
                                    inputType="text" 
                                    preloadValue={''}
                                    inputPlaceholder={`Your facility's website (if available)`}
                                    hasError={false} 
                                    returnFieldValue={(value)=>{setCareHomeDetails({...careHomeDetails, ...{website: value}})}}
                                />
                            </div>

                            <div className='w-full flex items-start gap-x-[20px] mt-4'>
                                <div className='w-full'>
                                    <SelectField
                                        selectOptions={employeeCount}
                                        inputLabel="Number of Staff"
                                        titleField="label"
                                        displayImage={false}
                                        imageField=""
                                        // preSelectedIndex={complexions.findIndex(item => item.value === applicationPayload.complexion)}
                                        preSelectedIndex={null}
                                        fieldId="applicant-complexion"
                                        hasError={validationErrors && validationErrors.numberOfStaff}
                                        returnFieldValue={(value) => {setCareHomeDetails({...careHomeDetails, ...{numberOfStaff: value.value}})}}
                                    />
                                    <label className='block mt-2 text-sm text-gray-400'>How many staff do you currently have in your facility?</label>
                                </div>
                                <div className='w-full'>
                                    <TextField
                                        inputLabel="Start of operations year" 
                                        fieldId="facility-start-year" 
                                        inputType="text" 
                                        preloadValue={''}
                                        inputPlaceholder={`What year did you start operations?`}
                                        hasError={validationErrors && validationErrors.startOfOperationsYear} 
                                        returnFieldValue={(value)=>{setCareHomeDetails({...careHomeDetails, ...{startOfOperationsYear: value}})}}
                                    />
                                </div>
                            </div>
                            
                            <div className='mt-8 w-[60%] mx-auto'>
                                <FormButton 
                                    buttonLabel={
                                        <span className='flex items-center justify-center gap-x-[20px] text-white font-space-grotesk font-[550]'>
                                            Continue
                                            <ArrowIcon className={`w-5 h-5`} />
                                        </span>
                                    } 
                                    buttonAction={()=>{completeStepTwo()}} 
                                    processing={processing} />
                            </div>
                        </>}
                        
                        {activeStep === 2 && <>
                            {keyPersonnel.map ((person, personIndex)=>(<div key={personIndex}>
                                {personIndex > 0 && <div className='flex flex-row-reverse mt-4'>
                                    <button onClick={()=>{removeKeyPersonnel(personIndex)}} className='p-[7px] text-gray-400 hover:bg-gray-100 hover:text-red-700 rounded transition duration-200'>
                                        <TrashIcon className={`w-5 h-5`} />
                                    </button>
                                </div>}
                                <div className='mt-0'>
                                    <TextField
                                        inputLabel="Full name" 
                                        fieldId={`key-personnel-${personIndex}-name`}
                                        inputType="text" 
                                        preloadValue={person.name}
                                        inputPlaceholder={'The name of your care home'}
                                        hasError={validationErrors && validationErrors[`person-${personIndex}-name`]} 
                                        returnFieldValue={(value)=>{updateKeyPersonnel(personIndex, 'name', value)}}
                                    />
                                </div>

                                <div className='mt-4 flex items-center justify-between gap-x-[20px]'>
                                    <div className='w-full'>
                                        <TextField
                                            inputLabel="Email address" 
                                            fieldId={`key-personnel-${personIndex}-email`} 
                                            inputType="text" 
                                            preloadValue={person.email}
                                            inputPlaceholder={`Their email address`}
                                            hasError={validationErrors && validationErrors[`person-${personIndex}-email`]} 
                                            returnFieldValue={(value)=>{updateKeyPersonnel(personIndex, 'email', value)}}
                                        />
                                    </div>
                                    <div className='w-full'>
                                        <TextField
                                            inputLabel="Phone number" 
                                            fieldId={`key-personnel-${personIndex}-phone`} 
                                            inputType="text" 
                                            preloadValue={person.phone}
                                            inputPlaceholder={'Their phone number'}
                                            hasError={validationErrors && validationErrors[`person-${personIndex}-phone`]} 
                                            returnFieldValue={(value)=>{updateKeyPersonnel(personIndex, 'phone', value)}}
                                        />
                                    </div>
                                </div>

                                <div className='mt-4'>
                                    <SelectField
                                        selectOptions={personnelPositions}
                                        inputLabel="Position"
                                        titleField="label"
                                        displayImage={false}
                                        imageField=""
                                        // preSelectedIndex={complexions.findIndex(item => item.value === applicationPayload.complexion)}
                                        preSelectedIndex={null}
                                        fieldId={`key-personnel-${personIndex}-position`}
                                        hasError={validationErrors && validationErrors[`person-${personIndex}-position`]}
                                        returnFieldValue={(value) => {updateKeyPersonnel(personIndex, 'position', value.label)}}
                                    />
                                    <label className='block mt-2 text-sm text-gray-400'>What position does this person occupy in the organization?</label>
                                </div> 
                            </div>))}

                            <div className='my-4 flex flex-row-reverse'>
                                <button onClick={()=>{addKeyPersonnel()}} className='px-[18px] py-[12px] font-space-grotesk border-[2px] rounded-[8px] transition duration-200 text-doiteasy-black border-doiteasy-black text-sm font-[550] hover:bg-doiteasy-black hover:text-doiteasy-light-gray'>Add another key personnel</button>
                            </div>

                            <div className='my-8 border-t w-full border-gray-200' />

                            <div className='w-full mt-8'>
                                <Checkbox
                                    CheckboxLabel="I have read and agree with the Terms & Conditions & Privacy Policy of Doiteasy for Care Facilities"
                                    checkboxToggleFunction={()=>{
                                        setFacilityTermsAccepted(!facilityTermsAccepted)
                                    }} 
                                    isChecked={facilityTermsAccepted} 
                                    hasError={validationErrors.facilityTermsAccepted} 
                                />
                            </div>

                            <div className='w-full mt-4'>
                                <Checkbox
                                    CheckboxLabel="I hereby confirm that i have the authority to create this account onn behalf of the Care facility"
                                    checkboxToggleFunction={()=>{
                                        setAcknowledged(!acknowledged)
                                    }} 
                                    isChecked={acknowledged} 
                                    hasError={validationErrors.acknowledged} 
                                />
                            </div>
                            
                            <div className='mt-8 w-[60%] mx-auto'>
                                <FormButton 
                                    buttonLabel={
                                        <span className='flex items-center justify-center gap-x-[20px] text-white font-space-grotesk font-[550]'>
                                            Create Facility
                                            <ArrowIcon className={`w-5 h-5`} />
                                        </span>
                                    } 
                                    buttonAction={()=>{createFacility()}} 
                                    processing={processing} />
                            </div>
                        </>}

                        {activeStep === 3 && <>
                            <div className='w-full pb-[30px]'>
                                <div className='mx-auto w-full text-center px-[40px]'>
                                    <span className='text-[60px] mb-[20px]'>🎉</span>
                                    <h3 className='font-space-grotesk text-[24px] leading-[28px] text-gunmetal-black font-[500]'>You're all done. Welcome aboard!</h3>
                                    <p className='mt-[10px] font-[500]'>Thank you for signing up.</p>
                                    <p className='mt-[10px] text-sm font-[500] text-gray-500'>We are currently going through your information and once your account is approved, you will be able to log in to the platform</p>
                                    {showConfetti && 
                                        <Confetti trigger={showConfetti} />
                                    }
                                </div>
                            </div>
                        </>}

                    </div>

                    <div className='w-full text-center mt-8 mb-8'>
                        <p className="my-3 text-sm block">
                            <Link to="/" className="text-secondary">Already have an account? Click here to login</Link>
                        </p>
                    </div>

                </div>
            </div>

            <ModalDialog
                shown={confirmationModalOpen} 
                closeFunction={()=>{setConfirmationModalOpen(false)}} 
                actionFunction={()=>{}} 
                actionFunctionLabel={``}
                dialogTitle=''
                maxWidthClass='max-w-lg'
                hideActions={true}
            >
                <EmailConfirmation 
                    email={userDetails.email}
                    closeModalOnSuccess={()=>{
                        setConfirmationModalOpen(false)
                        setActiveStep(1)
                    }}
                />
            </ModalDialog>
        </>
    )
}

export default Signup
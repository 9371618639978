import { CREATE_RESIDENT_ACTIVITY, CREATING_RESIDENT_ACTIVITY, GET_RESIDENT_ACTIVITIES, GETTING_RESIDENT_ACTIVITIES, RESIDENT_ACTIVITIES_ERROR, UPDATE_RESIDENT_ACTIVITY, UPDATING_RESIDENT_ACTIVITY } from "../types"


const initialState = {
    activities: [],
    loadingResidentActivities: true,
    residentActivitiesError: null,
    creatingResidentActivity: false,
    createdResidentActivity: null,
    updatingResidentActivity: false,
    updatedResidentActivity: null,
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function(state = initialState, action){

    switch(action.type){
        case GETTING_RESIDENT_ACTIVITIES:
        return {
            ...state,
            loadingResidentActivities :action.payload,
        }
        case GET_RESIDENT_ACTIVITIES:
        return{
            ...state,
            loadingResidentActivities:false,
            residentActivitiesError:  null,
            activities: action.payload,
        }
        case CREATING_RESIDENT_ACTIVITY:
        return {
            ...state,
            creatingResidentActivity: action.payload,
        }
        case CREATE_RESIDENT_ACTIVITY:
        return{
            ...state,
            creatingResidentActivity:false,
            residentActivitiesError:  null,
            createdResidentActivity: action.payload,
        }
        case UPDATING_RESIDENT_ACTIVITY:
        return {
            ...state,
            updatingResidentActivity: action.payload,
        }
        case UPDATE_RESIDENT_ACTIVITY:
        return{
            ...state,
            updatingResidentActivity:false,
            residentActivitiesError:  null,
            updatedResidentActivity: action.payload,
        }
        case RESIDENT_ACTIVITIES_ERROR:
        return{
            ...state,
            loadingResidentActivities:false,
            creatingResidentActivity:false,
            updatingResidentActivity:false,
            residentActivitiesError: action.payload 
        }
        default: return state
    }

}
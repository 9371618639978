import axios from "axios"
import { authHeader, baseUrl } from "../../utils"

import { CREATE_DEPARTMENT, CREATING_DEPARTMENT, DELETE_DEPARTMENT, DELETING_DEPARTMENT, DEPARTMENTS_ERROR, GETTING_DEPARTMENTS, GET_DEPARTMENTS, UPDATED_DEPARTMENT, UPDATING_DEPARTMENT } from "../types"

export const createDepartment = (departmentPayload) => async (dispatch) => {    
    try{
        const headers = authHeader()

        dispatch({
            type: CREATING_DEPARTMENT,
            payload: true
        })
        const response = await axios.post(`${baseUrl}/departments`, departmentPayload, { headers })
        
        dispatch({
            type: CREATE_DEPARTMENT,
            payload: response.data.data
        })
        
    }
    catch(error){
        // console.log(error)
        dispatch({
            type: DEPARTMENTS_ERROR,
            // payload: error.response.data
            error
        })
    }
}

export const updateDepartment = (departmentId, departmentPayload) => async (dispatch) => {    
    try{
        const headers = authHeader()

        dispatch({
            type: UPDATING_DEPARTMENT,
            payload: true
        })
        const response = await axios.put(`${process.env.REACT_APP_API_URL}/departments/update/${departmentId}`, departmentPayload, { headers })
        
        dispatch({
            type: UPDATED_DEPARTMENT,
            payload: response.data.data
        })
        
    }
    catch(error){
        // console.log(error)
        dispatch({
            type: DEPARTMENTS_ERROR,
            // payload: error.response.data
            error
        })
    }
}

export const updateDepartmentLeavePolicies = (departmentId, leavePolicyPayload) => async (dispatch) => {    
    try{
        const headers = authHeader()

        dispatch({
            type: CREATING_DEPARTMENT,
            payload: true
        })
        const response = await axios.put(`${process.env.REACT_APP_API_URL}/leave-policies/update/${departmentId}`, leavePolicyPayload, { headers })
        
        dispatch({
            type: CREATE_DEPARTMENT,
            payload: response.data.data
        })
        
    }
    catch(error){
        console.log(error)
        dispatch({
            type: DEPARTMENTS_ERROR,
            // payload: error.response.data
            error
        })
    }
}

// export const fetchDepartmentLeavePolicies = (departmentId) => async (dispatch) => {    
//     try{
//         const headers = authHeader()

//         dispatch({
//             type: CREATING_DEPARTMENT,
//             payload: true
//         })
//         const response = await axios.get(`${process.env.REACT_APP_API_URL}/leave-policies/update/${departmentId}`, { headers })
        
//         dispatch({
//             type: CREATE_DEPARTMENT,
//             payload: response.data.data
//         })
        
//     }
//     catch(error){
//         console.log(error)
//         dispatch({
//             type: DEPARTMENTS_ERROR,
//             // payload: error.response.data
//             error
//         })
//     }
// }

export const deleteDepartment = (departmentId) => async (dispatch) => {    
    try{
        const headers = authHeader()

        dispatch({
            type: DELETING_DEPARTMENT,
            payload: true
        })
        const response = await axios.delete(`${process.env.REACT_APP_API_URL}/departments/delete/${departmentId}`, { headers })
        
        dispatch({
            type: DELETE_DEPARTMENT,
            payload: response.data.data
        })
        
    }
    catch(error){
        dispatch({
            type: DEPARTMENTS_ERROR,
            error
        })
    }
}

export const clearCreatedDepartment = () => async (dispatch) => {    
    dispatch({
        type: CREATE_DEPARTMENT,
        payload: null
    })
}

export const clearUpdatedDepartment = () => async (dispatch) => {    
    dispatch({
        type: UPDATED_DEPARTMENT,
        payload: null
    })
}

export const clearDeletedDepartment = () => async (dispatch) => {    
    dispatch({
        type: UPDATED_DEPARTMENT,
        payload: null
    })
}

export const fetchDepartments = (filterString, page, perPage) => async dispatch => {    
    try{
        const headers = authHeader()

        let url = `${baseUrl}/departments`
        if(filterString && filterString !== '') {
            url += `${url.includes('?') ? '&' : '?'}${filterString}`
        }

        if(page && page!=='') {
            url += `${url.includes('?') ? '&' : '?'}page=${page}`
        }

        if(perPage && perPage!=='') {
            url += `${url.includes('?') ? '&' : '?'}perPage=${perPage}`
        }

        dispatch( {
            type: GETTING_DEPARTMENTS,
            payload: true
        })

        const response = await axios.get(`${url}`, { headers })
        // const response = await axios.get(`${process.env.REACT_APP_API_URL}/${requestUrl}?expand=custodian&limit=${pagination.perPage}&page=${pagination.page}&${appliedFilters}&${applySort(sort)}`, { headers })

        dispatch( {
            type: GET_DEPARTMENTS,
            payload: response.data.data
        })
        
    }
    catch(error){
        dispatch( {
            type: DEPARTMENTS_ERROR,
            // payload: error.response.data,
            error
        })
    }
}

// export const getWalletDetails = (walletId) => async dispatch => {    
//     try{
//         const headers = authHeader()
//         dispatch({
//             type: GETTING_WALLET_DETAILS,
//             payload: true
//         })
//         const response = await axios.get(`${process.env.REACT_APP_API_URL}/wallets/${walletId}?expand=custodian`, { headers })
//         dispatch( {
//             type: GET_WALLET_DETAILS,
//             payload: response.data.data
//         })
//     }
//     catch(error){
//         dispatch( {
//             type: WALLET_DETAILS_ERROR,
//             error
//             // payload: error.response.data,
//         })
//     }
// }
import { combineReducers } from "redux";
import { errorReducer } from "./errorReducer";
import successReducer from "./successReducer";
import staffReducer from "./staffReducer";
import rolesPermissionsReducer from "./rolesPermissionsReducer";
import auditLogsReducer from "./auditLogsReducer";
import residentsReducer from "./residentsReducer";
import roomsReducer from "./roomsReducer";
import departmentReducer from "./departmentReducer";
import residentPrescriptionsReducer from "./residentPrescriptionsReducer";
import residentReportsReducer from "./residentReportsReducer";
import residentActivitiesReducer from "./residentActivitiesReducer";
import notesReducer from "./notesReducer";

const rootReducer = combineReducers({
    success: successReducer,
    errors: errorReducer,
    staff: staffReducer,
    roles: rolesPermissionsReducer,
    auditLogs: auditLogsReducer,
    residents: residentsReducer,
    rooms: roomsReducer,
    departments: departmentReducer,
    prescriptions: residentPrescriptionsReducer,
    residentReports: residentReportsReducer,
    residentActivities: residentActivitiesReducer,
    notes: notesReducer
});

export default rootReducer;
import React, { useState } from 'react'
import SecondaryButton from '../../elements/SecondaryButton'
import FormButton from '../../elements/form/FormButton'
import { useDispatch, useSelector } from 'react-redux'
import TextareaField from '../../elements/form/TextareaField'
import { Switch } from '@headlessui/react'
import { ERROR } from '../../../store/types'
import { createNote } from '../../../store/actions/notesActions'

const NewResidentNote = ({resident, close}) => {
    const notesSelector = useSelector(state => state.notes)
    const dispatch = useDispatch()
    const [validationErrors, setValidationErrors] = useState({});
    const [note, setNote] = useState('');
    const [pinned, setPinned] = useState(false);

    const validateForm = () => {
        let errors = {}
        if(!note || note === '') {
            errors.note = true
        }
        
        setValidationErrors(errors)

        return errors
    }

    const pushNote = async () => {
        if (Object.values(validateForm()).includes(true)) {
            dispatch({
                type: ERROR,
                error: {response: {data: {
                    message: 'Please check the highlighted fields'
                }}}
            })
            return
        }

        const payload = {
            note,
            itemType: 'resident',
            item: resident._id,
            pinned
        }

        dispatch(createNote(payload))
    }

    return (
        <div className='w-full'>
            <div className='w-full mt-2'>
                <TextareaField
                    inputLabel="Note" 
                    fieldId="note" 
                    inputType="text" 
                    preloadValue={''}
                    inputPlaceholder={'Write your note here'}
                    hasError={validationErrors && validationErrors.description} 
                    returnFieldValue={(value)=>{setNote(value)}}
                />
            </div>

            <div className='flex items-start gap-x-3 mt-4'>
                <div className='w-full'>
                    <p className="font-[550] text-gray-700 text-sm mb-1">Pin this note</p>
                    <p className="font-[500] text-gray-500 text-xs mb-1">Pinned notes are displayed at the top of the notes list and on the residents overview page for easier reach.</p>

                </div>

                <div className='w-24 text-right'>
                    <Switch
                        checked={pinned}
                        onChange={()=>{setPinned(!pinned)}}
                        className={`${
                            pinned ? 'bg-doiteasy-blue' : 'bg-gray-200'
                        } relative inline-flex items-center h-5 rounded-full w-10`}
                        >
                        {/* <span className="sr-only">Display stock levels</span> */}
                        <span
                            className={`transform transition ease-in-out duration-200 ${
                                pinned ? 'translate-x-6' : 'translate-x-1'
                            } inline-block w-3 h-3 transform bg-white rounded-full`}
                        />
                    </Switch>
                </div>
            </div>

            <div className='w-full flex ml-auto items-center justify between gap-x-[10px] mt-[40px]'>
                <div className='w-max'>
                    <SecondaryButton
                        buttonLabel={`Cancel`} 
                        buttonAction={()=>{close()}} 
                        processing={false} 
                    />
                </div>
                <FormButton
                    buttonLabel={`Save Note`} 
                    buttonAction={()=>{pushNote()}} 
                    processing={notesSelector.creatingNote} 
                />
            </div>
        </div>
    )
}

export default NewResidentNote
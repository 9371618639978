import axios from "axios"
import { authHeader, baseUrl } from "../../utils"
import { CREATE_ROOM, CREATING_ROOM, GET_ROOMS, GETTING_ROOMS, ROOMS_ERROR, UPDATE_ROOM, UPDATING_ROOM } from "../types"

export const createRoom = (roomPayload) => async (dispatch) => {    
    try{
        const headers = authHeader()

        dispatch({
            type: CREATING_ROOM,
            payload: true
        })
        const response = await axios.post(`${baseUrl}/rooms`, roomPayload, { headers })
        
        dispatch({
            type: CREATE_ROOM,
            payload: response.data.data
        })
        
    }
    catch(error){
        console.log(error)
        dispatch({
            type: ROOMS_ERROR,
            error
        })
    }
}

export const updateRole = (roomId, roomPayload) => async (dispatch) => {    
    try{
        const headers = authHeader()

        dispatch({
            type: UPDATING_ROOM,
            payload: true
        })
        const response = await axios.patch(`${baseUrl}/rooms/${roomId}`, roomPayload, { headers })
        
        dispatch({
            type: UPDATE_ROOM,
            payload: response.data.data
        })
        
    }
    catch(error){
        console.log(error)
        dispatch({
            type: ROOMS_ERROR,
            error
        })
    }
}

export const fetchRooms = (filterString, page, perPage) => async (dispatch) => {    
    try{
        const headers = authHeader()

        let url = `${baseUrl}/rooms`
        if(filterString && filterString !== '') {
            url += `${url.includes('?') ? '&' : '?'}${filterString}`
        }

        if(page && page!=='') {
            url += `${url.includes('?') ? '&' : '?'}page=${page}`
        }

        if(perPage && perPage!=='') {
            url += `${url.includes('?') ? '&' : '?'}perPage=${perPage}`
        }

        dispatch( {
            type: GETTING_ROOMS,
            payload: true
        })

        const response = await axios.get(url, { headers })

        dispatch({
            type: GET_ROOMS,
            payload: response.data.data
        })
        
    }
    catch(error){
        dispatch( {
            type: ROOMS_ERROR,
            error
        })
    }
}

export const clearCreatedRoom = () => async (dispatch) => {    
    dispatch({
        type: CREATE_ROOM,
        payload: null
    })
}

export const clearUpdatedRoom = () => async (dispatch) => {    
    dispatch({
        type: UPDATE_ROOM,
        payload: null
    })
}

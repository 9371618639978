import React from 'react'
import Layout from '../../../components/layouts/Layout'
import CareHomeSettingsLayout from '../../../components/layouts/CareHomeSettingsLayout'
import { Outlet } from 'react-router-dom'

const CareHomeSettings = () => {
  return (
    <Layout pageTitle={`Care Home Settings`}>
        <CareHomeSettingsLayout>
            <Outlet />
        </CareHomeSettingsLayout>
    </Layout>
  )
}

export default CareHomeSettings
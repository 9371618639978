import React, { useState } from 'react'
import SecondaryButton from '../../elements/SecondaryButton'
import FormButton from '../../elements/form/FormButton'
import { useDispatch, useSelector } from 'react-redux'
import TextField from '../../elements/form/TextField'
import RadioGroup from '../../elements/form/RadioGroup'
import NumberField from '../../elements/form/NumberField'
import { ERROR } from '../../../store/types'
import { createResidentActivity } from '../../../store/actions/residentActivitiesActions'

const NewResidentActivity = ({resident, close}) => {
  const dispatch = useDispatch()
  const activitiesSelector = useSelector(state => state.residentActivities)
  const [activityPayload, setActivityPayload] = useState({
    frequency: {}
  });
  const [validationErrors, setValidationErrors] = useState({});

  const validateForm = () => {
    let errors = {}

    if(!activityPayload.activityType || activityPayload.activityType === '') {
      errors['activity-type'] = true
    }

    if(!activityPayload.activity || activityPayload.activity === '') {
      errors.activity = true
    }

    if(!activityPayload?.frequency?.type || activityPayload.frequency.type === '') {
      errors['frequency-type'] = true
    }
  
    if(!activityPayload?.frequency?.times || activityPayload.frequency.times === '') {
        errors['frequency-times'] = true
    }
   
    setValidationErrors(errors)

    return errors
  }

  const pushActivity = () => {
    if (Object.values(validateForm()).includes(true)) {
      dispatch({
          type: ERROR,
          error: {response: {data: {
              message: 'Please check the highlighted fields'
          }}}
        })
        return
      }

      const payload = {...activityPayload, ...{resident: resident._id}}
      dispatch(createResidentActivity(payload))
  }

  const frequencies = [
    {
        label: "Hourly",
        value: "hourly"
    },
    {
        label: "Daily",
        value: "daily"
    },
    {
        label: "Weekly",
        value: "weekly"
    },
    {
        label: "Monthly",
        value: "monthly"
    },
    {
        label: "Yearly",
        value: "yearly"
    },
  ]

  const activityTypes = [
    {
        label: "Sanitary",
        value: "sanitary"
    },
    {
        label: "Physical",
        value: "physical"
    },
    {
        label: "Medical",
        value: "medical"
    },
    {
        label: "Entertainment",
        value: "entertainment"
    }
  ]

  const updateFrequency = (field, value) => {
    let temp = {...activityPayload}
    temp.frequency[field] = value
    setActivityPayload(temp)
  }

  return (
    <div className='w-full'>

      <div className='w-full mt-2'>
        <RadioGroup
          items={activityTypes}
          inputLabel="Activity type"
          titleField="label"
          displayImage={false}
          imageField=""
          preSelectedIndex={frequencies.findIndex(item => item.value === activityPayload?.activityType)}
          // preSelectedIndex={null}
          fieldId={`activity-type`}
          requiredField={true}
          inline={true}
          hasError={validationErrors && validationErrors[`activity-type`]}
          showDescriptions={true}
          returnSelected={(value) => {setActivityPayload({...activityPayload, ...{activityType: value.value}})}}
        />
      </div>

      <div className='w-full mt-2'>
        <TextField
          inputLabel="Activity" 
          fieldId="activity" 
          inputType="text" 
          preloadValue={''}
          inputPlaceholder={'Name of the activity'}
          hasError={validationErrors && validationErrors.activity} 
          requiredField={true}
          returnFieldValue={(value)=>{setActivityPayload({...activityPayload, ...{activity: value}})}}
        />
      </div>

      <div className='w-full mt-2'>
        <TextField
            inputLabel="Description" 
            fieldId="medication" 
            inputType="text" 
            preloadValue={''}
            inputPlaceholder={'Please describe the activity'}
            hasError={false} 
            requiredField={false}
            returnFieldValue={(value)=>{setActivityPayload({...activityPayload, ...{description: value}})}}
        />
      </div>

      <h3 className='test-sm font-[500] mt-4'>Frequency</h3>

      <div className='w-full mt-2'>
        <RadioGroup
          items={frequencies}
          inputLabel="Frequency type"
          titleField="label"
          displayImage={false}
          imageField=""
          preSelectedIndex={frequencies.findIndex(item => item.value === activityPayload?.frequency?.type)}
          // preSelectedIndex={null}
          fieldId={`title`}
          requiredField={true}
          inline={true}
          hasError={validationErrors && validationErrors[`frequency-type`]}
          showDescriptions={true}
          returnSelected={(value) => {updateFrequency('type', value.value)}}
        />
      </div>

      <div className='w-full mt-2'>
          <NumberField 
              inputType="Times per frequency" 
              fieldId={`frequency`}
              inputLabel="Times in selected frequency" 
              requiredField={true}
              preloadValue={activityPayload?.frequency?.times || ''}
              hasError={validationErrors[`frequency-times`]} 
              returnFieldValue={(value)=>{updateFrequency('times', value)}}
          />
          <label className={`text-sm mt-1 lg:text-md cursor-text bg-transparent z-30 relative block py-1 mb-1 transition duration-200 text-gray-500`}>
              How many times will the activity be performed per the selected frequency type?
          </label>
      </div>
      <div className='w-full mt-2'>
          <TextField
              inputLabel="Additional notes for frequency" 
              fieldId="frequency-notes" 
              inputType="text" 
              preloadValue={''}
              inputPlaceholder={'Add some more information about frequency if required'}
              hasError={false} 
              requiredField={false}
              returnFieldValue={(value)=>{updateFrequency('instructions', value)}}
          />
      </div>

      <div className='w-full flex ml-auto items-center justify between gap-x-[10px] mt-[40px]'>
        <div className='w-max'>
          <SecondaryButton
            buttonLabel={`Cancel`} 
            buttonAction={()=>{close()}} 
            processing={false} 
          />
        </div>
        <FormButton 
          buttonLabel={`Create Activity`} 
          buttonAction={()=>{pushActivity()}} 
          processing={activitiesSelector.creatingResidentActivity} 
        />
      </div>
    </div>
  )
}

export default NewResidentActivity
import React, { useEffect, useState } from 'react'
import Layout from '../../../../components/layouts/Layout'
import ResidentProfileCard from '../../../../components/elements/residents/ResidentProfileCard'
import { useDispatch, useSelector } from 'react-redux'
import { authHeader, baseUrl } from '../../../../utils'
import { useParams } from 'react-router-dom'
import axios from 'axios'
import { ERROR, SET_SUCCESS } from '../../../../store/types'
import Preloader from '../../../../components/elements/Preloader'
import ResidentOverview from '../../../../components/partials/residents/ResidentOverview'
import ResidentMedications from '../../../../components/partials/residents/ResidentMedications'
import ResidentNotes from '../../../../components/partials/residents/ResidentNotes'
import ResidentReports from '../../../../components/partials/residents/ResidentReports'
import ResidentInvoicesPayments from '../../../../components/partials/residents/ResidentInvoicesPayments'
import { clearUpdatedResident } from '../../../../store/actions/residentsActions'
import ResidentVisitors from '../../../../components/partials/residents/ResidentVisitors'

const ResidentProfile = () => {
  const dispatch = useDispatch()
  const residentsSelector = useSelector(state => state.residents)
  const {residentId} = useParams()

  const [residentDetails, setResidentDetails] = useState({});
  const [Loading, setLoading] = useState(true);
  const [doReload, setDoReload] = useState(0);

  useEffect(() => {
    
    const fetchResidentDetails = async () => {    
      try{
        const headers = authHeader()
        const url = `${baseUrl}/residents/${residentId}?expand=room`

        const response = await axios.get(url, { headers })

        setResidentDetails(response.data.data)

        setLoading(false)
      }
      catch(error){
        console.log(error)
        dispatch( {
            type: ERROR,
            error
        })
      }
    }

    fetchResidentDetails()

    if(residentsSelector.updatedResident && residentsSelector.updatedResident !== null) {
      dispatch({
        type: SET_SUCCESS,
        payload: 'Updated successfully!'
      })
      dispatch(clearUpdatedResident())
    }

    return () => {
      
    };
  }, [dispatch, residentId, residentsSelector.updatedResident, doReload]);

  const residentTabs = [
    {
      label: 'Overview',
      value: 'overview'
    },
    {
      label: 'Prescriptions',
      value: 'prescriptions'
    },
    {
      label: 'Carer Reports',
      value: 'reports'
    },
    {
      label: 'Notes',
      value: 'notes'
    },
    {
      label: 'Visitors',
      value: 'visitors'
    },
    {
      label: 'Invoices & Payments',
      value: 'invoices-payments'
    },
  ]

  const [activeTab, setActiveTab] = useState('overview');
  
  return (
    <Layout pageTitle="Overview">
      {Loading ? 
        <Preloader preloadingText={`Loading resident's profile`} />
        :
        <>
          <div className='w-full bg-white flex items-start gap-x-[10px] rounded-[20px]'>
            <div className='bg-white rounded-[10px] p-[20px] min-h-[80vh] h-inherit w-[400px]'>
              <ResidentProfileCard resident={residentDetails} />
            </div>
            <div className='w-full border-l-4 border-gray-50 p-[20px]'>
              <div className='h-[50px] w-full flex items-center'>
                  {residentTabs.map((tab, tabIndex)=>(<button key={tabIndex} onClick={()=>{setActiveTab(tab.value)}} className={`text-sm nav-button px-3 py-3 font-space-grotesk font-[550] hover:bg-doiteasy-light-gray hover:bg-opacity-50 transition duration-200 ${activeTab === tab.value ? 'border-b-2 border-doiteasy-black bg-doiteasy-light-gray text-doiteasy-black' : 'border-b border-gray-300 text-gray-600'}`}>{tab.label}</button>))}
              </div>
              <div className={`mt-[20px] min-h-screen`}>
                {activeTab === 'overview' && <ResidentOverview resident={residentDetails} reload={()=>{setDoReload(doReload+1)}}/>}
                {activeTab === 'prescriptions' && <ResidentMedications resident={residentDetails} />}
                {activeTab === 'notes' && <ResidentNotes resident={residentDetails} />}
                {activeTab === 'reports' && <ResidentReports resident={residentDetails} />}
                {activeTab === 'invoices-payments' && <ResidentInvoicesPayments resident={residentDetails} />}
                {activeTab === 'visitors' && <ResidentVisitors resident={residentDetails} />}
              </div>
            </div>
          </div>
        </>
      }
    </Layout>
  )
}

export default ResidentProfile
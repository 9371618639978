import axios from "axios"
import { authHeader } from "../../utils"
import { GETTING_STAFF, GET_STAFF, ONBOARDING_STAFF, ONBOARD_STAFF, RESENDING_INVITATION, RESEND_INVITATION, REVOKE_INVITATION, REVOKING_INVITATION, STAFF_ERROR } from "../types"

export const onboardStaff = (staffPayload) => async (dispatch) => {    
    try{
        const headers = authHeader()

        dispatch({
            type: ONBOARDING_STAFF,
            payload: true
        })
        const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/auth/invitations`, staffPayload, { headers })
        
        dispatch({
            type: ONBOARD_STAFF,
            payload: response.data.data
        })
        
    }
    catch(error){
        // console.log(error)
        dispatch({
            type: STAFF_ERROR,
            // payload: error.response.data
            error
        })
    }
}

export const resendStaffInvitation = (id) => async (dispatch) => {    
    try{
        const headers = authHeader()

        dispatch({
            type: RESENDING_INVITATION,
            payload: id
        })
        const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/auth/invitations/resend/${id}`, { headers })
        
        dispatch({
            type: RESEND_INVITATION,
            payload: response.data.data
        })
        
    }
    catch(error){
        // console.log(error)
        dispatch({
            type: STAFF_ERROR,
            // payload: error.response.data
            error
        })
    }
}

export const revokeStaffInvitation = (id) => async (dispatch) => {    
    try{
        const headers = authHeader()

        dispatch({
            type: REVOKING_INVITATION,
            payload: id
        })
        const response = await axios.delete(`${process.env.REACT_APP_BASE_URL}/auth/invitations/revoke/${id}`, { headers })
        
        dispatch({
            type: REVOKE_INVITATION,
            payload: response.data.data
        })
        
    }
    catch(error){
        // console.log(error)
        dispatch({
            type: STAFF_ERROR,
            // payload: error.response.data
            error
        })
    }
}

export const fetchStaff= (filters, page, perPage) => async dispatch => {    
    try{
        const headers = authHeader()
        let requestUrl = 'staff?expand=department,userProfile,role,createdBy,invitation'

        dispatch( {
            type: GETTING_STAFF,
            payload: true
        })

        if(filters && filters !== '') {
            requestUrl += `${requestUrl.includes('?') ? '&' : '?'}${filters}`
        }

        if(page && page !== '') {
            requestUrl += `${requestUrl.includes('?') ? '&' : '?'}page=${page}`
        }

        if(perPage && perPage !== '') {
            requestUrl += `${requestUrl.includes('?') ? '&' : '?'}perPage=${perPage}`
        }

        const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/${requestUrl}`, { headers })

        dispatch( {
            type: GET_STAFF,
            payload: response.data.data
        })
        
    }
    catch(error){
        dispatch( {
            type: STAFF_ERROR,
            // payload: error.response.data,
            error
        })
    }
}

// export const fetchStaffInvitations= (filters, page, perPage) => async dispatch => {    
//     try{
//         const headers = authHeader()
//         let requestUrl = 'auth/invitations?expand=designation,department,userProfile'


//         dispatch( {
//             type: GETTING_STAFF,
//             payload: true
//         })

//         if(filters && filters !== '') {
//             requestUrl += `${requestUrl.includes('?') ? '&' : '?'}${filters}`
//         }

//         if(page && page !== '') {
//             requestUrl += `${requestUrl.includes('?') ? '&' : '?'}${page}`
//         }

//         if(perPage && perPage !== '') {
//             requestUrl += `${requestUrl.includes('?') ? '&' : '?'}${perPage}`
//         }

//         const response = await axios.get(`${process.env.REACT_APP_API_URL}/${requestUrl}`, { headers })

//         dispatch( {
//             type: GET_STAFF,
//             payload: response.data.data.employees
//         })
        
//     }
//     catch(error){
//         dispatch( {
//             type: STAFF_ERROR,
//             // payload: error.response.data,
//             error
//         })
//     }
// }

export const clearOnboardedStaff = () => async dispatch => {
    dispatch({
        type: ONBOARD_STAFF,
        payload: null
    })
}

export const clearResentInvitation = () => async dispatch => {
    dispatch({
        type: RESEND_INVITATION,
        payload: null
    })
}

export const clearRevokedInvitation = () => async dispatch => {
    dispatch({
        type: REVOKE_INVITATION,
        payload: null
    })
}

import React from 'react'

const ThumbtackIcon = ({className}) => {
  return (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 24"
    className={className}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="M9.803 13.953h5.363a1.57 1.57 0 0 0 1.3-1.223c.168-.6.012-1.243-.41-1.7-.628-.573-1.456-1.13-1.456-1.964V6.012A1.027 1.027 0 0 0 13.558 5h-2.084a1.027 1.027 0 0 0-1.042 1.012v3.054c0 .838-.827 1.391-1.455 1.967a1.79 1.79 0 0 0-.41 1.7 1.5 1.5 0 0 0 1.236 1.22"
      clipRule="evenodd"
    ></path>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeWidth="1.5"
      d="M12.484 13.953V19"
    ></path>
  </svg>
  )
}

export default ThumbtackIcon
import { CREATE_ROOM, CREATING_ROOM, GET_ROOMS, GETTING_ROOMS, ROOMS_ERROR, UPDATE_ROOM, UPDATING_ROOM } from "../types"

const initialState = {
    rooms: [],
    loadingRooms: true,
    roomsError: null,
    creatingRoom: false,
    createdRoom: null,
    updatingRoom: false,
    updatedRoom: null
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function(state = initialState, action){

    switch(action.type){
        case GETTING_ROOMS:
        return {
            ...state,
            loadingRooms :action.payload,
        }
        case GET_ROOMS:
        return{
            ...state,
            loadingRooms:false,
            roomsError:  null,
            rooms: action.payload,
        }
        case CREATING_ROOM:
        return {
            ...state,
            creatingRoom :action.payload,
        }
        case CREATE_ROOM:
        return{
            ...state,
            creatingRoom:false,
            roomsError:  null,
            createdRoom: action.payload,
        }
        case UPDATING_ROOM:
        return {
            ...state,
            creatingRoom :action.payload,
        }
        case UPDATE_ROOM:
        return{
            ...state,
            updatingRoom:false,
            roomsError:  null,
            updatedRoom: action.payload,
        }
        case ROOMS_ERROR:
        return{
            ...state,
            loadingRooms:false,
            creatingRoom:false,
            updatingRoom:false,
            roomsError: action.payload 
        }
        default: return state
    }

}
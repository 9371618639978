import React, { useEffect } from 'react'
import PrimaryButton from '../../elements/PrimaryButton'
import PlusWhite from '../../../assets/images/icons/plus.svg';
import { useDispatch, useSelector } from 'react-redux';
import Preloader from '../../elements/Preloader';
import EmptyState from '../../elements/EmptyState';
import { useState } from 'react';
import Pagination from '../../elements/Pagination';
import ModalDialog from '../../layouts/ModalDialog';
import { ERROR, GETTING_NOTES, SET_SUCCESS } from '../../../store/types';
import NewResidentNote from './NewResidentNote';
import { clearCreatedNote, clearUpdatedNote, fetchNotes } from '../../../store/actions/notesActions';
import ResidentNote from '../../elements/residents/ResidentNote';
import { authHeader, baseUrl } from '../../../utils';
import axios from 'axios';

const ResidentNotes = ({resident}) => {
  const dispatch = useDispatch()
  const notesSelector = useSelector(state => state.notes)
  // eslint-disable-next-line no-unused-vars
  const [filters, setFilters] = useState('pinned=false');
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(25);
  const [reload, setReload] = useState(0);

  // const [fetchingPinnedNotes, setFetchingPinnedNotes] = useState(true);
  const [pinnedNotes, setPinnedNotes] = useState([]);
  const [activeNote, setActiveNote] = useState(null);
  const [readingNote, setReadingNote] = useState(false);

  const openNote = (note) => {
    setActiveNote(note)
    setTimeout(() => {
      setReadingNote(true)
    }, 100);
  }

  const closeNote = () => {
    setReadingNote(false)
    setTimeout(() => {
      setActiveNote(null)
    }, 100);
  }

  useEffect(() => {
    dispatch({
        type: GETTING_NOTES,
        payload: true
    })
    dispatch(fetchNotes(resident._id, filters, page, perPage))
    
    const fetchPinnedNotes = async () => {
      try{
        const headers = authHeader()
        const url = `${baseUrl}/notes?item=${resident._id}&pinned=true&expand=createdBy`

        const response = await axios.get(url, { headers })

        setPinnedNotes(response.data.data.notes)

        // setFetchingPinnedNotes(false)
      }
      catch(error){
        console.log(error)
        dispatch( {
            type: ERROR,
            error
        })
      }
    }

    fetchPinnedNotes()

    if (notesSelector.createdNote && notesSelector.createdNote !== null) {
        dispatch(clearCreatedNote())
        dispatch({
            type: SET_SUCCESS,
            payload: 'Note created successfully!'
        })
        setCreatingNote(false)
    }

    if (notesSelector.updatedNote && notesSelector.updatedNote !== null) {
        dispatch(clearUpdatedNote())
        // dispatch({
        //     type: SET_SUCCESS,
        //     payload: 'Note created successfully!'
        // })
        closeNote()
    }
    return () => {
        
    };
  }, [dispatch, filters, page, perPage, notesSelector.createdNote, reload, resident._id, notesSelector.updatedNote]);

  const [creatingNote, setCreatingNote] = useState(false);
  return (
    <>
      <div>
          <div className='w-full flex items-start justify-between mb-[20px]'>
              <div className='w-2/3'>
                  <h3 className='text-doiteasy-black font-[550]'>Resident Notes</h3>
                  <p className='text-sm text-gray-500 font-[500]'>
                      View and manage notes created on this resident. You can pin important notes for faster access and also edit and delete notes than are no longer needed. Create a new note by clicking on "Add Note".
                  </p>
              </div>
              <div className='flex flex-row-reverse'>
                  <PrimaryButton label={`Add Note`} icon={PlusWhite} buttonAction={()=>{setCreatingNote(true)}} />
              </div>
          </div>

          {pinnedNotes.length > 0 && 
            <div className='w-full pb-[20px] mb-[20px] border-b border-gray-100'>
              <div className='w-full'>
                <h3 className='text-doiteasy-black font-[550] text-sm border-b w-max pb-[5px] border-gray-500'>Pinned Notes</h3>

                <div className='w-full grid lg:grid-cols-2 xl:grid-cols-3 gap-[10px] mt-[20px]'>
                    {pinnedNotes?.map((note, noteIndex) => (
                      <div key={noteIndex} className='w-full mb-[20px]'>
                        <ResidentNote note={note} openNote={()=>{openNote(note)}} resident={resident} reload={()=>{setReload(reload+1)}} />
                      </div>
                    ))}
                </div>
              </div>
            </div>
          }

          <div className='w-full'>
              {notesSelector?.loadingNotes ? 
                  <Preloader preloadingText={`Loading resident notes`} />
                  :
                  <>
                  <div className='w-full'>
                      {notesSelector?.notes?.notes?.length > 0 ?
                          <div className='w-full'>
                              <div className='w-full grid lg:grid-cols-2 xl:grid-cols-3 gap-[10px]  mt-[40px]'>
                                  {notesSelector?.notes?.notes?.map((note, noteIndex) => (
                                      <div key={noteIndex} className='w-full mb-[20px]'>
                                          <ResidentNote openNote={()=>{openNote(note)}} note={note} resident={resident} reload={()=>{setReload(reload+1)}} />
                                      </div>
                                  ))}
                              </div>
                              <Pagination 
                                  pagination={{
                                      page,
                                      perPage,
                                      totalItems: notesSelector?.notes?.total
                                  }} 
                                  changePage={(page)=>{setPage(page)}} 
                                  updatePerPage={(perPage)=>{setPerPage(perPage)}} 
                              />
                          </div>
                          :
                          <div className='w-full'>
                              <div className='mt-[50px] w-[500px] mx-auto'>
                                  <EmptyState  
                                  emptyStateTitle={`No notes added for this resident yet.`}
                                  emptyStateText={`You can create new note for this resident by clicking on "Add Note" button above.`}
                                  />
                              </div>
                          </div>
                      }
                      </div>
                  </>
              }
          </div>
      </div>

      <ModalDialog
          shown={creatingNote} 
          closeFunction={()=>{setCreatingNote(false)}} 
          actionFunction={()=>{}} 
          actionFunctionLabel={``}
          dialogTitle={<span className='capitalize font-space-grotesk'>Create a new note on {resident.title} {resident.firstName} {resident.lastName}</span>}
          maxWidthClass='max-w-lg'
          hideActions={true}
      >
          <NewResidentNote 
              resident={resident}
              close={()=>{setCreatingNote(false)}}
          />
      </ModalDialog>

      {activeNote !== null && <ModalDialog
          shown={readingNote} 
          closeFunction={()=>{closeNote()}} 
          actionFunction={()=>{}} 
          actionFunctionLabel={``}
          dialogTitle={<span className='capitalize font-space-grotesk'>Note on {resident.title} {resident.firstName} {resident.lastName}</span>}
          maxWidthClass='max-w-lg'
          hideActions={true}
      >
          <ResidentNote 
              resident={resident}
              note={activeNote}
              close={()=>{closeNote()}}
              state='full'
          />
      </ModalDialog>}
  </>
  )
}

export default ResidentNotes
import { CREATE_NOTE, CREATING_NOTE, GET_NOTES, GETTING_NOTES, NOTES_ERROR, UPDATE_NOTE, UPDATING_NOTE } from "../types"

const initialState = {
    notes: [],
    loadingNotes: true,
    notesError: null,
    creatingNote: false,
    createdNote: null,
    updatingNote: false,
    updatedNote: null,
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function(state = initialState, action){

    switch(action.type){
        case GETTING_NOTES:
        return {
            ...state,
            loadingNotes :action.payload,
        }
        case GET_NOTES:
        return{
            ...state,
            loadingNotes:false,
            notesError:  null,
            notes: action.payload,
        }
        case CREATING_NOTE:
        return {
            ...state,
            creatingNote: action.payload,
        }
        case CREATE_NOTE:
        return{
            ...state,
            creatingNote:false,
            notesError:  null,
            createdNote: action.payload,
        }
        case UPDATING_NOTE:
        return {
            ...state,
            updatingNote: action.payload,
        }
        case UPDATE_NOTE:
        return{
            ...state,
            updatingNote:false,
            notesError:  null,
            updatedNote: action.payload,
        }
        case NOTES_ERROR:
        return{
            ...state,
            loadingNotes:false,
            creatingNote:false,
            updatingNote:false,
            notesError: action.payload 
        }
        default: return state
    }

}
import React from 'react'
import ActivityTypePill from '../ActivityTypePill'

const ActivityCard = ({activity}) => {
  return (
    <div className='w-full flex items-start justify-between gap-x-[20px] pb-[10px]'>
        <div className='w-full'>
            <h3 className='font-[500] font-space-grotesk text-doiteasy-black text-[15px] leading-[18px] mb-[5px]'>{activity?.activity}</h3>
            <p className='font-urbanist text-[14px] text-[#828282] leading-[18px]'>{activity?.description} - <span className='text-black font-[500]'>{activity?.frequency.times}x {activity?.frequency.type}</span></p>
            <p className='font-urbanist text-[14px] text-doiteasy-black font-medium leading-[18px]'>{activity?.caregiverName}</p>
        </div>
        <div>
            <ActivityTypePill activityType={activity?.activityType} />
        </div>
    </div>
  )
}

export default ActivityCard
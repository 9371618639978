import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import Loader from '../../../../components/elements/Loader'
import DataTable from '../../../../components/elements/DataTable'
import { tableHeadersFields, transactionTimeStamp } from '../../../../utils'
import EmptyState from '../../../../components/elements/EmptyState'
import { fetchRoles } from '../../../../store/actions/rolesPermissionsActions'
// import FiltersV2 from '../../../../components/elements/FiltersV2'
import PrimaryButton from '../../../../components/elements/PrimaryButton'
import PlusWhite from '../../../../assets/images/icons/plus.svg';

const Roles = () => {
  const dispatch = useDispatch()
  const rolesSelector = useSelector((state => state.roles))
  const navigate = useNavigate()
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(25);
  // const [filters, setFilters] = useState('');

  useEffect(() => {
    dispatch(fetchRoles('', page, perPage))
    // dispatch(fetchFees('', 0, 0))
    // dispatch(fetchAcademicSessions('', 0, 0))
    return () => {
      
    };
  }, [page, perPage, dispatch]);

  const tableOptions = {
    selectable: false,
    multiselect: false,
    clickableRows: true,
    rowAction: (rowIndex)=>{
      navigate(`role/${rolesSelector.roles?.roles[rowIndex]._id}`)
    }
  }

  const columnWidths = {
    role: 'w-6/12',
    createdBy: 'w-2/12',
    permissions: 'w-2/12',
    dateCreated: 'w-2/12',
  }

  const columnDataStyles = {}

  const cleanUpTableData = (array) => {
    if(!array) return
    let finalResult = [] 
    array.forEach((object, objectIndex) => {
        const applicationObject = {
          role: <div className='w-[500px]'>
            <h3 className='text-[15px] font-[500] mb-[3px]'>{object?.name}</h3>
            <p className='text-sm font-[500] truncate ...'>{object?.description}</p>
          </div>,
          createdBy: object.createdBy?.name,
          permissions: <p>{object?.permissions?.length} permissions</p>,
          dateCreated: transactionTimeStamp(object?.createdAt)?.date,
        }

        finalResult.push(applicationObject)
    })
    return finalResult
  }

  return (
    <div className='w-full'>
          <div className='w-full flex justify-between mb-[40px]'>
            <div>
              <h3 className='text-lg font-medium'>Roles</h3>
              <p className='text-sm'>All user roles on this platform. Assign roles to users to specify what they can do on the platform</p>
            </div>
            <div>
            <Link to={`new-role`}>
              <PrimaryButton label={`Create a new role`} icon={PlusWhite} />
            </Link>

            </div>
          </div>

          {/* <div className='w-full flex items-center justify-between my-5 p-2 rounded-md border'>
            <div className='w-full'>
              <FiltersV2 />
            </div>
          </div> */}

          {rolesSelector?.loadingRoles ? 
            <Loader />
            :
            <>
              {rolesSelector?.roles?.roles?.length > 0 ? <div className=''>
                <div className='w-full'>
                  <DataTable
                    tableHeaders={tableHeadersFields(cleanUpTableData(rolesSelector?.roles?.roles)[0]).headers} 
                    tableData={cleanUpTableData(rolesSelector?.roles?.roles)} 
                    columnWidths={columnWidths}
                    columnDataStyles={columnDataStyles}
                    allFields={tableHeadersFields(cleanUpTableData(rolesSelector?.roles?.roles)[0]).fields}
                    onSelectItems={()=>{}}
                    tableOptions={tableOptions}
                    pagination={{
                        perPage, 
                        page,
                        totalItems: rolesSelector.roles.total,
                    }}
                    onSelectSingle={()=>{}}
                    changePage={setPage}
                    updatePerPage={setPerPage}
                  />
                </div>
              </div>
                : 
                <div className='mt-24 w-1/2 mx-auto'>
                  <EmptyState emptyStateText={`There are no roles yet! Click on "create new role" to start creating roles`} />
                </div>

              }
            </>
          }
        </div>
  )
}

export default Roles
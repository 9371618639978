import React, { useEffect, useState } from 'react'
import { authHeader, calculateAge, transactionTimeStamp } from '../../../utils'
import AvatarFemale from '../../../assets/images/resident-placeholder-female.jpg'
import AvatarMale from '../../../assets/images/resident-placeholder-male.jpg'
import ResidentCheckInStatus from './ResidentCheckInStatus'
import CareTypePill from './CareTypePill'
import moment from 'moment'
import ArrowPathIcon from '../icons/ArrowPathIcon'
import FileUpload from '../form/FileUpload'
import CloseIcon from '../icons/CloseIcon'
import { useDispatch, useSelector } from 'react-redux'
import { ERROR, UPDATING_RESIDENT } from '../../../store/types'
import { updateResident } from '../../../store/actions/residentsActions'
import ModalDialog from '../../layouts/ModalDialog'
import ChangeCareType from '../../partials/residents/ChangeCareType'
import Caregiver1 from '../../../assets/images/caregivers/caregiver1.webp'
import Caregiver2 from '../../../assets/images/caregivers/caregiver2.webp'
import { BellIcon } from '@heroicons/react/solid'
const ResidentProfileCard = ({resident}) => {
    const bgImage = () => {
        if(resident.picture && resident.picture !== ''){
            return resident.picture
        } else if (resident.gender === 'female') {
            return AvatarFemale
        } else if (resident.gender === 'male') {
            return AvatarMale
        }
    }

    const dispatch = useDispatch()
    const residentsSelector = useSelector(state => state.residents)

    useEffect(() => {
        if(residentsSelector.updatedResident && residentsSelector.updatedResident !== null) {
            setChangingPicture(false)
            setChangingCareType(false)
        }
        return () => {
            
        };
    }, [residentsSelector.updatedResident]);

    const [changingPicture, setChangingPicture] = useState(false);

    const [file, setFile] = useState(null);

    const handleUpload = async (file) => {
        console.log(file)
    
        var formData = new FormData()
        formData.append('file', file.file )
        const headers = new Headers();
        headers.append("Authorization",  authHeader().Authorization);

    
        try {
            dispatch({
                type: UPDATING_RESIDENT,
                payload: true
            })
            const doUpload = await fetch(`${process.env.REACT_APP_BASE_URL}/files/new`, {
                method: "POST",
                headers,
                body: formData,
            });
            const response = await doUpload.json();
    
            return response
        } catch (error) {
            dispatch({
                type: ERROR,
                error,
            });
        }
    }

    const pushUpdate = async (newCareType) => {

        const payload = {}

        if(file) {
            const uploaded = await handleUpload(file) 
            payload.picture = uploaded.data.file
        }

        if(newCareType && newCareType !== ''){
            payload.careTypeRequired = newCareType
        }

        dispatch(updateResident(resident._id, payload))
    }

    const [changingCareType, setChangingCareType] = useState(false);

    const assignedCarers = [
        {
            name: 'Grace Majors',
            avatar: Caregiver1,
            // department: 'Administration',
            shiftTime: '11:00PM - 7:00AM'
        },
        {
            name: 'Mabel Oluchi Okonkwo',
            avatar: Caregiver2,
            // department: 'Nursing/Med. Care',
            shiftTime: '11:00PM - 7:00AM'
        },
    ]

    return (
        <>
            <div>
                <div className='w-full'>
                    {changingPicture ? 
                        <>
                            <FileUpload
                                hasError={false}
                                fieldLabel={`Set Resident's Picture`}
                                returnFileDetails={(details)=>{
                                    setFile(details)
                                }}
                                acceptedFormats={['jpeg', 'jpg', 'png']}
                            />
                            {/* <label className='block mt-2 text-xs text-gray-400'>{`Add a picture of the resident if available`}</label> */}
                            {file && <button onClick={()=>{pushUpdate()}} className={`text-sm nav-button mb-2 px-1 py-1 font-space-grotesk font-[550] border-b-2 border-doiteasy-black text-doiteasy-black text-gray-600'}`}>
                                {residentsSelector.updatingResident ? 'Saving...' : 'Save Photo'}
                            </button>}
                        </>
                    
                    :
                    
                        <div className='w-full h-[300px] rounded-[8px] bg-gray-50 border-4 border-doiteasy-light-gray' style={{
                            backgroundImage: `url(" ${bgImage()} ")`,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center center',
                            backgroundRepeat: 'none'
                        }} />
                    }
                    <div className='flex flex-row-reverse mt-[5px]'>
                        {changingPicture ? 
                        <button onClick={()=>{setChangingPicture(!changingPicture)}} className='text-xs flex items-center gap-x-[5px]'><CloseIcon className={`w-4 h-4`} />Cancel</button>
                        :
                        <button onClick={()=>{setChangingPicture(!changingPicture)}} className='text-xs flex items-center gap-x-[5px]'><ArrowPathIcon className={`w-4 h-4`} />Change Picture</button>
                        }
                    </div>
                </div>

                <div className='mt-[5px]'>
                    <h3 className='text-[16px] font-space-grotesk text-doiteasy-black font-[550]'>{resident.firstName}{resident.otherNames && resident.otherNames !== '' ? resident.otherNames : ' '}{resident.lastName}</h3>
                    <div className='flex items-center gap-x-[5px] mt-[5px]'>
                        <p className='text-sm text-gray-500 font-[500]'>{calculateAge(resident.dob)} years old</p>
                        <span className='block rounded-full bg-doiteasy-black w-[4px] h-[4px]' />
                        <p className='text-sm capitalize text-gray-500 font-[500]'>{resident.gender}</p>
                        {/* <span className='block rounded-full bg-doiteasy-black w-[5px] h-[5px]' /> */}
                        {/* <p className='text-sm capitalize text-gray-500'>Room: {room && room!=='' ? room : 'pending'}</p>
                        <span className='block rounded-full bg-doiteasy-black w-[5px] h-[5px]' />
                        <p className='text-sm capitalize text-gray-500'>{careType} care</p> */}
                    </div>
                    <p className='text-sm text-gray-500 font-[500] mt-[5px]'>Date of Birth: {moment(resident.dob).format('DD MMM YYYY')}</p>

                    <div className='mt-[15px] flex items-center gap-x-[5px]'>
                        <CareTypePill careType={resident.careTypeRequired} />
                        <button onClick={()=>{setChangingCareType(true)}} className={`text-xs font-space-grotesk font-[550] text-gray-400 text-gray-600'}`}>Click to change</button>
                    </div>

                    <div className='mt-[15px] flex justify-between items-center gap-x-[5px]'>
                        <ResidentCheckInStatus checkedIn={resident.checkedIn} />
                        <p className='text-sm capitalize font-[500] text-gray-500'>{resident.room ? `Room: ${resident.room.name}` : ''}</p>
                    </div>
                    {resident.arrivalDate && <div className='mt-[15px] flex items-center gap-x-[5px]'>
                        <p className='text-sm capitalize font-[500] text-gray-500'>Arrived {transactionTimeStamp(resident.arrivalDate).date} @ {transactionTimeStamp(resident.arrivalDate).time}</p>
                    </div>}

                    {resident.allergies && resident.allergies.length > 0 && 
                    <div className='mt-[20px]'>
                        <h3 className='mb-[5px] font-[550] text-doiteasy-black text-sm'>Allergies</h3>
                        <div className='mt-[15px] flex items-center flex-wrap gap-x-[5px] gap-y-[5px]'>
                            {resident.allergies.map((allergy, allergyIndex) => (<span key={allergyIndex} className='text-xs capitalize rounded p-[5px] text-gray-600 bg-gray-100 font-[500]'>{allergy}</span>))}
                        </div>
                    </div>
                    }

                    {resident.preExistingConditions && resident.preExistingConditions.length > 0 && resident.preExistingConditions[0] !== '' &&
                    <div className='mt-[20px]'>
                        <h3 className='mb-[5px] font-[550] text-doiteasy-black text-sm'>Pre-existing Conditions</h3>
                        <div className='mt-[15px] flex items-center flex-wrap gap-x-[5px] gap-y-[5px]'>
                            {resident.preExistingConditions.map((condition, conditionIndex) => (<span key={conditionIndex} className='text-xs capitalize rounded p-[5px] text-gray-600 bg-gray-100 font-[500]'>{condition}</span>))}
                        </div>
                    </div>
                    }

                    <div className='mt-[20px]'>
                        <div className='flex items-center justify-between'>
                            <h3 className='mb-[5px] font-[550] text-doiteasy-black text-sm'>Assigned Carer(s)</h3>
                            <button onClick={()=>{}} className={`text-xs font-space-grotesk font-[550] text-gray-400 text-gray-600'}`}>Click to change</button>
                        </div>
                        {assignedCarers.map((caregiver, caregiverIndex)=>(<div key={caregiverIndex} className='w-full relative flex items-center justify-between gap-x-[10px] border-b pb-[10px] mt-[10px]'>
                            <div className='w-[52px]'>
                                <div className='w-[50px] h-[50px] rounded-full border-2 border-doiteasy-light-gray' style={{
                                    backgroundImage: `url(${caregiver?.avatar})`, 
                                    backgroundSize: 'cover',
                                    backgroundRepeat: 'no-repeat',
                                    backgroundPosition: 'center center'
                                }} />
                            </div>
                            <div className='w-full relative'>
                                <h3 className='font-[500] font-space-grotesk text-doiteasy-black text-sm'>{caregiver?.name}</h3>
                                <p className='font-urbanist text-[14px] text-gray-400 font-medium text-xs'>{caregiver?.shiftTime}</p>
                                <button className='absolute bottom-0 right-0 text-red-500 text-[12px] font-medium flex items-center gap-x-[5px]'><BellIcon className={`w-4 h-4`} /> Ping</button>
                            </div>
                        </div>))}

                    </div>
                </div>
            </div>

            <ModalDialog
                shown={changingCareType} 
                closeFunction={()=>{setChangingCareType(false)}} 
                actionFunction={()=>{}} 
                actionFunctionLabel={``}
                dialogTitle={<span className='capitalize font-space-grotesk'>Change care type for {resident.title} {resident.firstName} {resident.lastName}</span>}
                maxWidthClass='max-w-lg'
                hideActions={true}
            >
                <ChangeCareType 
                    resident={resident}
                    close={()=>{setChangingCareType(false)}}
                    updateCareType={(newCareType)=>{pushUpdate(newCareType)}}
                />
            </ModalDialog>
        </>
    )
}

export default ResidentProfileCard
import axios from "axios"
import { authHeader, baseUrl } from "../../utils"
import { CREATE_NOTE, CREATING_NOTE, GET_NOTES, GETTING_NOTES, NOTES_ERROR, UPDATE_NOTE, UPDATING_NOTE } from "../types"

export const createNote = (payload) => async (dispatch) => {    
    try{
        const headers = authHeader()

        dispatch({
            type: CREATING_NOTE,
            payload: true
        })
        const response = await axios.post(`${baseUrl}/notes`, payload, { headers })
        
        dispatch({
            type: CREATE_NOTE,
            payload: response.data.data
        })
        
    }
    catch(error){
        console.log(error)
        dispatch({
            type: NOTES_ERROR,
            error
        })
    }
}

export const clearCreatedNote = () => async (dispatch) => {    
    dispatch({
        type: CREATE_NOTE,
        payload: null
    })
}

export const updateNote = (noteId, payload) => async (dispatch) => {    
    try{
        const headers = authHeader()

        dispatch({
            type: UPDATING_NOTE,
            payload: true
        })
        const response = await axios.patch(`${baseUrl}/notes/${noteId}`, payload, { headers })
        
        dispatch({
            type: UPDATE_NOTE,
            payload: response.data.data
        })
        
    }
    catch(error){
        console.log(error)
        dispatch({
            type: NOTES_ERROR,
            error
        })
    }
}

export const clearUpdatedNote = () => async (dispatch) => {    
    dispatch({
        type: UPDATE_NOTE,
        payload: null
    })
}

export const fetchNotes = (itemId, filterString, page, perPage) => async (dispatch) => {    
    try{
        const headers = authHeader()

        let url = `${baseUrl}/notes?item=${itemId}&expand=createdBy`
        if(filterString && filterString !== '') {
            url += `${url.includes('?') ? '&' : '?'}${filterString}`
        }

        if(page && page!=='') {
            url += `${url.includes('?') ? '&' : '?'}page=${page}`
        }

        if(perPage && perPage!=='') {
            url += `${url.includes('?') ? '&' : '?'}perPage=${perPage}`
        }

        dispatch( {
            type: GETTING_NOTES,
            payload: true
        })

        const response = await axios.get(url, { headers })

        dispatch({
            type: GET_NOTES,
            payload: response.data.data
        })
        
    }
    catch(error){
        dispatch( {
            type: NOTES_ERROR,
            error
        })
    }
}

import React, { useEffect, useState } from 'react'
import DoiteasyLogo from '../components/elements/DoiteasyLogo'
import { useDispatch } from 'react-redux';
import TextField from '../components/elements/form/TextField';
import PasswordField from '../components/elements/form/PasswordField';
import Checkbox from '../components/elements/form/Checkbox';
import FormButton from '../components/elements/form/FormButton';
import ArrowIcon from '../components/elements/icons/ArrowIcon';
import { ERROR, SET_SUCCESS } from '../store/types';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import Preloader from '../components/elements/Preloader';
import FileUpload from '../components/elements/form/FileUpload';
import { authHeader } from '../utils';

const AcceptInvitation = () => {
    const [validationErrors, setValidationErrors] = useState({});
    const [userDetails, setUserDetails] = useState({});
    const [processing, setProcessing] = useState(false);
    const dispatch = useDispatch()
    const {invitationCode} = useParams()

    // const [activeStep, setActiveStep] = useState(0);
    // const [completedSteps, setCompletedSteps] = useState([]);

    const [acceptedUserTerms, setAcceptedUserTerms] = useState(false);

    // const steps = [
    //     {
    //         title: "Your Details",
    //         description: "Please provide details for your user account."
    //     },
    //     {
    //         title: "Documentation",
    //         description: "Provide your required documentation"
    //     },
    // ]

    const [loading, setLoading] = useState(true);
    const [invitation, setInvitation] = useState({});

    useEffect(() => {
        const getInvitation = async () => {
            try {
                const headers = {
                    'Content-Type': 'application/json'
                }
                
                const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/auth/invitations/${invitationCode}?expand=employeeProfile,careHome`, { headers }) 
                setInvitation(response.data.data.invitation)
                setUserDetails(response.data.data.invitation.employeeProfile)
                setLoading(false)

            } catch (error) {
                console.log(error)
                dispatch({
                    type: ERROR,
                    error
                })
                setProcessing(false)
            }
        }   

        getInvitation()

        return () => {
            
        };
    }, [dispatch, invitationCode]);

    const validateForm = () => {
        let errors = {}

        if(!userDetails.password || userDetails.password === '') {
            errors.password = true
        }

        if(!acceptedUserTerms) {
            errors.terms = true
        }

        if (invitation.requiredDocumentUploads && invitation.requiredDocumentUploads.length > 0 && (!files || files.length < invitation.requiredDocumentUploads.length ) ) {
            errors.files = true
        } else {
            files.forEach((file, fileIndex) => {
                if(!file.file || file.file === '') {
                    errors[`file-${fileIndex}`] = true
                }
            })
        }



        setValidationErrors(errors)
        return errors
    }

    const navigate = useNavigate()
    const acceptInvitation = async () => {
        if (Object.values(validateForm()).includes(true)) {

            dispatch({
                type: ERROR,
                error: {response: {data: {
                    message: 'Please check the highlighted fields'
                }}}
            })
            return
        }
        try {
            // const headers = {
            //     'Content-Type': 'application/json'
            // }
            const headers = authHeader()

            const requestPayload = {
                invitationCode,
                password: userDetails.password
            }

            setProcessing(true)

            let uploadedDocuments = []
			for (let index = 0; index < files.length; index++) {
				const element = files[index];
				const uploaded = await handleUpload(element.file)
	
				uploadedDocuments.push({
					documentName: element.document.documentName,
					slug: element.document.slug,
                    description: element.document.description,
					url: uploaded.data.file
				})
			}

            if(uploadedDocuments.length > 0) {
                requestPayload.document = uploadedDocuments
            }
            
            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/auth/invitations/${invitationCode}`, requestPayload, { headers }) 
            setProcessing(false)
            dispatch({
                type: SET_SUCCESS,
                payload: response.data.data.message
            })
            navigate('/')
            
        } catch (error) {
            console.log(error)
            dispatch({
                type: ERROR,
                error
            })
            setProcessing(false)
        }

    }

    const [files, setFiles] = useState([]);


	const addFile = (doc, file) => {
		let tempFiles = [...files]
		tempFiles.push({
			document: doc,
			file: file
		})
		setFiles(tempFiles)
	}
  
	const handleUpload = async (file) => {
	  console.log(file)

	  var formData = new FormData()
	  formData.append('file', file.file )
	  const headers = new Headers();

	  try {
  
		  const doUpload = await fetch(`${process.env.REACT_APP_BASE_URL}/files/new`, {
			  method: "POST",
			  headers,
			  body: formData,
		  });
		  const response = await doUpload.json();
  
		 return response
	  } catch (error) {
		  dispatch({
			  type: ERROR,
			  error,
		  });
	  }
  	}

    return (
        <div>
            <div className="w-full min-h-screen flex pt-[20px] justify-center bg-doiteasy-light-gray">
                <div className="w-5/12">
                    <DoiteasyLogo />

                    {loading ? 
                        <Preloader preloadingText={`Loading invitation`} />
                        :
                    <>
                        <h3 className="mt-[10px] font-medium text-gray-700 text-xl mb-1 text-center">Welcome {invitation.employeeProfile.firstName} {invitation.employeeProfile.lastName}.</h3>
                        <p className="text-sm text-opacity-80 text-center">You have been invited to join the care home <span className='font-[550] uppercase'>{invitation.careHome.name}</span> on Doiteasy as a/an {invitation.employeeProfile.designation}. Please provide the required information below to activate your account and proceed to the platform.</p>

                        <div className='rounded-[32px] bg-white px-[40px] py-[40px] mt-[20px]'>
                            <h3 className=" mb-[5px] font-[550] tracking-[0.01em] text-gray-600">Your Login Information</h3>
                            <p className="text-sm mb-[10px] font-[550] tracking-[0.01em] text-gray-400">Please create a password for your user account</p>


                                <div className='mt-4'>
                                    <TextField
                                        inputLabel="Your email address" 
                                        fieldId="email-address" 
                                        inputType="text" 
                                        preloadValue={userDetails.email || ''}
                                        inputPlaceholder={'Active email address'}
                                        hasError={validationErrors && validationErrors.firstName} 
                                        disabled={true}
                                        returnFieldValue={(value)=>{setUserDetails({...userDetails, ...{email: value}})}}
                                    />
                                    <label className='block mt-2 text-sm text-gray-400'>This is the email address you will always use to log in</label>
                                </div>

                                <div className='mt-4'>
                                    <PasswordField
                                        inputLabel="Password" 
                                        fieldId="password" 
                                        inputType="password" 
                                        inputPlaceholder={'Choose a secure password'}
                                        preloadValue={''}
                                        hasError={validationErrors && validationErrors.password} 
                                        returnFieldValue={(value)=>{setUserDetails({...userDetails, ...{password: value}})}}
                                    />
                                </div>

                                {invitation.requiredDocumentUploads && invitation.requiredDocumentUploads.length > 0 && 
                                    <>
                                        <hr className='mt-[30px] mb-[15px] bg-gray-400' />
                                        <h3 className=" mb-[5px] mt-[15px] font-[550] tracking-[0.01em] text-gray-600">Documentation</h3>
                                        <p className={`text-sm mb-[10px] font-[550] tracking-[0.01em] ${validationErrors.files ? 'text-red-500':'text-gray-400'}`}>{invitation.careHome.name} needs you to provide the following documents before you proceed</p>

                                        {invitation.requiredDocumentUploads.map((doc, docIndex)=>(
                                            <div key={docIndex} className='mt-3 w-full'>
                                                <FileUpload
                                                    hasError={validationErrors[`file-${docIndex}`]}
                                                    fieldLabel={doc.documentName}
                                                    returnFileDetails={(details)=>{
                                                        addFile(doc, details)
                                                    }}
                                                    acceptedFormats={['pdf', 'jpeg', 'jpg', 'png']}
                                                />
                                                <label className='block mt-3 text-xs text-gray-400'>{doc.description}</label>
                                            </div>
                                        ))}
                                    </>
                                
                                }

                                <div className='w-full mt-8'>
                                    <Checkbox
                                        CheckboxLabel="I have read and agree with the Terms & Conditions & Privacy Policy of Doiteasy"
                                        checkboxToggleFunction={()=>{
                                            setAcceptedUserTerms(!acceptedUserTerms)
                                        }} 
                                        isChecked={acceptedUserTerms} 
                                        hasError={validationErrors.terms} 
                                    />
                                </div>
                                
                                <div className='mt-8 w-[60%] mx-auto'>
                                    <FormButton 
                                        buttonLabel={
                                            <span className='flex items-center justify-center gap-x-[20px] text-white font-space-grotesk font-[550]'>
                                                Continue
                                                <ArrowIcon className={`w-5 h-5`} />
                                            </span>
                                        } 
                                        buttonAction={()=>{acceptInvitation()}} 
                                        processing={processing} />
                                </div>
                            </div>
 
                        </>}

                </div>
            </div>
        </div>
    )
}

export default AcceptInvitation
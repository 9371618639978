import React, { useState } from 'react'

const RadioGroup = ({items, returnSelected, showDescriptions, hasError, inputLabel, requiredField, inline, wrap, preSelectedIndex}) => {

    const [selectedOption, setSelectedOption] = useState(preSelectedIndex)

    const selectOption = (index, item) => {
        setSelectedOption(index)
        returnSelected(item)
    }

    return (
        <>
            <label 
                className={`text-sm lg:text-md cursor-text z-10 font-[500] relative py-1 transition duration-200 block  
                ${hasError ? 'text-red-600' : 'text-gray-500'}`}
            >
             {requiredField && requiredField === true && <span className='text-red-600'>*</span>} {inputLabel}
            </label>
            <div className={`w-full ${inline && 'flex items-stretch gap-x-2 gap-y-2'} ${wrap && 'flex-wrap'}`}>
                {items.map((item, itemIndex)=>(
                <div onClick={()=>{selectOption(itemIndex, item)}} key={itemIndex} className={`${showDescriptions ? 'w-full' : 'w-max' } flex items-start gap-x-1 my-1 py-2 px-2 cursor-pointer border rounded-[8px] ${selectedOption === itemIndex ? 'border-doiteasy-black bg-doiteasy-blue bg-opacity-10' : 'border-gray-200 bg-gray-50'}`}>
                    <div className='w-[30px] h-[30px] flex items-center justify-center'>
                        <button 
                            className={`flex items-center justify-center rounded-full w-[20px] h-[20px] border-2 mt-[1px] transition duration-200 text-white bg-white 
                                ${hasError ? 'border-red-600' : selectedOption === itemIndex ? 'border-doiteasy-black' : 'border-gray-200'}`
                            } 
                            onClick={()=>{selectOption(itemIndex, item)}}
                        >
                            {selectedOption === itemIndex && <div className='w-2 h-2 transition duration-200 rounded-full bg-doiteasy-black'></div>}
                        </button>
                    </div>
                    <div>
                        <label className={`text-sm mt-[5px] cursor-pointer block ${hasError ? 'text-red-600' : 'text-black'} ${showDescriptions && 'font-urbanist font-[500]'}`}>
                            {item.label}
                        </label>
                        {showDescriptions && item.description && item.description !== '' && <label className='block text-sm text-gray-800 mt-1'>{item.description}</label>}
                    </div>
                </div>
                ))
                }
            </div>
        </>
    )
}

export default RadioGroup
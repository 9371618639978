import React from 'react'

const ResidentEmergencyContactSnippet = ({contacts}) => {
  return (
    <div>
      <p className='text-gray-600 font-[550] text-sm'>{contacts[0].name}</p>
      <p className='text-gray-600 font-[500] text-sm mt-[5px]'>{contacts[0].phone}, {contacts[0].email} <span className='text-xs text-gray-400 font-[550] mt-[5px]'>{contacts.length > 1 && `+${contacts.length - 1} more`}</span></p>
    </div>
  )
}

export default ResidentEmergencyContactSnippet